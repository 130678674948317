<template>
  <v-dialog
    max-width="900px"
    light
    v-model="areCookiesVisible"
    close-delay="5"
    open-delay="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- open modal button -->
      <button
        role="button"
        class="button-footer pointer"
        v-bind="attrs"
        v-on="on"
        
      >
        <p
          class="mb-0 text-xl-body-2 text-lg-body-2 text-md-body-2 text-sm-body-1 primaryGreen font-weight-medium"
          id="verification-portal-footer-cookies"
        >
          <span
            class="body-2 d-xl-inline d-lg-inline d-md-inline d-sm-none d-none primaryGreen font-weight-medium ml-1"
            >|</span
          >
          {{ $t("cookies.cookiesPoliciesCompliance") }}
        </p>
      </button>
      <!--  -->
    </template>

    <!-- content modal -->
    <v-card>
      <v-card-title
        class="d-flex justify-space-between title-terms--contaier align-center"
      >
        <p id="verification-portal-footer-cookies_dialog_title" class="primaryGreen subtitle-2 text-uppercase mb-0 pb-1">
          {{ $t("cookies.cookiesPoliciesCompliance") }}
        </p>
        <img
          id="verification-portal-footer-cookies_dialog_close"
          src="../../../../../assets/icons/close.svg"
          :alt="$t('altAriaAttributes.closeIcon')"
          class="close-icon pointer"
          @click="areCookiesVisible = false"
        />
      </v-card-title>
      <v-card-text>
        <v-col
          v-if="areCookiesVisible"
          cols="12"
          xl="12"
          lg="12"
          md="12"
          sm="12"
          class="pa-0 pt-5"
        >
          <h3>{{ $t("cookies.cookiesPoliciesModalIntro") }}</h3>
          <br />
          <p>{{ $t("cookies.cookiesPoliciesModalIntroP1") }}</p>
          <p>{{ $t("cookies.cookiesPoliciesModalIntroP2") }}</p>
          <p>{{ $t("cookies.cookiesPoliciesModalIntroP3") }}</p>
          <p>{{ $t("cookies.cookiesPoliciesModalIntroP4") }}</p>

          <h3>
            {{ $t("cookies.cookiesPoliciesModalResponsibility") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalResponsibilityP1") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalResponsibilityP2") }}
            <a href="mailto:dpo@customercomms.com" class="text-decoration-none"
              >dpo@customercomms.com</a
            >
          </p>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalWhatIsCookie") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalWhatIsCookieP1") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalWhatIsCookieP2") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalWhatIsCookieP3") }}
          </p>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalCookieTypes") }}
          </h3>
          <br />
          <h3>
            {{ $t("cookies.cookiesPoliciesModalCookiesInstall") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalCookiesInstallP1") }}
          </p>
          <br />
          <v-data-table
            dense
            :headers="headers"
            :items="tableCookiesData"
            class="elevation-0 col-12 pt-1 px-md-2 table-cookies"
            calculate-widths
            sort-desc
            hide-default-footer
          >
          </v-data-table>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalBrowserConfigCookies") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesP1") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesP2") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesP3") }}
          </p>
          <ul>
            <li><a :href="'https://support.google.com/chrome/answer/95647?hl='+lang" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI1")}}</a></li>
            <li><a v-if="lang === 'es' || lang === 'ca' || lang === 'gl' || lang === 'eu'" :href="'https://support.apple.com/'+lang+'-es/guide/safari/sfri11471/mac'" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI2") }}</a>
              <a v-else :href="'https://support.apple.com/'+lang+'-'+lang+'/guide/safari/sfri11471/mac'" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI2") }}</a></li>
            <li><a v-if="lang === 'es' || lang === 'ca' || lang === 'gl' || lang === 'eu'" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI3") }}</a><a v-else href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox#w_block-cookies-for-a-single-website" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI3") }}</a></li>
            <li><a v-if="lang === 'es' || lang === 'ca' || lang === 'gl' || lang === 'eu'" href="https://support.microsoft.com/es-es/microsoft-edge" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI4") }}</a>
              <a v-else href="https://support.microsoft.com/en-US/microsoft-edge" target="_blank">{{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI4") }}</a></li>
          </ul>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalBrowserConfigCookiesP4") }}
          </p>
          <ul>
            <li>
              <a
                href="https://www.criteo.com/es/privacy/disable-criteo-services-on-internet-browsers/"
                target="_blank"
                >{{
                  $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI5")
                }}</a
              >
            </li>
            <li>
              <a href="https://youronlinechoices.eu/" target="_blank">{{
                $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI6")
              }}</a>
            </li>
            <li>
              <a
                href="https://optout.networkadvertising.org/?c=1"
                target="_blank"
                >{{
                  $t("cookies.cookiesPoliciesModalBrowserConfigCookiesLI7")
                }}</a
              >
            </li>
          </ul>
          <br />
          <h3>
            {{ $t("cookies.cookiesPoliciesModalUnavailableCookies") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalUnavailableCookiesP1") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalUnavailableCookiesP2") }}
          </p>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalAcceptCookies") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalAcceptCookiesP1") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalAcceptCookiesP2") }}
          </p>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalUpgradeCookies") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalUpgradeCookiesP1") }}
          </p>
          <p>
            {{ $t("cookies.cookiesPoliciesModalUpgradeCookiesP2") }}
          </p>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalRecipientCookies") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalRecipientCookiesP1") }}
            <a
              id="verification-portal-footer-cookies_dialog_privacy_policy_link"
              style="text-decoration: underline"
              @click="goPrivacyPolicy()"
            >
              {{ $t("cookies.cookiesPoliciesModalRecipientCookiesLinkCCOMMS")}}.
            </a>
          </p>
          <h3>
            {{ $t("cookies.cookiesPoliciesModalContactInfoCookies") }}
          </h3>
          <br />
          <p>
            {{ $t("cookies.cookiesPoliciesModalContactInfoCookiesP1")
            }}<a
              href="mailto:marketing@customercomms.com"
              class="text-decoration-none"
              >marketing@customercomms.com</a
            >
          </p>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "../../../../../plugins/helpers/eventBus";

export default {
  name: "cookies",
  data: function() {
    return {
      areCookiesVisible: false,
      headers: [
        {
          id: "cookieType",
          text: this.$t("cookies.cookiesPoliciesModalDatatableCookieType"),
          value: "cookieType",
          cols: "1"
        },
        {
          id: "description",
          text: this.$t("cookies.description"),
          value: "description",
          cols: "3"
        },
        {
          id: "purpose",
          text: this.$t("cookies.privacyPoliciesConditionsFinalidadTitle"),
          value: "purpose",
          cols: "3"
        },
        {
          id: "treatmentTerm",
          text: this.$t("cookies.cookiesPoliciesModalDatatableTreatmentTerm"),
          value: "treatmentTerm",
          cols: "1"
        }
      ],
      tableCookiesData: [
        {
          cookieType: this.$t(
            "cookies.cookiesPoliciesModalDatatableTypeAnalytics"
          ),
          description: this.$t(
            "cookies.cookiesPoliciesModalDatatableTypeAnalyticsD"
          ),
          purpose: this.$t(
            "cookies.cookiesPoliciesModalDatatableTypeAnalyticsF"
          ),
          treatmentTerm: this.$t(
            "cookies.cookiesPoliciesModalDatatableTypeAnalyticsT"
          )
        },
        {
          cookieType: this.$t("cookies.cookiesPoliciesModalDatatableTypeSelf"),
          description: this.$t(
            "cookies.cookiesPoliciesModalDatatableTypeSelfD"
          ),
          purpose: this.$t("cookies.cookiesPoliciesModalDatatableTypeSelfF"),
          treatmentTerm: this.$t(
            "cookies.cookiesPoliciesModalDatatableTypeSelfT"
          )
        }
      ]
    };
  },
  computed: {
    lang: {
      get() {
        return this.$i18n.locale
      }
    }
  },
  methods: {
    goPrivacyPolicy() {
      EventBus.$emit("open:privacyPolicy", true);
      this.areCookiesVisible = false;
    }
  },
  mounted() {
    EventBus.$on("open:cookies", () => {
      this.areCookiesVisible = true;
    });
  }
};
</script>
