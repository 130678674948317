export default {
  "app": {
    "header": {
      "language": "Taal"
    }
  },
  "home": {
    "title": "Online Certificaat-verificatie",
    "trid": "unieke transactie-identificatiecode (trid)",
    "csv": "unieke certificaat-identificatiecode (csv)",
    "description": "Vul de volgende gegevens in om de authenticiteit te verifiëren van de verklaring van voltooiing of het certificaat dat in uw bezit is.",
    "subtitle": "VALIDATIECODE",
    "validate": "Valideren",
    "resultError": "Controleer de ingevoerde gegevens",
    "messagesInputs": {
      "required": "Verplicht",
      "minimo": "Minimaal 4 tekens.",
      "maximo": "Minimaal 1000 tekens."
    }
  },
  "confirmation": {
    "title": "Uw gegevens komen overeen met de volgende certificatie",
    "subtitle": "U kunt het certificaat downloaden door op de onderstaande knop te klikken en controleren of het overeenkomt met de gegevens waarover u beschikt.",
    "download": "Downloaden",
    "back": "Terug",
    "certificateDownloaded": "Uw certificaat is correct gedownload"
  },
  "helpDialog": {
    "messageSent": {
      "title": "Contact met support opnemen",
      "subtitle": "Uw aanvraag is doorgestuurd aan onze supportafdeling. Een van onze medewerkers neemt zo snel mogelijk contact met u op.",
      "back": "Terug"
    },
    "text": "Hulp nodig?",
    "contactWithSupport": "Contact met support opnemen",
    "dialogPhrase": "Vul de gegevens in. Het technisch supportteam geeft u zo snel mogelijk antwoord. Openings- en servicetijden:",
    "monFriHours": "<p class='body-2 font-weight-medium mb-7'><strong>Maandag t/m vrijdag</strong>: 8:00-17:00 uur</p>",
    "message": "Bericht",
    "sendRequest": "Aanvraag verzenden",
    "requiredEmailFormat": "Incorrect formaat e-mailadres",
    "requiredEmail": "E-mailadres is verplicht",
    "requiredMessage": "Bericht is verplicht",
    "requiredName": "Naam is verplicht",
    "email": "E-mailadres",
    "name": "Naam"
  },
  "termsAndConditions": {
    "title": "Algemene Voorwaarden",
    "termsConditions1": "CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (hierna CCOMMS) in zijn hoedanigheid van eigenaar en verantwoordelijke voor deze App, in overeenstemming met de bepalingen van artikel 10 van de Spaanse Wet 34/2002, van 11 juli, betreffende diensten van de informatiemaatschappij en elektronische handel, biedt gebruikers de volgende informatie uit deze App aan:",
    "companyInfo": "<p class='mb-1'><strong>Naam bedrijf</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F (Spaans fiscaal identificatienummer)</strong>: CB-86414000 </p><p class='mb-1'><strong>Geregistreerd adres</strong>:  Avenida de la Recomba, 14, 28914 Leganés (Madrid)</p><p class='mb-1'><strong>Gegevens Handelsregister</strong>: Handelsregister Madrid Volume 29.801, Folio 98, Sectie 8ª, Blad nummer M-536210.</p><p class='mb-1'><strong>Contact: Telefoon</strong>: 916 895 665</p><p class='mb-1'><strong>E-mail</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>E-mailadres van de functionaris gegevensbescherming (DPO)</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Functionaris gegevensbescherming (DPO) van MailTecK'>dpo@mailteck.com</a></p>",
    "termsConditionsObjetoTitulo": "I.- DOEL",
    "termsConditionsObjetoParrafo1": "Deze Algemene Gebruiksvoorwaarden (hierna de 'AGV') regelen het gebruik van de Applicatie Certificaatvalidatie (hierna 'de App') die CCOMMS ter beschikking stelt aan gebruikers die zich toegang verschaffen tot de App om de aanvragen die gedaan worden te kunnen afhandelen.",
    "termsConditionsObjetoParrafo2": "Daarom zijn deze AGV zowel van toepassing op de promotieactiviteiten en de informatieverstrekking via de App als op de levering van de daarin aangeboden diensten, zodat deze voorwaarden te allen tijde van toepassing zullen zijn op zowel simpelweg browsen in de App als op het contracteren van diensten in het kader van de App. De laatstgenoemde activiteiten zijn echter onderworpen aan zowel de hieronder beschreven AGV als aan de van toepassing zijnde Algemene Contractvoorwaarden en de eventuele Specifieke Voorwaarden.",
    "termsConditionsUsuariosTitulo": "II.- GEBRUIKERS",
    "termsConditionsUsuariosParrafo1": "De toegang tot en/of het gebruik van de App verleent aan diegenen die toegang hebben tot en/of gebruik maken van de App de status van gebruiker, waarbij ze vanaf dat moment, volledig en zonder enig voorbehoud, de huidige AGV accepteren, evenals de Specifieke Voorwaarden die, waar van toepassing, de AGV aanvullen, wijzigen of vervangen met betrekking tot bepaalde diensten en inhoud van de App, en waarbij ze zich verplichten om te allen tijde de door CCOMMS op enig moment vastgelegde beperkingen op het gebruik van de genoemde pagina te respecteren.",
    "termsConditionsAccesoAPPTitulo": "III.- TOEGANG TOT DE APP",
    "termsConditionsAccesoAPPParrafo1": "De toegang tot en het gebruik van de App hebben een beperkt karakter en om toegang te krijgen tot de App is voorafgaande registratie van de gebruiker nodig.",
    "termsConditionsUsoAPPTitulo": "IV.- GEBRUIK VAN DE APP",
    "termsConditionsUsoAPPParrafo1": "De gebruiker verbindt zich ertoe de App, de diensten en de inhoud ervan te gebruiken zonder in strijd te zijn met de geldende wetgeving, de goede trouw, algemeen aanvaarde gebruiken en de openbare orde. Evenzo is het verboden de App te gebruiken voor ongeoorloofde of schadelijke doeleinden tegen CCOMMS of een derde partij, of doeleinden die op enigerlei wijze schade kunnen toebrengen aan of de normale werking kunnen belemmeren van de App.",
    "termsConditionsResposabilidadTitulo": "V-. AANSPRAKELIJKHEID VAN DE GEBRUIKER VOOR SCHADE",
    "termsConditionsResposabilidadParrafo1": "Het gebruik van de App geschiedt op eigen en exclusieve aansprakelijkheid van de gebruiker. Deze aansprakelijkheid strekt zich uit tot het gebruik, door de gebruiker of een derde, van alle wachtwoorden en dergelijke die zijn toegewezen voor toegang tot de App of diensten ervan.",
    "termsConditionsResposabilidadParrafo2": "Onverminderd het voorgaande behoudt CCOMMS zich het recht voor om te allen tijde en zonder voorafgaande kennisgeving de toegang tot de App of beperkte toegang te ontzeggen of onmogelijk te maken aan gebruikers die deze AGV of de door CCOMMS of zijn medewerkers opgestelde regels schenden of de goede werking, het imago, de geloofwaardigheid en/of het prestige van CCOMMS of zijn medewerkers kunnen verstoren.",
    "termsConditionsResposabilidadParrafo3": "In deze zin behoudt CCOMMS zich het recht voor om de toegang tot de App te annuleren of te beperken voor bepaalde Gebruikers, in het geval dat gedrag opgemerkt wordt dat naar het oordeel van CCOMMS in strijd is met deze Gebruiksvoorwaarden, de Wet, de door CCOMMS of zijn medewerkers opgestelde regels of dat de goede werking, het imago, de geloofwaardigheid en/of het prestige van CCOMMS of zijn medewerkers kan verstoren. Evenzo kan CCOMMS Gebruikers aansprakelijk stellen voor schade die voortvloeit uit misbruik van de App.",
    "termsConditionsResposabilidadParrafo4": "CCOMMS garandeert niet de wettigheid, betrouwbaarheid, bruikbaarheid, waarheidsgetrouwheid of nauwkeurigheid van de inhoud die gebruikers overdragen tijdens het gebruik van de App.",
    "termsConditionsResposabilidadParrafo5": "CCOMMS oefent geen controle uit op het gebruik dat gebruikers van de App maken, en garandeert evenmin dat gebruikers dit doen in overeenstemming met deze Algemene Voorwaarden.",
    "termsConditionsResposabilidadParrafo6": "CCOMMS controleert of garandeert niet de afwezigheid van computervirussen in de diensten die door derden via de App worden aangeboden en die wijzigingen kunnen veroorzaken in uw computersysteem (software en hardware) of in de elektronische documenten en bestanden die in uw computersysteem zijn opgeslagen.",
    "termsConditionsPropiedadIntelectualTitulo": "VI.- INTELLECTUELE EIGENDOM",
    "termsConditionsPropiedadIntelectualParrafo1": "Iedere vorm van gebruik of wijziging van het Materiaal of de Inhoud voor enig ander doel dan zoals toegestaan in de Voorwaarden zal worden beschouwd als een schending van de internationale copyright-wetten die de rechten van de auteur(s) beschermen.",
    "termsConditionsPropiedadIntelectualParrafo2": "Met betrekking tot de Inhoud is het volgende verboden:",
    "termsConditionsPropiedadIntelectualListaELemento1": "Reproduceren, kopiëren, distribueren, wijzigen of manipuleren ervan, in welke vorm en voor welk doel dan ook, tenzij vooraf schriftelijke toestemming is verkregen van de rechtmatige eigenaars of het wettelijk is toegestaan.",
    "termsConditionsPropiedadIntelectualListaELemento2": "Elke inbreuk op de rechten van CCOMMS of de rechtmatige eigenaars van deze rechten.",
    "termsConditionsPropiedadIntelectualListaELemento3": "Het gebruik ervan voor andere dan de strikt toegestane commerciële of reclame-doeleinden.",
    "termsConditionsPropiedadIntelectualParrafo3": "CCOMMS garandeert dat de inhoud, met inbegrip van die met intellectuele eigendom, niet van illegale aard is en niet in strijd is met de geldende wetgeving. Daarom mag de inhoud van de App niet xenofoob, pornografisch, discriminerend of racistisch zijn, of op enigerlei wijze geweld bevorderen,",
    "termsConditionsPropiedadIntelectualParrafo4": "Bovendien zal CCOMMS de wettelijke maatregelen nemen die het bedrijf geschikt acht om elk soort gedrag te verhinderen dat in strijd is met de wet of immoreel is.",
    "termsConditionsModificacionUnilateralTitulo": "VII.- EENZIJDIGE WIJZIGING",
    "termsConditionsModificacionUnilateralParrafo1": "CCOMMS kan de structuur en het ontwerp van de App eenzijdig en zonder voorafgaande kennisgeving wijzigen, evenals de diensten, inhoud en voorwaarden voor toegang en/of gebruik van de App wijzigen of opheffen, indien het bedrijf dat nodig acht.",
    "termsConditionsExclusionGarantiasTitulo": "VIII.- UITSLUITING VAN GARANTIES EN AANSPRAKELIJKHEID",
    "termsConditionsExclusionGarantiasParrafo1": "CCOMMS geeft geen garantie en is in geen geval aansprakelijk voor schade van welke aard dan ook die veroorzaakt kan zijn door:",
    "termsConditionsExclusionGarantiasListaELemento1": "Het niet naleven van de Wet, de goede zeden en gewoonten, evenals de openbare orde, of het gebruik van de aangeboden producten en/of diensten op een onzorgvuldige of onjuiste manier, of voor ongeoorloofde doeleinden of effecten of in strijd met de bepalingen van deze AGV of de toepasselijke voorwaarden die van toepassing kunnen zijn.",
    "termsConditionsExclusionGarantiasListaELemento2": "Voor het verwerven of contracteren van diensten, goederen of producten die van derden verworven of gecontracteerd kunnen worden via toegang tot de App van CCOMMS, met name in die gevallen waarin het aankoop- of contracteerproces rechtstreeks in de app van de derde wordt uitgevoerd', ook al zien ze er anders uit of vormen ze een 'frame' met de grafische elementen van de app (Branding).",
    "termsConditionsExclusionGarantiasListaELemento3": "Gebrek aan beschikbaarheid, onderhoud en effectief functioneren van de App en/of de diensten of inhoud ervan.",
    "termsConditionsExclusionGarantiasListaELemento4": "Gebrek aan nut, geschiktheid of geldigheid van de App en/of zijn diensten of inhoud om aan de behoeften, activiteiten of concrete resultaten of verwachtingen van de gebruikers te voldoen.",
    "termsConditionsExclusionGarantiasListaELemento5": "Het bestaan van virussen, kwaadaardige of schadelijke programma's in de inhoud.",
    "termsConditionsExclusionGarantiasListaELemento6": "Het ontvangen, verkrijgen, opslaan, verspreiden of doorgeven van de inhoud door gebruikers.",
    "termsConditionsExclusionGarantiasListaELemento7": "Onwettigheid of gebrek aan waarheidsgetrouwheid, nauwkeurigheid, betrouwbaarheid, relevantie, tijdigheid en volledigheid van de inhoud en informatie die aan gebruikers wordt doorgegeven of beschikbaar wordt gesteld, met inbegrip van informatie en diensten die door derden of door gebruikers via de App worden verstrekt.",
    "termsConditionsExclusionGarantiasListaELemento8": "Niet-naleving door derden van hun verplichtingen of verbintenissen in verband met de diensten die via de App aan de gebruikers worden verleend.",
    "termsConditionsExclusionGarantiasListaELemento9": "Inhoud, bestanden, informatie, reclame, meningen, concepten en beelden die niet van de App afhankelijk zijn, noch door CCOMMS beheerd worden. Het bedrijf is evenmin verantwoordelijk voor misbruik van de inhoud van de App, die uitsluitend de verantwoordelijkheid is van de persoon die er toegang toe heeft of er gebruik van maakt.",
    "termsConditionsExclusionGarantiasListaELemento10": "Oneigenlijk gebruik van de toegangscodes van de gebruikers om toegang te krijgen tot de inhoud van de App-dienst waarvoor deze nodig zijn en de gevolgen van welke aard dan ook die voortvloeien uit misbruik door gebruikers, verlies of vergeten ervan, en oneigenlijk gebruik door onbevoegde derden.",
    "termsConditionsExclusionGarantiasListaELemento11": "Het onvermogen van een gebruiker of het zich voordoen als een derde door de gebruiker.",
    "termsConditionsExclusionGarantiasListaELemento12": "De inhoud die door de gebruikers wordt verstrekt.",
    "termsConditionsProteccionDatosTitulo": "IX.- GEGEVENSBESCHERMING",
    "termsConditionsProteccionDatosParrafo1": "U kunt gedetailleerde informatie over Gegevensbescherming vinden op onze pagina",
    "termsConditionsLeyJurisdiccionTitulo": "X.- VAN TOEPASSING ZIJNDE WET EN JURISDICTIE",
    "termsConditionsLeyJurisdiccionParrafo1": "Alle Algemene en Specifieke Voorwaarden die in deze App zijn opgenomen, vallen onder de Spaanse wetgeving. Alle geschillen, disputen of situaties die voortvloeien uit de Algemene en Specifieke Voorwaarden worden voorgelegd aan de rechtbanken en gerechtshoven van de stad Madrid, en de partijen zien uitdrukkelijk af van overige eigen jurisdictie."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  "cookies": {
    "cookiesPoliciesCompliance": "Cookiebeleid",
    "cookiesPoliciesIntroText": "Deze website gebruikt cookies om uw ervaring te verbeteren tijdens het navigeren door de website. U kunt de cookie-instellingen op ieder moment wijzigen. Cookies die als noodzakelijk zijn geclassificeerd, worden in uw browser opgeslagen en zijn essentieel voor de werking van de basisfunctionaliteiten van de website. Cookies van derden worden alleen met uw toestemming in uw browser opgeslagen en helpen ons te analyseren en te begrijpen hoe deze website wordt gebruikt. U heeft de optie om de ontvangst van deze cookies uit te schakelen. Maar als u sommige van deze cookies uitschakelt, kan dit echter ertoe leiden dat sommige functies niet langer beschikbaar zijn. Voor informatie over het verwijderen van cookies kunt u de helpfunctie van uw browser raadplegen.",
    "cookiesPoliciesNecessaryCookies": "Noodzakelijke cookies",
    "cookiesPoliciesNecessaryCookiesText": "Dit soort cookies is absoluut noodzakelijk voor de goede werking van de website. Deze categorie omvat alleen cookies die zorgen voor basisfunctionaliteit en beveiligingsfuncties van de website. Deze cookies verzamelen geen persoonlijke informatie over websitegebruikers en slaan deze niet op.",
    "cookiesPoliciesUnnecessaryCookies": "Niet-noodzakelijke cookies",
    "cookiesPoliciesUnnecessaryCookiesText": "Cookies die niet specifiek noodzakelijk zijn voor het functioneren van de website en die specifiek worden gebruikt om persoonlijke gebruikersgegevens te verzamelen via analyses, advertenties en andere ingebedde inhoud, worden 'niet-noodzakelijke cookies' genoemd. Het is verplicht de toestemming van de gebruiker te verkrijgen voordat deze cookies op uw website gebruikt mogen worden.",
    "cookiesPoliciesGoogleAnalyticsCookies": "Cookies van Google Analytics",
    "cookiesPoliciesGoogleAnalyticsCookiesText": "Voor meer informatie over dit type cookies kunt u het document 'Gebruik van Google Analytics-cookies op websites' raadplegen",
    "cookiesPoliciesGoogleAnalyticsCheckboxText": "Cookies actief houden",
    "cookiesNotificationBarText": "Wij gebruiken cookies, zowel onze eigen cookies als die van derden, om het bezoek te meten, de functionaliteit van de website te garanderen en de navigatie op de website te analyseren. Hieronder kunt u cookies accepteren of het gebruik van cookies instellen/afwijzen. Meer informatie over de eigenaar van de website, de verantwoordelijke voor de behandeling van cookies en hun contactgegevens kunt u vinden op onze",
    "cookiesNotificationBarConfig": "Instellen",
    "cookiesPoliciesModalIntro": "Inleiding",
    "cookiesPoliciesModalIntroP1": "Deze website gebruikt cookies en/of vergelijkbare technologieën die informatie opslaan en ophalen bij uw gebruik van de website, om de gebruikerservaring te verbeteren en te optimaliseren.",
    "cookiesPoliciesModalIntroP2": "In het algemeen kunnen deze technologieën verschillende doelen dienen, zoals bijvoorbeeld het herkennen van u als gebruiker, het verkrijgen van informatie over uw navigatiegedrag of het personaliseren van de manier waarop inhoud wordt weergegeven.",
    "cookiesPoliciesModalIntroP3": "In overeenstemming met Spaans Koninklijk Besluit Wet 13/2012 en de uitwerking daarvan op artikel 22.2 van de Spaanse Wet op de diensten van de informatiemaatschappij en de elektronische handel, informeert dit Beleid u over de verschillende cookies die CUSTOMER COMMUNICATIONS TECKNALIA, S.L. verzamelt via de verschillende communicatiemiddelen of -kanalen, eigen of van derden, al dan niet vrijgesteld van de informatieplicht, en de verschillende manieren waarop u deze kunt weigeren, toestaan of blokkeren.",
    "cookiesPoliciesModalIntroP4": "Hieronder vindt u gedetailleerde informatie over: wat Cookies zijn, welk type deze website gebruikt, hoe ze kunnen worden gedeactiveerd in een browser en hoe u specifiek de installatie van Cookies van derden kunt blokkeren.",
    "cookiesPoliciesModalResponsibility": "Wie is de verantwoordelijke voor gebruik van cookies op deze website?",
    "cookiesPoliciesModalResponsibilityP1": "De verantwoordelijke voor gebruik van cookies is CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (hierna CCOMMS), met N.I.F.-nummer (belastingidentificatienummer/btw-nummer) B-86414000 en met geregistreerd adres in Leganés (Madrid, Spanje), Avenida de la Recomba 14, volgens de regels geregistreerd in het Handelsregister van Madrid, Volume 29.801, Folio 98, Sectie 8ª, Blad nummer M-536210.",
    "cookiesPoliciesModalResponsibilityP2": "E-mailadres van de functionaris gegevensbescherming (DPO).",
    "cookiesPoliciesModalWhatIsCookie": "Wat is een cookie?",
    "cookiesPoliciesModalWhatIsCookieP1": "Een cookie is een gegeven, bestand of gegevensdrager dat/die door een webbrowser automatisch opgeslagen wordt op het apparaat (smartphone, tablet of aangesloten tv) van een gebruiker bij het openen van webpagina's, accounts van sociale netwerken of bij het ontvangen van e-mail.",
    "cookiesPoliciesModalWhatIsCookieP2": "Cookies maken automatisch opslaan en opvragen mogelijk van gegevens over het gemaakte gebruik van voornoemde digitale media en de computer of het apparaat waarmee u de gegevens benadert.",
    "cookiesPoliciesModalWhatIsCookieP3": "Een cookie is slechts data die worden opgeslagen op de computer van de gebruiker. Het is dus geen software of stukjes code, het zijn gewoon gegevens. Daarom kunnen cookies in principe geen virussen overdragen en uitvoeren of malware zoals Trojaanse paarden of spyware installeren.",
    "cookiesPoliciesModalCookieTypes": "Wat voor soort cookies staan er op de website en met welk doel?",
    "cookiesPoliciesModalCookiesInstall": "Welke cookies kunnen worden geplaatst op de website Certificaat-verificatie?",
    "cookiesPoliciesModalCookiesInstallP1": "Toegang tot de website Certificaat-verificatie houdt in dat er cookies, eigen en/of van derden, kunnen worden geïnstalleerd. Meer informatie over deze cookies vindt u in de volgende tabel:",
    "cookiesPoliciesModalBrowserConfigCookies": "Hoe kunt u cookies in uw browser instellen?",
    "cookiesPoliciesModalBrowserConfigCookiesP1": "U heeft de optie om geen cookies te ontvangen of niet te worden geïnformeerd over de instelling ervan door uw browser daartoe te configureren. Alle cookies, behalve Flash-cookies, kunnen met uw browser worden beheerd, gewoonlijk onder 'Opties' of 'Voorkeuren'.",
    "cookiesPoliciesModalBrowserConfigCookiesP2": "U kunt ze volledig uitschakelen, in welk geval uw browser niet zal toestaan dat er ook maar één cookie wordt geïnstalleerd (waardoor u niet van alle functionaliteiten van de website gebruik kunt maken), of alleen die van bepaalde websites, waardoor u wel kunt genieten van de geavanceerde functionaliteiten van de websites die de privacy van uw gegevens respecteren, en de websites kunt uitsluiten die dat niet doen.",
    "cookiesPoliciesModalBrowserConfigCookiesP3": "De volgende links geven toegang tot de belangrijkste pagina's die hierbij behulpzaam zijn:",
    "cookiesPoliciesModalBrowserConfigCookiesP4": "Ook kan de Gebruiker zich verzetten tegen het gebruik van bepaalde cookies door middel van de volgende diensten:",
    "cookiesPoliciesModalBrowserConfigCookiesLI1": "Cookies blokkeren in Chrome",
    "cookiesPoliciesModalBrowserConfigCookiesLI2": "Cookies blokkeren in Safari",
    "cookiesPoliciesModalBrowserConfigCookiesLI3": "Cookies blokkeren in Firefox",
    "cookiesPoliciesModalBrowserConfigCookiesLI4": "Cookies blokkeren in Internet Explorer",
    "cookiesPoliciesModalBrowserConfigCookiesLI5": "http://www.criteo.com/deactivate-criteo-banners/",
    "cookiesPoliciesModalBrowserConfigCookiesLI6": "http://youronlinechoices.eu/",
    "cookiesPoliciesModalBrowserConfigCookiesLI7": "http://www.networkadvertising.org/choices/",
    "cookiesPoliciesModalUnavailableCookies": "Wat gebeurt er als u de cookies uitschakelt?",
    "cookiesPoliciesModalUnavailableCookiesP1": "Als u cookies weigert, kunt u op onze Website blijven navigeren, hoewel het gebruik van sommige diensten beperkt kan zijn en uw ervaring dus minder goed kan zijn.",
    "cookiesPoliciesModalUnavailableCookiesP2": "Weigeren, blokkeren of niet toestaan van de cookies die in dit beleid worden beschreven, kan van invloed zijn op het correct navigeren door de inhoud die op de website wordt weergegeven.",
    "cookiesPoliciesModalAcceptCookies": "Accepteren van het cookiebeleid",
    "cookiesPoliciesModalAcceptCookiesP1": "Als u drukt op de knop 'COOKIES ACCEPTEREN' wordt aangenomen dat u het gebruik van cookies accepteert.",
    "cookiesPoliciesModalAcceptCookiesP2": "Echter, iedere keer dat u een sessie start zal het Cookiebeleid onderaan elke pagina van de site worden weergegeven, zodat u ervan op de hoogte bent.",
    "cookiesPoliciesModalUpgradeCookies": "Actualiseren wij ons Cookiebeleid?",
    "cookiesPoliciesModalUpgradeCookiesP1": "Het is mogelijk dat de informatie die in dit Cookiebeleid wordt verstrekt, wijzigingen ondergaat als gevolg van updates of wijzigingen in het beleid inzake de behandeling van cookies; evenzo kan het Spaanse Agentschap voor gegevensbescherming instructies en/of aanbevelingen uitvaardigen die tot wijziging van dit beleid kunnen leiden.",
    "cookiesPoliciesModalUpgradeCookiesP2": "Wij raden u daarom aan dit beleid elke keer dat u onze website bezoekt door te nemen, zodat u goed geïnformeerd bent over hoe en waarom wij cookies gebruiken.",
    "cookiesPoliciesModalRecipientCookies": "Wie zijn de ontvangers van de informatie en hoe beïnvloedt het gebruik van cookies de bescherming van persoonsgegevens?",
    "cookiesPoliciesModalRecipientCookiesP1": "Meer informatie over de verwerking van uw gegevens vindt u in het",
    "cookiesPoliciesModalRecipientCookiesLinkCCOMMS": "Gegevensbeschermingsbeleid",
    "cookiesPoliciesModalContactInfoCookies": "Contactinformatie",
    "cookiesPoliciesModalContactInfoCookiesP1": "In geval van vragen, onduidelijkheden en/of suggesties met betrekking tot cookies kunt u contact met ons opnemen per e-mail",
    "cookiesPoliciesModalDatatableCookieType": "Type cookie",
    "cookiesPoliciesModalDatatableTreatmentTerm": "Verwerkingsperiode",
    "cookiesPoliciesModalDatatableTypeAnalytics": "Analytische cookies",
    "cookiesPoliciesModalDatatableTypeSelf": "Eigen cookies",
    "cookiesPoliciesModalDatatableTypeAnalyticsD": "Telkens wanneer een Gebruiker een Dienst bezoekt, genereert een tool van een externe provider een analytische Cookie op de computer van de Gebruiker. Deze Cookie, die alleen tijdens het bezoek wordt gegenereerd, zal bij toekomstige bezoeken aan CCOMMS gebruikt worden om de bezoeker anoniem te identificeren.",
    "cookiesPoliciesModalDatatableTypeAnalyticsF": "Anonieme identificatie van navigerende Gebruikers mogelijk te maken via de Cookie (identificeert browsers en apparaten, geen personen) en dus een schatting bij te houden van het aantal bezoekers en de ontwikkeling in de tijd daarvan. De meest bezochte en aantrekkelijkste inhoud voor gebruikers te bepalen. Om te weten of de Gebruiker die de site bezoekt een nieuwe of een terugkerende bezoeker is. Behalve als de gebruiker besluit zich op CCOMMS te registreren, zal de Cookie nooit in verband worden gebracht met persoonlijke gegevens die rechtstreekse identificatie van de gebruiker mogelijk maken. Deze cookies worden alleen gebruikt voor statistische doeleinden die helpen om de ervaring van de Gebruiker op de site te optimaliseren en te verbeteren.",
    "cookiesPoliciesModalDatatableTypeAnalyticsT": "Persistente cookies",
    "cookiesPoliciesModalDatatableTypeSelfD": "Dit type Cookies maakt het mogelijk om de informatie uit te breiden die getoond wordt aan elke anonieme Gebruiker in de Diensten van COMMS.",
    "cookiesPoliciesModalDatatableTypeSelfF": "Zo worden onder meer de duur of de frequentie van weergave van de verschillende rubrieken, de interactie daarmee, of de navigatiepatronen en/of het navigatiegedrag van de Gebruiker opgeslagen.",
    "cookiesPoliciesModalDatatableTypeSelfT": "Persistente cookies",
    "description": "Beschrijving",
    "privacyPoliciesConditionsFinalidadTitle": "Doel",
    "accept": "Accepteren",
    "reject": "Weigeren",
    "save": "Opslaan",
    "cookiesPoliciesCertySign": "Cookies op Certificaat-verificatie",
    "cookiesNecessaryCookieDescription": "Deze cookie is noodzakelijk en wordt gebruikt om voorkeuren van de gebruiker met betrekking tot het cookiebeleid vast te leggen.",
    "cookiesGAMessage": "Interne meting met Google Analytics. Deze cookies slaan een unieke identificatiecode op om de gebruiker bij toekomstige bezoeken aan de website te herkennen.",
    "cookiesGAFGenericMessage": "Interne meting met Google Analytics."
  },
  conditionsOfService: 'Servicevoorwaarden',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "pictogram sluiten",
    zoomTRID: "zoom trid",
    zoomCSV: "zoom-csv",
    sampleCertificate: "Voorbeeldcertificaat",
    downloadButton: "downloadknop",
    checkboxActiveUser: "Selectievakje",
    selectLanguage: "Selecteer taal",
    openHelpDialog: "Ondersteuningsdialoog openen",
    trid: "Schrijf TRID",
    csv: "Schrijf CSV",
    part1Container: "1 - Positie-indicator van de unieke transactie-identificatie (trid) in het modelcertificaat",
    part2Container: "2 - Positie-indicator van de unieke certificaat-ID (csv) in het modelcertificaat",
    sampleTrid: "Unieke transactie-identificatie (trid) in het voorbeeldcertificaat",
    sampleCSV: "Certificate unique identifier (csv) in the sample certificate"
  }
};
