export default {
  app: {
    header: {
      language: "Idioma"
    }
  },
  home: {
    title: "Verificación Online de Certificados",
    trid: "identificador único de transaccion (trid)",
    csv: "identificador único de certificado (csv)",
    description:
      "Complete los siguientes datos para verificar la autenticidad de la declaración de finalización o certificado que obra en su poder.",
    subtitle: "CÓDIGO DE VALIDACIÓN",
    validate: "Validar",
    resultError: "Por favor, revise los datos introducidos",
    messagesInputs: {
      required: "Requerido",
      minimo: "Minimo 4 caracteres",
      maximo: "Minimo 1000 caracteres"
    }
  },
  confirmation: {
    title: "Sus datos corresponden con la siguiente certificación",
    subtitle:
      "Puede descargar el certificado en el botón inferior y comprobar que coincide con la información en su poder.",
    download: "Descargar",
    back: "Volver",
    certificateDownloaded: "Su certificado se ha descargado correctamente"
  },
  helpDialog: {
    messageSent: {
      title: "Contactar con soporte",
      subtitle:
        "Se ha enviado su solicitud a nuestro departamento de soporte, un agente se pondrá en contacto con usted a la mayor brevedad posible.",
      back: "Volver"
    },
    text: "¿Necesita ayuda?",
    contactWithSupport: "Contactar con soporte",
    dialogPhrase:
      "Rellene los datos y el equipo de soporte técnico le responderá a la mayor brevedad posible. Horarios de atención, laborables:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Lunes a Viernes</strong>: 8:00 h - 17:00 h</p>",
    message: "Mensaje",
    sendRequest: "Enviar Solicitud",
    requiredEmailFormat: "Formato de email incorrecto",
    requiredEmail: "Email requerido",
    requiredMessage: "Mensaje requerido",
    requiredName: "Nombre requerido",
    email: "E-mail",
    name: "Nombre"
  },
  termsAndConditions: {
    title: "Términos y Condiciones",
    termsConditions1:
      " CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (en adelante CCOMMS) en su condición de titular y responsable de la presente APP, en cumplimiento de lo establecido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, pone a disposición de los usuarios la siguiente información genera de esta APP:",
    companyInfo:
      "<p class='mb-1'><strong>Titular</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Domicilio Social</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Datos Registrales</strong>: Registro Mercantil de Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.</p><p class='mb-1'><strong>Contacto: Teléfono</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Correo electrónico del Delegado de Protección de Datos</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Delegado de Protección de Datos de MailTecK'>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- OBJETO",
    termsConditionsObjetoParrafo1:
      "Las presentes Condiciones Generales de Uso (en adelante, las “CGU”) regulan el uso de la Aplicación Validacion de certificado (en adelante, la APP) que CCOMMS pone a disposición de los usuarios que accedan al mismo con el fin de proceder a poder realizar la solicitud realizada.",
    termsConditionsObjetoParrafo2:
      "Por tanto, las presentes CGU se aplicarán tanto a la actividad promocional y de suministro de información que se efectúa a través de la APP como a la prestación de los servicios ofrecidos en el mismo, de tal manera que las mismas regirán en todo momento tanto para la simple navegación por la APP, como para la contratación de servicios en el marco de la APP, si bien estas últimas actividades adicionalmente, se someterán tanto a las CGU descritas a continuación, como a las Condiciones Generales de Contratación aplicables, y las Condiciones Particulares, que en su caso, pudieran existir.",
    termsConditionsUsuariosTitulo: "II.- USUARIOS",
    termsConditionsUsuariosParrafo1:
      "El acceso a y/o uso de la APP, atribuye a quien lo realiza la condición de usuario, aceptando, desde ese mismo momento, plenamente y sin reservas alguna, las presentes CGU, así como las Condiciones Particulares que, en su caso, complementen, modifiquen o sustituyan las CGU en relación con determinados servicios y contenidos de la APP, obligándose a respetar las restricciones de uso de dicha página establecidas por CCOMMS en cada momento.",
    termsConditionsAccesoAPPTitulo: "III.- ACCESO A LA APP",
    termsConditionsAccesoAPPParrafo1:
      "El acceso y uso de la APP tiene carácter restringido y para poder acceder a la misma será necesario el registro previo del usuario.",
    termsConditionsUsoAPPTitulo: "IV.- USO DE LA APP",
    termsConditionsUsoAPPParrafo1:
      "El usuario se compromete a utilizar la APP, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe, los usos generalmente aceptados y el orden público. Asimismo, queda prohibido, el uso de la APP con fines ilícitos o lesivos contra CCOMMS o cualquier tercero, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento de la APP.",
    termsConditionsResposabilidadTitulo:
      "V-. RESPONSABILIDAD DEL USUARIO POR DAÑOS Y PERJUICIOS",
    termsConditionsResposabilidadParrafo1:
      "El uso de la APP se realizará bajo la única y exclusiva responsabilidad del usuario. Dicha responsabilidad se extenderá al uso, por parte del usuario o de cualquier tercero, de cualesquiera contraseña o similares asignadas para el acceso a la APP o a cualesquiera de sus servicios.",
    termsConditionsResposabilidadParrafo2:
      "Sin perjuicio de lo anterior, CCOMMS se reserva el derecho a denegar o inhabilitar en cualquier momento y sin necesidad de aviso previo, el acceso a la APP o al acceso restringido, a aquellos usuarios que incumplan estas CGU, las normas establecidas por CCOMMS o sus colaboradores o puedan perturbar el buen funcionamiento, imagen, credibilidad y/o prestigio de CCOMMS o sus colaboradores.",
    termsConditionsResposabilidadParrafo3:
      "En este sentido, CCOMMS se reserva la facultad de cancelar o restringir, el acceso a de la APP a determinados Usuarios, en caso de observar cualesquiera conductas que a juicio de CCOMMS resulten contrarias a estas Condiciones de Uso, la Ley, las normas establecidas por CCOMMS o sus colaboradores o puedan perturbar el buen funcionamiento, imagen, credibilidad y/o prestigio de CCOMMS o sus colaboradores. Asimismo, CCOMMS podrá exigir responsabilidad a los Usuarios por los daños y perjuicios derivados del mal uso de la APP.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMS no garantiza la licitud, fiabilidad y utilidad, así como su veracidad o exactitud, de los contenidos que los usuarios transmitan durante la utilización de la APP.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMS no controla la utilización que los usuarios hacen de la APP, ni garantiza que lo hagan de forma conforme a las presentes Condiciones Generales.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMS no controla ni garantiza la ausencia de virus informáticos en los servicios prestados por terceros a través de la APP que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos y ficheros electrónicos almacenados en su sistema informático.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- PROPIEDAD INTELECTUAL",
    termsConditionsPropiedadIntelectualParrafo1:
      'Cualquier uso o modificación del Material o de los Contenidos para cualquier otro fin distinto del autorizado en las Condiciones Generales será considerado una violación de las leyes internacionales del "copyright", que protegen los derechos de autor.',
    termsConditionsPropiedadIntelectualParrafo2:
      "Respecto de los contenidos, se prohíbe:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "Su reproducción, copia, distribución, modificación o manipulación, de cualquier forma y con cualquier finalidad, a menos que se cuente con la autorización previa y por escrito de sus legítimos titulares o resulte legalmente permitido.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Cualquier vulneración de los derechos de CCOMMS o de sus legítimos titulares sobre los mismos.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "Su utilización para todo tipo de fines comerciales o publicitarios, distintos de los estrictamente permitidos.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMS garantiza que los contenidos, incluidos los de propiedad intelectual, no son de carácter ilícito ni infringen la normativa vigente. Por consiguiente, los contenidos de la APP no tendrán carácter xenófobo, pornográfico, discriminatorio, racista o que de cualquier modo fomente la violencia.",
    termsConditionsPropiedadIntelectualParrafo4:
      "Además, CCOMMS adoptará las medidas legales que considere convenientes para impedir cualquier tipo de conducta contraria a la Ley o inmoral.",
    termsConditionsModificacionUnilateralTitulo:
      "VII.- MODIFICACIÓN UNILATERAL",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMS podrá modificar unilateralmente y sin previo aviso, siempre que lo considere oportuno, la estructura y diseño de la APP, así como modificar o eliminar, los servicios, los contenidos y las condiciones de acceso y/o uso de la APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMS no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:",
    termsConditionsExclusionGarantiasListaELemento1:
      "El incumplimiento de la Ley, la moral y las buenas costumbres, así como el orden público, o el uso de los productos y/o servicios ofertados de forma no diligente o incorrecta, o con fines o efectos ilícitos o contrarios a lo establecido en las presentes CGU o en las condiciones aplicables que en su caso resulten de aplicación.",
    termsConditionsExclusionGarantiasListaELemento2:
      "Por la adquisición o contratación de los servicios, bienes o productos que puedan ser adquiridos o contratados a terceros a través de un acceso en la APP de CCOMMS, especialmente en aquellos casos en los que el proceso de compra o contratación se realiza directamente en la app del tercero y aunque aparezcan distintivos o un “frame” con los elementos gráficos de la app (Branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "La falta disponibilidad, mantenimiento y efectivo funcionamiento de la APP y/o de sus servicios o contenidos.",
    termsConditionsExclusionGarantiasListaELemento4:
      "La falta de utilidad, adecuación o validez de la APP y/o de sus servicios o contenidos para satisfacer necesidades, actividades o resultados concretos o expectativas de los usuarios.",
    termsConditionsExclusionGarantiasListaELemento5:
      "La existencia de virus, programas maliciosos o lesivos en los contenidos.",
    termsConditionsExclusionGarantiasListaELemento6:
      "La recepción, obtención, almacenamiento, difusión o transmisión, por parte de los usuarios, de los contenidos.",
    termsConditionsExclusionGarantiasListaELemento7:
      "La ilicitud o falta de veracidad, exactitud, fiabilidad, pertinencia, actualidad y exhaustividad de los contenidos e informaciones transmitidas o puestos a disposición de los usuarios, incluidos las informaciones y servicios prestados por terceros o por los usuarios a través de la APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "El incumplimiento por parte de terceros de sus obligaciones o compromisos en relación con los servicios prestados a los usuarios a través de la APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "De los contenidos, archivos, informaciones, publicidad, opiniones, conceptos e imágenes que no dependan de la APP, ni sean gestionados por CCOMMS. Tampoco se responsabiliza del mal uso que se realice de los contenidos de la APP, siendo exclusiva responsabilidad de la persona que acceda a ellos o los utilice.",
    termsConditionsExclusionGarantiasListaELemento10:
      "Del uso indebido de las calves de acceso de los usuarios para el acceso a los contenidos del servicio de la APP que los requieran y de las consecuencias derivadas de cualquier naturaleza del mal uso por los usuarios, su pérdida u olvido, y su uso indebido por terceros no autorizados.",
    termsConditionsExclusionGarantiasListaELemento11:
      "La incapacidad de cualquier usuario o la suplantación de la personalidad de un tercero efectuada por el usuario.",
    termsConditionsExclusionGarantiasListaELemento12:
      "De los contenidos facilitados por los usuarios.",
    termsConditionsProteccionDatosTitulo: "IX.-  PROTECCIÓN DE DATOS",
    termsConditionsProteccionDatosParrafo1:
      "Puede consultar la información detallada sobre Protección de Datos en nuestra página de",
    termsConditionsLeyJurisdiccionTitulo: "X.- LEY APLICABLE Y JURISDICCIÓN",
    termsConditionsLeyJurisdiccionParrafo1:
      "Todas las Condiciones, Generales y Particulares, insertadas en la presente APP se rigen por la normativa española. Todos los conflictos, controversias o situaciones derivadas de las Condiciones Generales y particulares se someterán a los Juzgados y Tribunales de la Villa de Madrid, renunciando expresamente las partes a su fuero."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Política de privacidad",
    privacyPolicieModalTitle: "POLÍTICA DE PRIVACIDAD",
    privacyPolicieModalLargeTitle: "Política de privacidad",
    privacyPoliciesConditionsParrafo1:
        "De conformidad con lo establecido tanto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD), como en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD) y demás normativa sobre protección de datos vigente, se informa a los usuarios del sitio web, que voluntariamente han manifestado su consentimiento de manera libre, clara e inequívoca para el tratamiento de los datos personales al aceptar la casilla de “Política de protección de datos”, que sus datos van a ser tratados, en función de la empresa en la con la que haya contactado, bien la empresa MAILTECK, S.A. o bien la empresa CUSTOMER COMMUNICATIONS TECKNALIA, S.L., las cuales serán denominadas conjuntamente como GRUPO MAILCOMMS dentro de esta Política de Privacidad.",
    privacyPoliciesConditionsResponsable1Title: "Responsable 1",
    privacyPoliciesConditionsResponsable2Title: "Responsable 2",
    privacyPoliciesConditionsFinalidadTitle: "Finalidad",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimacion",
    privacyPoliciesConditionsDestinatariosTitle: "Destinatarios",
    privacyPoliciesConditionsDerechosTitle: "Derechos",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Información adicional",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Política de la información",
    privacyPoliciesConditionsIdentidadTitle: "Identidad",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Dirección",
    privacyPoliciesConditionsTelefonoTitle: "Teléfono",
    privacyPoliciesConditionsCorreoTitle: "Correo",
    privacyPoliciesConditionsDPDTitle: "Contacto con el DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "Facilitar información solicitada y de gestionar el contacto con posibles clientes.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "En GRUPO MAILCOMMS, la información que usted nos ha facilitado ha sido recabada por una de las empresas del GRUPO MAILCOMMS, con el fin de gestionar y tramitar la solicitud realizada.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, solo tratará los datos facilitados para las finalidades descritas anteriormente y no serán tratados ulteriormente de manera incompatible con dichos fines.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "Con el fin de poder ofrecerle productos y servicios de acuerdo con sus intereses y mejorar su experiencia de usuario elaboraremos un “perfil comercial”, en base a la información facilitada. No obstante, no se tomarán decisiones automatizadas en base a dicho perfil.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "Los datos personales proporcionados se conservarán mientras se mantenga la relación mercantil o no se solicite su supresión por el interesado.",
    privacyPoliciesConditionsLegitimacionIntro: "Consentimiento del interesado.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "El propio interesado facilitando sus datos a través de los canales que las empresas del GRUPO MAILCOMMS facilitan para la recabar datos de contacto (formulario de contacto, visita comercial, etc).",
    privacyPoliciesConditionsDestinatariosIntro: "Empresas colaboradoras",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Empresas colaboras y participadas para fines comerciales.</li>" +
        "<li>- Por exigencia legal cuando una norma así lo exija.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "Puede ejercer sus derechos en la forma y plazos legalmente previstos.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "Confirmación general:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Cualquier persona tiene derecho a obtener confirmación sobre si GRUPO MAILCOMMS se están tratando datos personales que les conciernan.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "Derechos ARCO:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitación del tratamiento:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Oposición al tratamiento:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos.\n" +
        "Por lo tanto, GRUPO MAILCOMMS dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Portabilidad de los datos:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán solicitar recibir los datos personales facilitados en el formulario de contacto, en un formato estructurado, de uso común y legible por máquina, así como transmitir dichos datos a otro responsable del tratamiento sin impedimentos.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Reclamación ante organismo público:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "En caso de considerar vulnerado su derecho a la protección de datos personales, podrá interponer una reclamación ante la Agencia Española de Protección de Datos (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Canal para ejercitar los derechos:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Los interesados podrán ejercer los derechos que les reconoce en la normativa frente a, y en contra de, cada uno de los responsables, ante las direcciones habilitada como DPO en el apartado «Responsable».',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "Más información sobre Protección de Datos.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Los datos personales se han obtenido de los canales que las empresas del CCMS facilitan para la recabar datos de contacto (formulario de contacto, visita comercial, etc).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "Las categorías de datos que se van a tratan son datos de identificación (nombre, apellidos, correo electrónico, etc).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS no trata datos especialmente protegidos.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "Grupo MailComms, Puede consultar la Política de Seguridad y Privacidad de la Información basada en la norma ISO 27001",
    privacyPoliciesConditionsPoliticaInformacionDate:"Versión: 7 | Fecha: 26 de octubre 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANEXO I: POLÍTICA DEL SISTEMA DE GESTIÓN DE SEGURIDAD Y PRIVACIDAD DE LA INFORMACIÓN",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "Esta Política de Seguridad, Continuidad y Privacidad de la Información muestra el compromiso de la Dirección dotando a la organización de recursos, responsabilidades y procedimientos que permitan garantizar el cumplimiento de la normativa vigente, así como la integridad, confidencialidad y disponibilidad de la información y los sistemas, siendo crucial para la seguridad, privacidad y continuidad de nuestra organización, así como la de nuestros clientes.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "Grupo MailComms, consciente de que la seguridad, continuidad y privacidad de la información relativa a nuestros clientes es un recurso con gran valor, ha establecido un Sistema de Gestión de la Seguridad, Continuidad y Privacidad de la Información de acuerdo con los requisitos de las normas ISO/IEC 27001:2013, ISO/IEC 27701:2019 e ISO 22301 para garantizar la continuidad de los sistemas de información, minimizar los riesgos de daño y asegurar el cumplimiento de los objetivos fijados.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "De manera adicional, para el servicio de Entrega Electrónica Certificada, en base a la naturaleza del propio servicio prestado, se han seguido las disposiciones establecidas en el Reglamento Europeo eIDAS que regula los Servicios de Confianza, y concretamente las normas ETSI 319 401 y ETSI 319 521 para Proveedores de Servicios de Entrega Electrónica Certificada que contienen preceptos específicos para la prestación de este tipo de servicio. En este sentido, la Política que regirá este servició será la Declaración de Prácticas de Certificación (en adelante DPC).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "El objetivo de la Política de Seguridad, Continuidad y Privacidad es fijar el marco de actuación necesario para proteger los recursos de información frente a amenazas, internas o externas, deliberadas o accidentales, con el fin de asegurar el cumplimiento de la confidencialidad, integridad y disponibilidad de la información.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "La eficacia y aplicación del Sistema de Gestión de la Seguridad, Continuidad y Privacidad de la Información es responsabilidad directa del Comité de la Seguridady Privacidad de la Información, el cual es responsable de la aprobación, difusión y cumplimiento de la presente Política de Seguridad, Continuidad y Privacidad. En su nombre y representación se ha nombrado un Responsable del Sistema de Gestión de la Seguridad y Privacidad de la Información, que posee la suficiente autoridad para desempeñar un papel activo en el Sistema de Gestión de la Seguridad y Privacidad de la Información, supervisando su implantación, desarrollo y mantenimiento.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "Adicionalmente, y para dar cumplimiento a la normativa vigente de protección de datos, se ha nombrado un Delegado de Protección de datos (DPO) con el objeto de garantizar la seguridad y privacidad de los datos de carácter personal, especialmente teniendo en cuenta la actividad de la compañía.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "El Comité de Seguridad y Privacidad de la Información procederá a desarrollar y aprobar la metodología de análisis de riesgos utilizada en el Sistema de Gestión de la Seguridad, Continuidad y Privacidad de la Información.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Toda persona cuya actividad pueda, directa o indirectamente, verse afectada por los requisitos del Sistema de Gestión de la Seguridad, Continuidad y Privacidad de la Información, está obligada al cumplimiento estricto de la Política de Seguridad, Continuidad y Privacidad y adicionalmente, para el Servicio de Entrega Electrónica Certificada, deberá cumplir con la DPC.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "En Grupo MailComms se implantarán todas las medidas necesarias para cumplir la normativa aplicable en materia de seguridad, continuidad y privacidad en general y de seguridad informática, relativa a la política informática, a la seguridad de edificios e instalaciones y al comportamiento de empleados y terceras personas asociadas con Grupo MailComms en el uso de sistemas informáticos y tratamiento de datos personales. Las medidas necesarias para garantizar la seguridad y privacidad de la información mediante la aplicación de normas, procedimientos y controles deberán permitir asegurar la confidencialidad, integridad, disponibilidad de la información, esenciales para:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Cumplir con la legislación, reglamentación y normativa vigente en materia de continuidad, privacidad y de los sistemas de información y con todos los requisitos contractuales en materia de protección de datos y servicios electrónicos de confianza, así como los que considere oportuno llevar a cabo con el objetivo de lograr una mejora continua de la organización y del sistema de gestión de seguridad, continuidad y privacidad.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Asegurar la confidencialidad de los datos gestionados por Grupo MailComms.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Asegurar la disponibilidad de los sistemas de información, tanto en los servicios ofrecidos a los clientes como en la gestión interna.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Asegurar la capacidad de respuesta ante situaciones de emergencia, restableciendo el funcionamiento de los servicios críticos en el menor tiempo posible.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Evitar alteraciones indebidas en la información.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promover la concienciación y formación en seguridad, continuidad y privacidad de la información y en materia de servicios electrónicos de confianza.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Impulsar y participar en conseguir la mejora continua del sistema de gestión de la seguridad, continuidad y privacidad de la información y servicios electrónicos de confianza.",
    privacyPoliciesConditionsPoliticaInformacionP10: "Las Políticas para la Seguridad y Privacidad de la Información y la DPC deben revisarse a intervalos planificados, como mínimo anualmente y siempre que se produzcan cambios significativos en la organización, a fin de asegurar que se mantenga su idoneidad, adecuación y eficacia."
  },
  cookies: {
    cookiesPoliciesCompliance: "Política de Cookies",
    cookiesPoliciesIntroText:
      "Este sitio web utiliza cookies para mejorar su experiencia mientras navega pemor el sitio web. Puede cambiar estos ajustes en cualquier momento. Las cookies clasificadas como necesarias se almacenan en su navegador y son esenciales para el funcionamiento de las funcionalidades básicas del sitio web. Las cookies de terceros se almacenarán en su navegador solo con su consentimiento y nos ayudan a analizar y comprender cómo se utiliza este sitio web. Tiene la opción de optar por no recibir estas cookies, sin embargo, la exclusión voluntaria de alguna de esta cookies puede hacer que algunas funciones dejen de estar disponibles. Para obtener información sobre eliminar las cookies, por favor consulte la función de ayuda de su navegador.",
    cookiesPoliciesNecessaryCookies: "Cookies necesarias",
    cookiesPoliciesNecessaryCookiesText:
      "Este tipo de cookies son absolutamente esenciales para que el sitio web funcione correctamente. Esta categoría solo incluye cookies que garantizan funcionalidades básicas y características de seguridad del sitio web, y no recopilan o almacenan ninguna información personal sobre los usuarios del sitio web.",
    cookiesPoliciesUnnecessaryCookies: "Cookies no necesarias",
    cookiesPoliciesUnnecessaryCookiesText:
      "Las cookies que pueden no ser particularmente necesarias para que el sitio web funcione y se utilizan específicamente para recopilar datos personales del usuario a través de análisis, anuncios y otros contenidos integrados se denominan cookies no necesarias. Es obligatorio obtener el consentimiento del usuario antes de ejecutar estas cookies en su sitio web.",
    cookiesPoliciesGoogleAnalyticsCookies: "Cookies de Google Analytics",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Para obtener más información respecto a este tipo de cookies, puede obtener más información consultando el documento de “Uso de las cookies de Google Analytics en sitios web",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Mantener cookies activas",
    cookiesNotificationBarText:
      "Utilizamos cookies, tanto propias como de terceros, para medir las visitas, garantizar la funcionalidad del sitio web y analizar la navegación en el mismo. A continuación puede aceptar las cookies o configurar/rechazar el uso de las mismas. Puede obtener más información sobre la titularidad de la web, responsable del tratamiento de las cookies y de sus datos de contacto en nuestra",
    cookiesNotificationBarConfig: "Configurar",
    cookiesPoliciesModalIntro: "Introducción",
    cookiesPoliciesModalIntroP1:
      "Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas para mejorar y optimizar la experiencia del usuario.",
    cookiesPoliciesModalIntroP2:
      "En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocer como usuario, obtener información sobre hábitos de navegación, o personalizar la forma en que se muestra el contenido.",
    cookiesPoliciesModalIntroP3:
      "De conformidad con el Real Decreto Ley 13/2012 y su afectación en el artículo 22.2 de la Ley de Servicios de la Sociedad de la Información y Comercio Electrónico, mediante la presente Política se le informa de las diferentes cookies que CUSTOMER COMMUNICATIONS TECKNALIA, S.L. recaba a través de los distintos medios o canales de comunicación, propias o de terceros, exentas o no del deber de informar y los diferentes medios a través de los cuales puede rechazar, permitir o bloquear aquellas.",
    cookiesPoliciesModalIntroP4:
      "A continuación se detalla información sobre: qué son las “Cookies”, qué tipología utiliza este sitio web, cómo pueden ser desactivarlas en un navegador y cómo bloquear específicamente la instalación de Cookies de terceros.",
    cookiesPoliciesModalResponsibility:
      "¿Quién es el responsable del uso de las cookies en el presente sitio web?",
    cookiesPoliciesModalResponsibilityP1:
      "El responsable del uso de la cookies es CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en adelante CCOMMS), con N.I.F. número B-86414000 y con domicilio social en Leganés (Madrid) Avenida de la Recomba, 14, debidamente inscrita en el Registro Mercantil de Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Correo electrónico del Delegado de Protección de Datos.",
    cookiesPoliciesModalWhatIsCookie: "¿Qué es una cookie?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Una cookie es un dato, fichero o dispositivo de almacenamiento y recuperación de datos (DARD), que un navegador web almacena de forma automática en el dispositivo (Smartphone, tableta o televisión conectada) de un usuario al acceder a páginas web, cuentas de redes sociales o al recibir un correo electrónico.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Las cookies permiten almacenar y recuperar de forma automatizada datos sobre el uso que se ha hecho de los citados medios digitales y del equipo o dispositivo desde el que accedes a los mismos.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Una cookie son sólo datos que se almacenan en el ordenador del usuario, por lo que no son software, tampoco son fragmentos de código, son simplemente datos. Por tanto, en principio las cookies no pueden transmitir y ejecutar virus, ni instalar malware como troyanos o programas de espionaje.",
    cookiesPoliciesModalCookieTypes:
      "Qué tipo de cookies hay en la página web y su finalidad",
    cookiesPoliciesModalCookiesInstall:
      "¿Qué cookies se pueden instalar en la web de Verificación de Certificados?",
    cookiesPoliciesModalCookiesInstallP1:
      "El acceso de Web de Verificación de Certificados implica que se puedan instalar las Cookies, propios y/o de tercero, que se detallan en el siguiente cuadro:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Cómo configurar las cookies en el navegador",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Usted tiene la opción de no recibir cookies o de ser informado acerca de su fijación mediante la configuración de su navegador a tal efecto, todas las cookies, salvo las de Flash, puede ser gestionadas desde su navegador, normalmente en la sección “Opciones” o “Preferencias”.",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Puede desactivarlas del todo, en cuyo caso su navegador no permitirá que se instale ninguna (imposibilitando que pueda hacer uso de todas las funcionalidades de la página web), o sólo las que provenga de determinadas webs, lo que le permitirá poder disfrutar de las funcionalidades avanzadas de las webs que respetan la privacidad de sus datos, y excluir aquellas que no lo hagan.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "En los siguientes enlaces le facilitamos los accesos a las principales páginas de soporte:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Asimismo, el Usuario puede oponerse al uso de determinadas cookies mediante los siguientes servicios:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Bloqueo de cookies en Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Bloqueo de cookies en Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Bloqueo de cookies en Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Bloqueo cookies en Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "¿Qué ocurre si se deshabilitan las cookies?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Si rechaza las cookies podrá seguir navegando por nuestro Sitio Web, aunque el uso de algunos de sus servicios podrá ser limitado y, por tanto, su experiencia puede resultar menos satisfactoria.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "El rechazo, bloqueo o no permisibilidad de las cookies descritas en la presente Política puede afectar a una correcta navegación por los contenidos reproducidos en el sitio web.",
    cookiesPoliciesModalAcceptCookies: "Aceptación de la política de cookies",
    cookiesPoliciesModalAcceptCookiesP1:
      "Pulsando el botón “ACEPTAR COOKIES” se asume que usted acepta el uso de cookies.",
    cookiesPoliciesModalAcceptCookiesP2:
      "No obstante, con cada inicio de sesión se mostrará la Política de cookies en la parte inferior de cualquier página del portal con el objeto de que usted sea consciente.",
    cookiesPoliciesModalUpgradeCookies:
      "¿Actualizamos nuestra política de cookies?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "Es posible que la información proporcionada en la presente Política de Cookies, pueda sufrir modificaciones debidas a actualizaciones o cambios en la política de tratamiento de cookies, así mismo, la Agencia Española de Protección de Datos pudiere dictar instrucciones y/o recomendaciones que hicieren variar la misma.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Por ello le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.",
    cookiesPoliciesModalRecipientCookies:
      "¿Quienes son los destinatarios de la información y coómo el uso de cookies afecta a la protección de datos personales?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Para más información sobre el tratamiento de sus datos puede consultar la ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS:
      "Política de Protección de datos",
    cookiesPoliciesModalContactInfoCookies: "Información de contacto",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Para cualquier duda, aclaración y/o sugerencia al respecto de las cookies, rogamos se pongan en contacto a través de correo electrónico ",
    cookiesPoliciesModalDatatableCookieType: "Tipo de Cookie",
    cookiesPoliciesModalDatatableTreatmentTerm: "Plazo de Tratamiento",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analíticas",
    cookiesPoliciesModalDatatableTypeSelf: "Propias",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Cada vez que un Usuario visita un Servicio, una herramienta de un proveedor externo genera una Cookie analítica en el ordenador del Usuario. Esta Cookie, que sólo se genera en la visita, servirá en próximas visitas a CCOMMS para identificar de forma anónima al visitante.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Permitir la identificación anónima de los Usuarios navegantes a través de la “Cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo. Identificar de forma anónima los contenidos más visitados y más atractivos para los Usuarios. Saber si el Usuario que está accediendo es nuevo o repite visita. Salvo que el Usuario decida registrarse en CCOMMS, la “Cookie” nunca irá asociada a ningún dato de carácter personal que pueda identificarle directamente. Dichas Cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización y mejora de la experiencia de los Usuarios en el sitio.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistentes",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Este tipo de Cookies permiten ampliar la información mostrada a cada Usuario anónimo en los Servicios de CCOMMS.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Entre otros, se almacena la duración o frecuencia de visualización de las distintas secciones, la interacción con las mismas, o los patrones de navegación y/o comportamientos del Usuario.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistentes",
    description: "Descripción",
    privacyPoliciesConditionsFinalidadTitle: "Finalidad",
    accept: "Aceptar",
    reject: "Rechazar",
    save: "Guardar",
    cookiesPoliciesCertySign: "Cookies en Verificación de Certificados",
    cookiesNecessaryCookieDescription:
      "Esta cookie es necesaria y se utiliza para establecer la preferencia del usuario al respecto de la política de cookies.",
    cookiesGAMessage:
      "Medición interna con Google Analytics. Estas cookies almacenan un identificador único para reconocer al usuario las futuras ocasiones que visite el sitio web.",
    cookiesGAFGenericMessage: "Medición interna con Google Analytics."
  },
  conditionsOfService: 'Condiciones del servicio',
  certificationPractiseStatement: 'Declaración de prácticas del  Servicio de Confianza',
  altAriaAttributes: {
    closeIcon: "icono cerrar",
    zoomTRID: "zoom trid",
    zoomCSV: "zoom csv",
    sampleCertificate: "Certificado modelo",
    downloadButton: "botón descargar",
    checkboxActiveUser: "Marcar casilla",
    selectLanguage: "Seleccionar idioma",
    openHelpDialog: "Abrir diálogo de soporte",
    trid: "Escribir TRID",
    csv: "Escribir CSV",
    part1Container: "1 - Indicador de posición del identificador único de transaccion (trid) en el certificado modelo",
    part2Container: "2 - Indicador de posición del identificador único de certificado (csv) en el certificado modelo",
    sampleTrid: "Identificador único de transacción (trid) en el certificado modelo",
    sampleCSV: "Identificador único de certificado (csv) en el certificado modelo"
  }
};
