export default {
  name: "certificatePreview",
  data: function() {
    return {
      showPreview: null
    };
  },
  methods: {
    handleClickOutside() {
      this.showPreview = false;
    }
  }
};
