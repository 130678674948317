import termsAndConditions from "./components/termsAndConditions";
import privacyPolicy from "./components/privacyPolicy";
import cookies from "./components/cookies";
import cookiesConfigure from "./components/cookiesConfigure";

import EventBus from "../../../plugins/helpers/eventBus";
import ConditionsOfService from '@/App/sections/footer/components/conditionsOfService/index.vue'
import CertificationPractiseStatement from '@/App/sections/footer/components/certificationPractiseStatement/index.vue'

export default {
  name: "footerComponent",
  components: {
    CertificationPractiseStatement,
    ConditionsOfService,
    termsAndConditions,
    privacyPolicy,
    cookies,
    cookiesConfigure
  },
  data() {
    return {
      cookiesBar: true,
      unnecesaryCookies: true,
      googleCookies: true
    };
  },
  methods: {
    redirectToHome() {
      if (this.$route.name !== "home") {
        //setLoader
        EventBus.$emit("set:loader", true);
        setTimeout(() => {
          EventBus.$emit("set:loader", false);
        }, 1000);

        this.$router.push({ name: "home" });
      }
    },
    getCookie(name) {
      if (name === "CScookiesPolicyAccepted") {
        let nameEQ = name + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    saveCookies(status) {
      // console.log(status);
      // console.log(
      //   status &&
      //     status === "ok" &&
      //     this.unnecesaryCookies &&
      //     this.googleCookies
      // );
      if (
        status &&
        status === "ok" &&
        this.unnecesaryCookies &&
        this.googleCookies
      ) {
        this.setCookie("CScookiesPolicyAccepted", "true", 30);
      } else {
        this.setCookie("CScookiesPolicyAccepted", "false", 30);
        this.delAnalyticsCookies();
      }
      this.cookiesBar = false;
    },
    delAnalyticsCookies() {
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (c.indexOf(name) == 0 || c.indexOf(name) == 1) {
          let cd = c.split("=");
          let cookieToDelete = cd[0].trim();
          if (cookieToDelete.includes("_ga")) {
            document.cookie =
              cookieToDelete +
              "=; Path=/; Domain=.certy-sign.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        }
      }
    },
    openCookiesPolicy() {
      EventBus.$emit("open:cookies", true);
    },
    openCookiesConfigure() {
      EventBus.$emit("open:cookiesConfigure", true);
    }
  },
  mounted() {
    EventBus.$on("close:cookiesBar", () => {
      this.cookiesBar = false;
    });

    EventBus.$on("saveUnnecessary", params => {
      this.unnecesaryCookies = params.unnecessaryCookies;
      this.googleCookies = params.googleAnalyticsCookies;
    });
  },
  created() {
    this.details = false;
    const isCookie = this.getCookie("CScookiesPolicyAccepted");
    if (isCookie === null) {
      this.cookiesBar = true;
    } else {
      this.cookiesBar = false;
    }
  }
};
