export default {
  app: {
    header: {
      language: "Hizkuntza"
    }
  },
  home: {
    title: "Ziurtagiriak online egiaztatzea",
    trid: "Transakzioaren identifikatzaile bakuna (trid)",
    csv: "Ziurtagiriaren identifikatzaile bakuna (csv)",
    description:
      "Bete hurrengo datuak zure esku dagoen bukatze-aitorpenaren edo ziurtagiriaren egiazkotasuna egiaztatzeko.",
    subtitle: "BALIDATZE-KODEA",
    validate: "Balidatu",
    resultError: "Mesedez, berrikusi sartutako datuak",
    messagesInputs: {
      required: "Nahitaezkoa",
      minimo: "4 karaktere gutxienez",
      maximo: "1000 karaktere gutxienez"
    }
  },
  confirmation: {
    title: "Zure datuak hurrengo ziurtagiriari dagozkio",
    subtitle:
      "Ziurtagiria beheko botoian deskargatu dezakezu eta zure informazioarekin bat datorren ikusi.",
    download: "Deskargatu",
    back: "Itzuli",
    certificateDownloaded: "Ongi deskargatu da zure ziurtagiria"
  },
  helpDialog: {
    messageSent: {
      title: "Euskarriarekin harremanetan jarri",
      subtitle:
        "Zure eskaera gure Euskarriaren sailera bidali da, agente bat zurekin jarriko da harremanetan ahal bezain laster.",
      back: "Itzuli"
    },
    text: "Laguntzarik behar al duzu?",
    contactWithSupport: "Euskarriarekin harremanetan jarri",
    dialogPhrase:
      "Bete datuak eta euskarri teknikoaren taldeak ahal bezain laster erantzungo dizu. Arreta-ordutegia, lanegunetan:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Astelehenetik ostiralera</strong>: 8:00 h - 17:00 h</p>",
    message: "Mezua",
    sendRequest: "Bidali eskakizuna",
    requiredEmailFormat: "Emailaren formatua ez da zuzena",
    requiredEmail: "Emaila behar da",
    requiredMessage: "Mezua behar da",
    requiredName: "Izena behar da",
    email: "Helbide elektronikoa",
    name: "Izena"
  },
  termsAndConditions: {
    title: "Zehaztapenak eta baldintzak",
    termsConditions1:
      "CUSTOMER COMMUNICATIONS TECKNALIA, S.L.-k (aurrerantzean CCOMMS), APP honen titular eta arduradun gisa duen izaera dela eta, uztailaren 11ko 34/2002 Legearen 10. artikuluan ezarriari jarraikiz, Informazioaren Gizarteko Zerbitzuena eta Merkataritza Elektronikoarena, erabiltzaileen esku jartzen du APP honi buruzko honako informazio orokor hau:",
    companyInfo:
      "<p class='mb-1'><strong>Titularra</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Egoitza soziala</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madril)</p><p class='mb-1'><strong>Erregistroko datuak</strong>: Madrilgo Merkataritza Erregistroa, 29.801 Liburukia, 98. Folioa, 8. Atala, M-536210 Orria.</p><p class='mb-1'><strong>Kontaktua: Telefonoa</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Datuak Babesteko Ordezkariaren posta elektronikoa</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Mailteck-eko Datuak Babesteko Ordezkaria'>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.-XEDEA",
    termsConditionsObjetoParrafo1:
      "Erabilera Baldintza Orokor hauek (aurrerantzean, 'EBO') CCOMMSek erabiltzaileen esku jartzen duen Ziurtagiriaren Balidazioaren (aurrerantzean, APPa) erabilera arautzen dute, egindako eskaera gauzatzeari ekiteko helburuarekin.",
    termsConditionsObjetoParrafo2:
      "Hortaz, EBO hauek APPren bitartez egiten den sustapen-jardueran eta informazioaren banaketan aplikatuko dira, eta baita bertan eskainitako zerbitzuetan ere; hori dela eta, APPan zehar nabigatzeko edota APParen markoan zerbitzuak kontratatzeko jarduerak zuzenduko dituzte, eta azkeneko jarduera hauek, EBOez gain, aplikatu beharreko Kontratazioaren Baldintza Orokorren eta Baldintza Berezien (egongo balira) mende ere egongo dira.",
    termsConditionsUsuariosTitulo: "II.-ERABILTZAILEAK",
    termsConditionsUsuariosParrafo1:
      "APPra sartzeak edota hura erabiltzeak erabiltzaile izaera ematen dio hori egin duenari, eta beraz, une horretatik aurrera eta inolako erreserbarik gabe, EBO hauek eta EBOak osatzen, aldatzen edo ordezkatzen dituzten Baldintza Bereziak onartzen ditu, APPren zerbitzu eta eduki jakin batzuei dagokienez; eta derrigortuta dago CCOMMSek une bakoitzean orrialdearen erabilerarekin lotuta ezartzen dituen murriztapenak errespetatzera.",
    termsConditionsAccesoAPPTitulo: "III.-APP-RAKO SARBIDEA",
    termsConditionsAccesoAPPParrafo1:
      "APPrako sarbidea eta erabilera mugatuta dago, eta bertara sartzeko, ezinbestekoa izango da erabiltzailea aurrez erregistratzea.",
    termsConditionsUsoAPPTitulo: "IV.-APP-REN ERABILERA",
    termsConditionsUsoAPPParrafo1:
      "Erabiltzailea konprometitzen da APPa, bere zerbitzuak eta edukiak indarrean dagoen legedia, fede ona, orokorrean onartutako erabilerak eta ordena publikoa urratu gabe erabiltzera. Halaber, debekatuta dago APPa legez kontrako edo CCOMMSen edo hirugarrenen aurkako helburuekin erabiltzea, edo APPren ohiko funtzionamendua kaltetu edo galarazi dezakeen era batean erabiltzea.",
    termsConditionsResposabilidadTitulo:
      "V-. KALTE-GALEREN ONDORIOZKO ERABILTZAILEAREN ERANTZUKIZUNA",
    termsConditionsResposabilidadParrafo1:
      "APPren erabileraren erantzukizuna erabiltzailearena bakarrik izango da. Erantzukizun hori erabiltzaileak edo hirugarren batek APPra edo bere edozein zerbitzutara sartzeko esleituta duen pasahitzaren edo antzekoen erabilerara zabalduko da.",
    termsConditionsResposabilidadParrafo2:
      "Aurrekoari kalterik egin gabe, CCOMMSek beretzat gordetzen du edozein unetan eta aurrez jakinarazteko beharra izan gabe, APPrako sarbidea ukatzeko edo desgaitzeko eskubidea, EBO hauek edo CCOMMSek edo bere kolaboratzaileek ezarritako arauak hausten dituzten erabiltzeei, edo CCOMMSen edo bere kolaboratzaileen funtzionamendua, irudia, sinesgarritasuna edota izen ona asaldatu dezaketen erabiltzaileei.",
    termsConditionsResposabilidadParrafo3:
      "Ildo honetan, CCOMMSek beretzat gordetzen du Erabiltzaile jakin batzuei APPrako sarbidea ezeztatzeko edo mugatzeko ahalmena, baldin eta CCOMMSen iritziz Erabilera Baldintza hauen, Legearen, CCOMMSek edo bere kolaboratzaileek ezarritako arauen aurkako edo CCOMMSen edo bere kolabroatzaileen funtzionamendua, irudia, sinesgarritasuna edota izen ona asaldatu dezaketen portaerak baldin badaude. Halaber, CCOMMSek APPa modu okerrean erabiltzetik eratorritako kalte-galeren erantzukizuna eskatu ahalko die Erabiltzaileei.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMSek ez du bermatzen erabiltzaileek APPa erabiltzen duten bitartean transmititzen dituzten edukien zilegitasuna, fidagarritasuna, erabilgarritasuna, egiatasuna eta zehaztasuna.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMSek ez du kontrolatzen erabiltzaileek APPrekin egiten duten erabilera, eta ez du bermatzen Baldintza Orokor hauek jarraikiz egingo dutenik ere.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMSek ez du kontrolatzen eta bermatzen hirugarrenek APPren bitartez eskainitako zerbitzuetan birus informatikorik egongo ez denik, sistema informatikoan (software eta hardware) edo bere sistema informatikoan biltegiratutako dokumentu eta fitxategi elektronikoetan nahasmenduak eragin ditzaketenak.",
    termsConditionsPropiedadIntelectualTitulo: "VI.-JABETZA INTELEKTUALA",
    termsConditionsPropiedadIntelectualParrafo1:
      "Materiala edo Edukiak Baldintza Orokorretan baimentzen dena ez den beste helburu baterako erabiltzea edo aldatzea, egile-eskubideak babesten dituzten 'copyright'-aren nazioarteko legeen urraketa gisa hartuko da.",
    termsConditionsPropiedadIntelectualParrafo2:
      "Edukiei dagokienez, debekatuta dago:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "Edozein modutan eta edozein helbururekin erreproduzitzea, kopiatzea, banatzea, aldatzea edo manipulatzea, baldin eta legezko titularren aurretiko baimen idatzia ez badago edota legeak baimentzen ez badu.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "CCOMMSen edo bere legezko titularren eskubideak edozein modutan urratzea.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "Hertsiki baimendutakoak ez diren merkataritza edo publizitate helburuetarako erabiltzea.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMSek bermatzen du, edukiak, jabetza intelektualekoak barne, ez direla legez kontrakoak eta ez dutela indarrean dagoen legedia urratzen. Ondorioz, APPko edukiek ez dute izaera xenofobo, pornografiko, diskriminatzaile edo arrazistarik izango, ezta indarkeria bultzatzen duenik ere.",
    termsConditionsPropiedadIntelectualParrafo4:
      "Gainera, CCOMMSek komenigarritzat jotzen dituen legezko neurriak hartuko ditu Legearen edo moralaren aurkako portaera oro galarazteko.",
    termsConditionsModificacionUnilateralTitulo: "VIII.-ALDEBAKARREKO ALDAKETA",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMSek egokitzat hartzen duenean, alde bakarretik eta aurrez jakinarazi gabe aldatu ahal izango du APPren egitura eta diseinua, baita APPren zerbitzuak, edukiak eta sarbide eta erabilera baldintzak aldatu edo ezabatu ere.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- BERMEEN ETA ERANTZUKIZUNAREN BAZTERKETA",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMSek ez du inolako bermerik ematen eta inola ere ez da honako jarduera hauen ondorioz sor daitezkeen kalte-galeren erantzule egiten:",
    termsConditionsExclusionGarantiasListaELemento1:
      "Legea, morala, ohitura onak eta ordena publikoa urratzea, baita eskainitako produktuak edota zerbitzuak modu arduratsuan eta egokian ez erabiltzea ere, edo EBO hauetan edo aplikatu beharreko baldintzetan (aplikatu beharko balira) ezarritako helburuen aurka edo legearen aurka erabiltzea.",
    termsConditionsExclusionGarantiasListaELemento2:
      "CCOMMSen APPko sarbide baten bitartez eskuratu edo hirugarrenei kontratatu daitezkeen zerbitzuak, ondasunak edo produktuak eskuratu edo kontratatzeagatik, bereziki, erosketa edo kontratazio-prozesua zuzenean hirugarrenaren app-ean egiten denean eta app-aren elementu grafikoak (Branding) dituen bereizgarriak edo 'frame' bat agertu arren.",
    termsConditionsExclusionGarantiasListaELemento3:
      "APPren edota bere zerbitzu edo edukien erabilgarritasun, mantentze eta funtzionamendu egokiaren falta.",
    termsConditionsExclusionGarantiasListaELemento4:
      "APPren edota bere zerbitzu edo edukien erabilgarritasun, egokitzapen edo baliotasun falta, erabiltzaileen beharrak, jarduerak, emaitza zehatzak edo itxaropenak asebetetzeari dagokionez.",
    termsConditionsExclusionGarantiasListaELemento5:
      "Edukietan birusak edo programa gaiztoak eta kaltegarriak egotea.",
    termsConditionsExclusionGarantiasListaELemento6:
      "Erabiltzaileek edukiak jasotzea, lortzea, biltzea, hedatzea edo transmititzea.",
    termsConditionsExclusionGarantiasListaELemento7:
      "Erabiltzaileei transmititutako edo haien esku jarritako edukien eta informazioen egiatasun, zehaztasun, fidagarritasun, egokitasun, gaurkotasun eta sakontasun falta, hirugarrenek edo erabiltzaileek APPren bitartez eskainitako informazioak eta zerbitzuak barne.",
    termsConditionsExclusionGarantiasListaELemento8:
      "Hirugarrenek APPren bitartez erabiltzaileei eskainitako zerbitzuei dagokienez dituzten betebeharrak eta konpromisoak haustea.",
    termsConditionsExclusionGarantiasListaELemento9:
      "APPren mende ez dauden eta CCOMMSek kudeatzen ez dituen eduki, fitxategi, informazio, publizitate, iritzi, kontzeptu eta irudiena. Ez da APPko edukiekin egiten den erabilera okerraren erantzule egiten ere, erantzukizun osoa edukiak atzitzen edo erabiltzen dituen pertsonarena baita.",
    termsConditionsExclusionGarantiasListaELemento10:
      "Sarbide-gakoak behar dituzten APParen zerbitzuetako edukietan, erabiltzaileen sarbide-gakoen erabilera okerrarena, eta erabiltzaileek horiekin egiten duten erabilera okerretik, gakoak galtzetik edo ahaztetik eratorritako ondorioena, eta baimenik ez daukaten hirugarrenek egindako erabilera okerrarena.",
    termsConditionsExclusionGarantiasListaELemento11:
      "Erabiltzaile baten ezintasuna edo erabiltzaileak hirugarren baten identitatea ordeztea.",
    termsConditionsExclusionGarantiasListaELemento12:
      "Erabiltzaileek emandako edukiena.",
    termsConditionsProteccionDatosTitulo: "IX.-DATUEN BABESA",
    termsConditionsProteccionDatosParrafo1:
      "Datuen Babesari buruzko informazio zehatza kontsulta dezakezu gure orrialdean",
    termsConditionsLeyJurisdiccionTitulo:
      "X.-APLIKATU BEHARREKO LEGEA ETA JURISDIKZIOA",
    termsConditionsLeyJurisdiccionParrafo1:
      "APP honetan barne hartzen diren Baldintza guztiak, Orokorrak eta Bereziak, Espainiako araudiari jarraitzen diote. Baldintza Orokorretatik eta Berezietatik eratorritako gatazka, eztabaida edo egoera guztiak Madrilgo hiriko Epaitegien eta Auzitegien mende egongo dira, eta alderdiek berariaz uko egingo diote bere foruari."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  cookies: {
    cookiesPoliciesCompliance: "Cookieen politika",
    cookiesPoliciesIntroText:
      "Web orri honek cookieak erabiltzen ditu webgunean nabigatzen ari zaren bitartean zure esperientzia hobetzeko. Ezarpen hauek edozein unetan alda ditzakezu. Beharrezkotzat sailkatutako cookieak zure nabigatzailean biltegiratuko dira eta funtsezkoak dira webgunearen oinarrizko funtzionaltasunek funtzionatzeko. Hirugarrenen cookieak zure nabigatzailean biltegiratuko dira, soilik zure baimenarekin, eta webgune hau nola erabiltzen den aztertzen eta ulertzen laguntzen digute. Cookie hauek ez jasotzea erabakitzeko aukera daukazu, baina cookie hauek nahita baztertzeak berekin ekar dezake funtzio batzuek erabilgarri egoteari uztea. Cookieak ezabatzeari buruzko informazioa lortzeko, kontsultatu zure nabigatzaileko laguntzaren funtzioa.",
    cookiesPoliciesNecessaryCookies: "Beharrezko cookieak",
    cookiesPoliciesNecessaryCookiesText:
      "Mota honetako cookieak erabat funtsezkoak dira webguneak behar bezala funtziona dezan. Kategoria honek webgunearen oinarrizko funtzionaltasunak eta segurtasun-ezaugarriak bermatzen dituzten cookieak soilik barne hartzen ditu, eta ez dute webguneko erabiltzaileei buruzko inolako informazio pertsonalik biltzen.",
    cookiesPoliciesUnnecessaryCookies: "Beharrezkoak ez diren cookieak",
    cookiesPoliciesUnnecessaryCookiesText:
      "Webguneak funtzionatu ahal izateko bereziki beharrezkoak ez diren cookiei, zehazki erabiltzailearen datu pertsonalak biltzeko erabiltzen direnei, azterketen, iragarkien eta beste eduki batzuen bitartez, beharrezkoak ez diren cookieak esaten zaie. Derrigorrezkoa da erabiltzailearen baimena izatea cookie hauek bere webgunean egikaritu aurretik.",
    cookiesPoliciesGoogleAnalyticsCookies: "Google Analytics-en cookieak",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Mota honetako cookiei buruzko informazio gehiago izateko, 'Google Analytics-en cookien erabilera webguneetan' dokumentua kontsulta dezakezu.",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Cookieak aktibo mantendu",
    cookiesNotificationBarText:
      "Gure eta hirugarrenen cookieak erabiltzen ditugu webguneko bisitak neurtzeko, funtzionaltasuna bermatzeko eta bertako nabigazioa aztertzeko. Jarraian, cookieak onar ditzakezu edo cookien erabilera konfiguratu/baztertu dezakezu. Webaren titulartasunari buruzko informazio gehiago lor dezakezu, zeina cookien eta zure harremanetarako datuen tratamenduaren arduraduna den, hemen",
    cookiesNotificationBarConfig: "Konfiguratu",
    cookiesPoliciesModalIntro: "Sarrera",
    cookiesPoliciesModalIntroP1:
      "Webgune honek nabigatzen duzun bitartean informazioa biltegiratzen eta berreskuratzen duten cookieak edota antzeko teknologiak erabiltzen ditu, erabiltzailearen esperientzia hobetzeko eta optimizatzeko.",
    cookiesPoliciesModalIntroP2:
      "Orokorrean, teknologia hauek helburu oso ezberdinetarako balio dezakete, esaterako, erabiltzaile bat ezagutzeko, nabigazio-ohiturei buruzko informazioa lortzeko, edo edukia erakusteko modua pertsonalizatzeko.",
    cookiesPoliciesModalIntroP3:
      "13/2012 Errege Lege-dekretuarekin eta Informazioaren Gizartearen eta Merkataritza Elektronikoaren Zerbitzuen Legearen 22.2 artikuluarekin bat etorriz, Politika honen bidez, alde batetik, CUSTOMER COMMUNICATIONS TECKNALIA, S.L.-k biltzen dituen cookie ezberdinen berri ematen zaizu, bere nahiz hirugarrenen komunikazio-bide eta -kanalen bitartez jasotzen dituenak, informatzeko beharretik salbuetsita daudenak ala ez, eta beste alde batetik, horiek ukatu, ahalbidetu edo blokeatzeko erabil ditzakezun bide ezberdinak azaltzen zaizkizu.",
    cookiesPoliciesModalIntroP4:
      "Jarraian, honi buruzko informazioa zehazten da: 'Cookieak' zer diren, webgune honek zein tipologia erabiltzen duen, nabigatzaile batean nola desaktiba daitezkeen eta zehazki hirugarrenen Cookien instalazioa nola blokea daitekeen.",
    cookiesPoliciesModalResponsibility:
      "Zein da webgune honetako cookien erabileraren arduraduna?",
    cookiesPoliciesModalResponsibilityP1:
      "Cookien erabileraren arduraduna CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (aurrerantzean, CCOMMS) da, B-86414000 I.F.Z duena eta egoitza soziala Leganesen (Madril) duena, Recomba hiribidea 14 helbidean; eta Madrilgo Merkataritza Erregistroan behar bezala inskribatuta dago, 29.801 Liburukian, 98. folioan, 8. atalean, M-536210 orrian.",
    cookiesPoliciesModalResponsibilityP2:
      "Datuak Babesteko Ordezkariaren posta elektronikoa.",
    cookiesPoliciesModalWhatIsCookie: "Zer da cookie bat?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Cookie bat datu bat, fitxategi bat edo datuak biltegiratzeko eta berreskuratzeko gailu bat da (DBBG), web nabigatzaile batek erabiltzaile baten gailuan (Smartphone, tableta edo telebista) automatikoki biltegiratzen dituen datuak, alegia, web orrietan edo sare sozialetako kontuetan sartzean edo posta elektroniko bat jasotzean.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Cookiek aipatu baliabide digitalekin eta horietara sartzeko tresna edo gailuarekin egindako erabilerari buruzko datuak modu automatikoan biltegiratzea eta berreskuratzea ahalbidetzen dute.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Cookie bat erabiltzailearen ordenagailuan biltegiratzen diren datuak soilik dira, hortaz, ez dira software bat edo kode-zatiak, datu soilak dira. Beraz, hasiera batean, cookiek ezin dute birus bat transmititu edo egikaritu, ezta malwareak instalatu ere, esaterako, troiarrak edo espioitza-programak.",
    cookiesPoliciesModalCookieTypes:
      "Zein cookie mota daude web orrian eta zein helburu dute?",
    cookiesPoliciesModalCookiesInstall:
      "Zein cookie instala daitezke CertySign-en web orrian?",
    cookiesPoliciesModalCookiesInstallP1:
      "Ziurtagirien Egiaztatzeko web-sarbideak hurrengo taulan adierazten diren Cookieak, bereak edota hirugarrenenak, instala daitezkeela dakar berekin:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Nola konfiguratu cookieak nabigatzailean",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Cookierik ez jasotzeko edo cookien finkapenaren berri izateko aukera daukazu, zure nabigatzailea ondore horretarako konfiguratzen baduzu; cookie guztiak, Flash-enak izan ezik, zure nabigatzailetik kudeatu daitezke, 'Aukerak' edo 'Lehentasunak' atalean.",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Cookieak guztiz desaktiba ditzakezu, era horretan, nabigatzaileak ez du bat ere instalatzen utziko (web orriko funtzionaltasun guztiak erabiltzea ezinezkoa eginez), edo soilik web jakin batzuetatik datozen cookieak desaktiba ditzakezu, eta hala, zure datuen pribatutasuna errespetatzen duten webguneetako funtzionaltasun aurreratuez gozatu ahal izango duzu, eta errespetatzen ez dutenak baztertu.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "Hurrengo esteka hauetan, euskarri-orrialde nagusietarako sarbidea ematen dizugu:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Halaber, Erabiltzaileak cookie jakin batzuen erabilerari uko egin diezaioke, honako zerbitzu hauen bidez:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Cookieak blokeatzea Chrome-n",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Cookieak blokeatzea Safari-n",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Cookieak blokeatzea Firefox-en",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Cookieak blokeatzea Internet Explorer-en",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "Zer gertatzen da cookieak desgaitzen badira?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Cookieak baztertzen badituzu, gure Webgunean nabigatzen jarraitu ahal izango duzu, baina zerbitzu batzuen erabilera mugatuta egon daiteke eta, beraz, baliteke zure esperientzia horren pozgarria ez izatea.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "Politika honetan deskribatutako cookieak baztertzeak, blokeatzeak edo ez baimentzeak webgunean erreproduzitzen diren edukietan zehar behar bezala ez nabigatzea eragin dezake.",
    cookiesPoliciesModalAcceptCookies: "Cookieen politika onartzea",
    cookiesPoliciesModalAcceptCookiesP1:
      "'ONARTU COOKIEAK' botoia sakatzen baduzu, cookien erabilera onartzen duzula adierazten duzu.",
    cookiesPoliciesModalAcceptCookiesP2:
      "Hala ere, saioa hasten den bakoitzean, atariko edozein orrialderen beheko aldean Cookieen politika erakutsiko da, jakinaren gainean egon zaitezen.",
    cookiesPoliciesModalUpgradeCookies:
      "Gure cookien politika eguneratzen al dugu?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "Baliteke, Cookieen Politika honetan emandako informazioak aldaketa batzuk jasatea, cookien tratamenduaren politikan izandako eguneraketak edo aldaketak direla eta, halaber, Datuen Babesaren Espainiako Agentziak politika aldaraziko luketen argibideak edota gomendioak eman ditzake.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Hori dela eta, Webgunean sartzen zaren bakoitzean politika hau berrikustea gomendatzen dizugu, cookieak nola eta zertarako erabiltzen ditugun behar bezala jakin dezazun.",
    cookiesPoliciesModalRecipientCookies:
      "Nortzuk dira informazioaren hartzaileak eta nola eragiten dio cookien erabilerak datu pertsonalen babesari?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Zure datuei buruzko informazio gehiago izateko, kontsultatu ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: "Datuak Babesteko Politika",
    cookiesPoliciesModalContactInfoCookies: "Harremanetarako informazioa",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Cookiei buruzko edozein zalantza, azalpen edota iradokizunetarako, jarri gurekin harremanetan posta elektroniko honen bitartez",
    cookiesPoliciesModalDatatableCookieType: "Cookie mota",
    cookiesPoliciesModalDatatableTreatmentTerm: "Tratamenduaren epea",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analitikoak",
    cookiesPoliciesModalDatatableTypeSelf: "Propioak",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Erabiltzaile batek Zerbitzu bat bisitatzen duen bakoitzean, kanpoko hornitzaile baten tresna batek Cookie analitiko bat sortzen du Erabiltzailearen ordenagailuan. Cookie honek, bisitan bakarrik sortzen denak, CCOMMSi hurrengo bisitetan bisitaria era anonimoan identifikatzeko balioko dio.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Erabiltzaile nabigatzaileen identifikazio anonimoa ahalbidetzea, 'Cookiearen' bitartez (nabigatzaileak eta gailuak identifikatzen ditu, ez pertsonak), eta beraz, bisitari-kopuruaren gutxi gorabeherako zenbaketa eta denboran zehar izandako joera. Bisita gehien dituzten eta Erabiltzaileentzat erakargarrienak diren edukiak modu anonimoan identifikatzea. Sartzen ari den Erabiltzailea berria den ala bisita errepikatzen ari den jakitea. Erabiltzaileak COOMMSen erregistratzea erabakitzen ez badu, 'Cookiea' inoiz ez da erabiltzailea zuzenean identifikatzea ahalbidetuko duten datu pertsonalekin lotuta egongo. Cookie horiek estatistika-helburuekin soilik erabiliko dira, Erabiltzaileen webguneko esperientzia optimizatzen eta hobetzen lagunduko dutenak.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Iraunkorrak",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Mota honetako Cookiek Erabiltzaile anonimo bakoitzari CCOMMSen Zerbitzuetan erakutsitako informazioa zabaltzea ahalbidetzen dute.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Besteak beste, atal ezberdinen bistaratzearen iraupena edo maiztasuna, horiekiko elkarrekintza, edo Erabiltzailearen nabigazio- edota portaera-ohiturak biltegiratzen dira.",
    cookiesPoliciesModalDatatableTypeSelfT: "Iraunkorrak",
    description: "Deskripzioa",
    privacyPoliciesConditionsFinalidadTitle: "Helburua",
    accept: "Onartu",
    reject: "Baztertu",
    save: "Gorde",
    cookiesPoliciesCertySign: "Ziurtagirien Egiaztapeneko Cookieak",
    cookiesNecessaryCookieDescription:
      "Cookie hau beharrezkoa da eta erabiltzailearen lehentasunak ezartzeko erabiltzen da, cookien politikari dagokionez.",
    cookiesGAMessage:
      "Barne-neurketa Google Analytics-ekin. Cookie hauek identifikadore bakar bat biltegiratzen dute, webgunea bisitatzen duen hurrengo aldietan erabiltzailea ezagutzeko.",
    cookiesGAFGenericMessage: "Barne-neurketa Google Analytics-ekin."
  },
  conditionsOfService: 'Zerbitzuaren baldintzak',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "itxi ikonoa",
    zoomTRID: "Zoom handitu",
    zoomCSV: "csv handitu",
    sampleCertificate: "Lagin-ziurtagiria",
    downloadButton: "deskargatu botoia",
    checkboxActiveUser: "Markatu laukia",
    selectLanguage: "Hautatu hizkuntza",
    openHelpDialog: "Ireki laguntza-elkarrizketa",
    trid: "Idatzi TRID",
    csv: "CSV idatzi",
    part1Container: "1 - Transakzio-identifikatzaile bakarraren (trid) posizio-adierazlea ereduaren ziurtagirian",
    part2Container: "2 - Ziurtagiriaren identifikatzaile bakarraren (csv) posizio-adierazlea ziurtagiri ereduan",
    sampleTrid: "Transakzio-identifikatzaile bakarra (trid) eredu-ziurtagirian",
    sampleCSV: "Ziurtagiriaren identifikatzaile bakarra (csv) eredu-ziurtagirian"
  }
};
