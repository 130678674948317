<template>
  <v-dialog
    max-width="900px"
    light
    v-model="areTermsVisible"
    close-delay="5"
    open-delay="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- open modal button -->
      <button role="button" class="button-footer" v-bind="attrs" v-on="on">
        <p
          id="verification-portal-footer-conditions-of-service"
          class="mb-0 text-xl-body-2 text-lg-body-2 text-md-body-2 text-sm-body-1 primaryGreen font-weight-medium"
        >
          {{ $t("conditionsOfService") }}
          <span
            class="body-2 primaryGreen d-xl-inline d-lg-inline d-md-inline d-sm-none d-none font-weight-medium ml-1 mr-2"
          >|</span>
        </p>
      </button>
      <!--  -->
    </template>

    <!-- content modal -->
    <v-card v-if="areTermsVisible">
      <v-card-title
        class="d-flex justify-space-between title-terms--contaier align-center"
      >
        <p id="verification-portal-footer-terms_and_conditions_title" class="primaryGreen subtitle-2 text-uppercase mb-0 pb-1">
          {{ $t("conditionsOfService") }}
        </p>

        <img
          id="verification-portal-footer-terms_and_conditions_close"
          src="../../../../../assets/icons/close.svg"
          :alt="$t('altAriaAttributes.closeIcon')"
          class="close-icon pointer"
          @click="areTermsVisible = false"
        />
      </v-card-title>
      <!-- text modal -->
      <v-card-text class="px-4 pb-0">
        <v-row dense class="ma-0">
          <v-col cols="12" md="6" class="my-4 px-2">
            <v-btn
              id="verification-portal-validate_btn"
              role="button"
              block
              color="primary"
              tabindex="3"
              aria-pressed="true"
              depressed
              class="button-footer"
              @click="openLink('mailteck')"
            >
                MailTecK
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="my-4 px-2">
            <v-btn
              id="verification-portal-validate_btn"
              role="button"
              block
              color="primary"
              tabindex="3"
              aria-pressed="true"
              depressed
              class="button-footer"
              @click="openLink('customercomms')"
            >
              Customer Comms
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--  -->
  </v-dialog>
</template>
<script>
export default {
  name: "conditionsOfService",
  data: function() {
    return {
      areTermsVisible: false
    };
  },
  methods: {
    openLink(subdomain) {
      let lang = this.$i18n.locale;
      let url = "";

      if (subdomain === "mailteck") {
        if (lang === "es") {
          url =
            "https://comunicaciones-legales.mailteck.com/pdf/Condiciones-generales-contratacion-Grupo-MailComms-mtk.pdf";
        } else {
          url =
            "https://comunicaciones-legales.mailteck.com/pdf/Condiciones-generales-contratacion-Grupo-MailComms-mtk_EN.pdf";
        }
      } else {
        if (lang === "es") {
          url =
            "https://comunicaciones-legales.customercomms.com/pdf/Condiciones-generales-contratacion-Grupo-MailComms.pdf";
        } else {
          url =
            "https://comunicaciones-legales.customercomms.com/pdf/Condiciones-generales-contratacion-Grupo-MailComms_EN.pdf";

        }
      }
      window.open(url, "_blank");
      this.areTermsVisible = false;
    }
  }
};
</script>
