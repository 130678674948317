import { mapState, mapMutations } from "vuex";
import EventBus from "../../plugins/helpers/eventBus";
import {
  S_VALIDATION_RESULT,
  M_UPDATE_VALIDATION_RESULT
} from "../../constants/store/index";

export default {
  name: "confirmation",
  data: function() {
    return {
      certificateWasDownloaded: false
    };
  },
  computed: {
    ...mapState({
      stateValidationResult: S_VALIDATION_RESULT
    })
  },
  methods: {
    ...mapMutations({
      mutUpdateValidationResult: M_UPDATE_VALIDATION_RESULT
    }),
    setLoader(timer) {
      EventBus.$emit("set:loader", true);
      setTimeout(() => {
        EventBus.$emit("set:loader", false);
      }, timer);
    },
    handleClickDownload() {
      const pdfLink = this.stateValidationResult.document_content;
      const linkSource = `data:application/pdf;base64,${pdfLink}`;
      const downloadLink = document.createElement("a");
      let fileName = "";
      if( this.stateValidationResult.filename != null ){
        fileName = this.stateValidationResult.filename;
      } else {
        fileName = this.stateValidationResult.csv + ".pdf";
      }
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.setLoader(800);
      this.certificateWasDownloaded = true;
      sessionStorage.setItem(
        "wasCertificateDownloaded",
        this.certificateWasDownloaded
      );
    },
    handleClickBackHome() {
      sessionStorage.clear();
      this.setLoader(1000);
      this.$router.push({ name: "home" });
    },
    setDocumentDownloadedState() {
      const wasCertificateDownloaded = sessionStorage.getItem(
        "wasCertificateDownloaded"
      );
      if (wasCertificateDownloaded) {
        this.certificateWasDownloaded = wasCertificateDownloaded;
      }
    },
    setResultData() {
      const stateValidationResultIsEmpty = !Object.keys(
        this.stateValidationResult
      ).length;

      if (stateValidationResultIsEmpty) {
        const sessionStorageValidationResult = JSON.parse(
          sessionStorage.getItem("resultData")
        );
        this.mutUpdateValidationResult(sessionStorageValidationResult);
      } else {
        sessionStorage.setItem(
          "resultData",
          JSON.stringify(this.stateValidationResult)
        );
      }
    }
  },

  mounted() {
    this.setDocumentDownloadedState();
    this.setResultData();
  }
};
