import es from "./es";
import en from "./en";
import eu from "./eu";
import ca from "./ca";
import gl from "./gl";
import pt from "./pt";
import nl from "./nl";
import it from "./it";
import de from "./de";
import fr from "./fr";

export default {
  es,
  en,
  eu,
  ca,
  gl,
  pt,
  nl,
  it,
  de,
  fr
};
