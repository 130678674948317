import EventBus from "../../../plugins/helpers/eventBus";
import helpDialog from "../../../components/helpDialog";
import { screenSizeMixin } from "@/store/screenSizeMixin";

export default {
  mixins: [screenSizeMixin],
  name: "headerComponent",
  data: function() {
    return {
      languageLocales: [],
      localeMap: [
        { "code": "es", name:"Español" },
        { "code": "en", name:"English" },
        { "code": "pt", name:"Portugues" },
        { "code": "eu", name:"Euskera" },
        { "code": "ca", name:"Català" },
        { "code": "gl", name:"Galego" },
        { "code": "nl", name:"Nederlands" },
        { "code": "it", name:"Italiano" },
        { "code": "de", name:"Deutsche" },
        { "code": "fr", name:"Française" },
      ]
    };
  },
  components: { helpDialog },
  methods: {
    initLanguageChange() {
      EventBus.$emit("set:loader", true);
    },
    finalizeLanguageChange() {
      window.scrollTo({
        top: 0,
        left: 0
      });
      window.location.reload();
    },
    redirectToHome() {
      if (this.$route.name !== "home") {
        //setLoader
        EventBus.$emit("set:loader", true);
        setTimeout(() => {
          EventBus.$emit("set:loader", false);
        }, 1000);

        this.$router.push({ name: "home" });
      }
    },
    handleChangeLocale(localeValue) {
      const localeTo = localeValue.target.value;
      const activeLocale = this.$i18n.locale;
      EventBus.$emit("change:locale", localeTo);

      if (localeTo !== activeLocale) {
        this.initLanguageChange();
        this.$router.replace({ params: { localeTo } });

        setTimeout(() => {
          this.finalizeLanguageChange();
          this.$i18n.locale = localeTo;
        }, 1000);
      }
    },
    setLanguageLocales() {
      const i18nLocales = this.$i18n.availableLocales;
      this.languageLocales = Object.values(i18nLocales);
    }
  },
  mounted() {
    this.setLanguageLocales();
  }
};
