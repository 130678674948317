import qs from "qs";
import { mapState, mapActions } from "vuex";
import { CONFIRMATION } from "@/constants/router/";
import {
  S_VALIDATION_RESULT,
  S_ERROR_API,
  A_GET_VALIDATION_RESULT
} from "../../constants/store/index";
import EventBus from "../../plugins/helpers/eventBus";
import helpDialog from "@/components/helpDialog";
import certificatePreview from "../../components/certificatePreview";
import { screenSizeMixin } from "@/store/screenSizeMixin";

export default {
  mixins: [screenSizeMixin],
  name: "home",
  components: {
    "help-dialog": helpDialog,
    "certificate-preview": certificatePreview
  },
  data: function() {
    return {
      errorRequiredCsv: null,
      errorRequiredTrid: null,
      tridId: "",
      csvId: "",
      errorTrid: null,
      errorCsv: null,
      certificateIsValid: null,
      isZoomTridVisible: false,
      isZoomCsvVisible: false,
      recaptchaCode: "",
      languageRecapcha: this.$route.params.lang,
      sitekey: process.env.VUE_APP_GOOGLE_KEY
    };
  },
  computed: {
    ...mapState({
      stateValidationResult: S_VALIDATION_RESULT,
      stateErrorApi: S_ERROR_API
    }),
    isBtnDisabled() {
      const tridIdIsEmpty = !this.tridId.length;
      const tridIdIsLowerThan4 = this.tridId.length < 4;
      const csvIdIsEmpty = !this.csvId.length;
      const csvIdIsLowerThan4 = this.csvId.length < 4;
      const recaptchaCodeiSEmpty = !this.recaptchaCode.length;
      return csvIdIsEmpty || tridIdIsLowerThan4 || tridIdIsEmpty || csvIdIsLowerThan4 || recaptchaCodeiSEmpty
    },
    errorIsDisplayed() {
      return this.stateErrorApi || this.certificateIsValid === false;
    }
  },
  methods: {
    ...mapActions({
      actionGetValidationResult: A_GET_VALIDATION_RESULT
    }),
    redirectToConfirmationRoute() {
      this.$router.push({
        name: CONFIRMATION.name
      });
    },
    errorMsg(value) {
      if (!value) {
        return this.$t("home.messagesInputs.required");
      } else if (value && value.length < 4) {
        return this.$t("home.messagesInputs.minimo");
      } else if (value && value.length > 1000) {
        return this.$t("home.messagesInputs.maximo");
      }
    },
    verifyReCapchaCode(response) {
      this.recaptchaCode = response;
    },
    async handleValidateHashId() {
      const dataObj = {
        trid: this.tridId,
        csv: this.csvId
      };
      const params = qs.stringify(dataObj);
      const recapchaIsValid = this.recaptchaCode.length > 0;

      await this.actionGetValidationResult(params);

      this.certificateIsValid = this.stateValidationResult.present;

      if (
        this.stateErrorApi === false &&
        this.certificateIsValid &&
        recapchaIsValid
      ) {
        this.redirectToConfirmationRoute();
      }
    },

    setRecapcha() {
      this.$nextTick(() => {
        var RecaptchaOptions = {
          sitekey: this.sitekey,
          hl: this.languageRecapcha,
          callback: this.verifyReCapchaCode,
          "expired-callback": this.handleExpireCapcha
        };

        window.grecaptcha.render("recapcha", RecaptchaOptions);
      });
    },
    removeRecapcha(lang) {
      this.$nextTick(() => {
        this.recaptchaCode = "";
        document.querySelector("#recapcha").remove();
        var RecaptchaOptions = {
          sitekey: this.sitekey,
          hl: lang,
          callback: this.verifyReCapchaCode,
          "expired-callback": this.handleExpireCapcha
        };
        const div = document.createElement("div");
        div.id = "recapcha";
        document.querySelector(".recapchaContainer").appendChild(div);

        window.grecaptcha.render("recapcha", RecaptchaOptions);
      });
    },
    clearValidations() {
      this.rulesTrid.required = null;
      this.rulesTrid.counter = null;
      this.rulesTrid.required = null;
      this.rulesTrid.counter = null;
    },
    handleBlurCsv() {
      this.validateInputCSV(this.csvId);
    },
    handleBlurTrid() {
      this.validateInputTrid(this.tridId);
    },
    validateInputCSV(inputValue) {
      if (!inputValue.length) {
        this.errorCsv = true;
        this.errorRequiredCsv = true;
      } else if (inputValue && inputValue.length < 4) {
        this.errorRequiredCsv = false;
        this.errorCsv = true;
      } else {
        this.errorCsv = false;
        this.errorRequiredCsv = false;
      }
    },
    validateInputTrid(inputValue) {
      if (!inputValue.length) {
        this.errorTrid = true;
        this.errorRequiredTrid = true;
      } else if (inputValue && inputValue.length < 4) {
        this.errorRequiredTrid = false;
        this.errorTrid = true;
      } else {
        this.errorTrid = false;
        this.errorRequiredTrid = false;
      }
    },
    handleExpireCapcha() {
      this.recaptchaCode = "";
    }
  },
  watch: {
    csvId(value) {
      this.validateInputCSV(value);
    },
    tridId(value) {
      this.validateInputTrid(value);
    }
  },
  mounted() {
    sessionStorage.clear();
    window.onresize = this.setZoomTridWidth;

    setTimeout(() => {
      this.setRecapcha();
    }, 100);

    EventBus.$on("change:locale", lang => {
      this.removeRecapcha(lang);
    });
  }
};
