import Vue from 'vue';

export const screenSizeMixin = {
  data() {
    return {
      screenSize: Vue.observable({
        isMobile: false,
        isTable: false,
        isDesktop: false
      })
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const width = window.innerWidth;
      this.screenSize.isMobile = width < 600;
      this.screenSize.isTable = width > 600 && width < 1200;
      this.screenSize.isDesktop = width > 1200;
    }
  }
};
