import vimage from "../../../components/imgResponsive";
import EventBus from "../../../plugins/helpers/eventBus";

export default {
  name: "logoContainer",
  components: {
    vimage
  },
  methods: {
    redirectToHome() {
      if (this.$route.name !== "home") {
        //setLoader
        EventBus.$emit("set:loader", true);
        setTimeout(() => {
          EventBus.$emit("set:loader", false);
        }, 1000);

        this.$router.push({ name: "home" });
      }
    }
  }
};
