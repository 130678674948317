import qs from "qs";
import axios from "axios";

export default {
  name: "helpDialog",
  props: {},
  data() {
    return {
      showDialog: false,
      supportMessage: "",
      isMessageSent: false,
      name: "",
      email: "",
      emailRules: [],
      nameRules: [],
      messageRules: []
    };
  },
  methods: {
    clearInputs() {
      this.name = "";
      this.email = "";
      this.supportMessage = "";
      this.messageRules = [];
      this.emailRules = [];
      this.nameRules = [];
    },
    setValidationName() {
      this.nameRules = [v => !!v || this.$t("helpDialog.requiredName")];
    },
    setValidationMessage() {
      this.messageRules = [v => !!v || this.$t("helpDialog.requiredMessage")];
    },
    setValidationEmail() {
      this.emailRules = [
        v => !!v || this.$t("helpDialog.requiredEmail"),
        v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("helpDialog.requiredEmailFormat")
      ];
    },
    sendSupportRequest() {
      let self = this;
      let dataObject = qs.stringify({
        name: `${this.name} [Portal de verificación certificados - ${this.$i18n.locale}]`,
        email: this.email,
        phone: "[no indicado]",
        company: "[no indicado]",
        message: this.supportMessage
      });

      axios
        .post(
          process.env.VUE_APP_ENDPOINT +
            "certificate_validation/contact_with_support",
          dataObject
        )
        .then(() => {
          self.isMessageSent = true;
          this.clearInputs();
        });
    }
  }
};
