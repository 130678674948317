export default {
  name: "imgResponsive",
  props: {
    classContainer: {
      type: String,
      required: false
    },
    srcDesktop: {
      type: String,
      required: false
    },
    altDesktop: {
      type: String,
      required: false
    },
    srcTablet: {
      type: String,
      required: false
    },
    altTablet: {
      type: String,
      required: false
    },
    srcMobile: {
      type: String,
      required: false
    },
    altMobile: {
      type: String,
      required: false
    }
  }
};
