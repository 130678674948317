import Vue from "vue";
import Vuex from "vuex";
import { apiPostCertificateValidation } from "../services/certificateValidation";
import {
  S_VALIDATION_RESULT,
  S_ERROR_API,
  M_UPDATE_ERROR_API,
  M_UPDATE_VALIDATION_RESULT,
  A_GET_VALIDATION_RESULT
} from "../constants/store/index";
import EventBus from "../plugins/helpers/eventBus";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    [S_VALIDATION_RESULT]: {},
    [S_ERROR_API]: null
  },
  mutations: {
    [M_UPDATE_VALIDATION_RESULT](state, payload) {
      state[S_VALIDATION_RESULT] = payload;
    },
    [M_UPDATE_ERROR_API](state, payload) {
      state[S_ERROR_API] = payload;
    }
  },
  actions: {
    [A_GET_VALIDATION_RESULT]({ commit }, payload) {
      EventBus.$emit("set:loader", true);
      return apiPostCertificateValidation(payload)
        .then(response => {
          commit(M_UPDATE_VALIDATION_RESULT, response.data.response);
          EventBus.$emit("set:loader", false);
          commit(M_UPDATE_ERROR_API, response.data.error);
        })
        .catch(() => {
          commit(M_UPDATE_ERROR_API, true);
        });
    }
  }
});
