export default {
  app: {
    header: {
      language: "Idioma"
    }
  },
  home: {
    title: "Verificación online de certificados",
    trid: "identificador único de transacción (trid)",
    csv: "identificador único de certificado (csv)",
    description:
      "Encha os seguintes datos para verificar a autenticidade da declaración de finalización ou certificado que obran no seu poder.",
    subtitle: "CÓDIGO DE VALIDACIÓN",
    validate: "Validar",
    resultError: "Revise os datos fornecidos",
    messagesInputs: {
      required: "Requirido",
      minimo: "Mínimo 4 caracteres",
      maximo: "Mínimo 1000 caracteres"
    }
  },
  confirmation: {
    title: "Os seus datos corresponden coa seguinte certificación",
    subtitle:
      "Pode descargar o certificado no botón inferior e comprobar que coincide coa información no seu poder.",
    download: "Descargar",
    back: "Volver",
    certificateDownloaded: "Descargouse correctamente o seu certificado"
  },
  helpDialog: {
    messageSent: {
      title: "Contactar con soporte",
      subtitle:
        "Enviouse a súa solicitude ao noso departamento de soporte; un axente poñerase en contacto con vostede o antes posible.",
      back: "Volver"
    },
    text: "Necesita axuda?",
    contactWithSupport: "Contactar con soporte",
    dialogPhrase:
      "Encha os datos e o equipo de soporte técnico responderá o antes posible. Horarios de atención, laborables:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Luns a Venres</strong>: 8:00 h-17:00 h</p>",
    message: "Mensaxe",
    sendRequest: "Enviar solicitude",
    requiredEmailFormat: "Formato de correo electrónico incorrecto",
    requiredEmail: "Correo electrónico requirido",
    requiredMessage: "Mensaxe requirida",
    requiredName: "Nome requirido",
    email: "Correo electrónico",
    name: "Nome"
  },
  termsAndConditions: {
    title: "Termos e condicións",
    termsConditions1:
      "CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en diante, CCOMMS), na súa condición de titular e responsable da presente APP, en cumprimento do establecido no artigo 10 da Lei 34/2002, de 11 de xullo, de Servizos da Sociedade da Información e de Comercio Electrónico, pon a disposición dos usuarios a seguinte información xeral sobre esta APP:",
    companyInfo:
      "<p class='mb-1'><strong>Titular</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>N.I.F</strong>: CB-86414000 </p><p class='mb-1'><strong>Domicilio social</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Datos rexistrais</strong>: Rexistro Mercantil de Madrid ao Tomo 29 801, Folio 98, Sección 8.ª, Folla número M-536210.</p><p class='mb-1'><strong>Contacto: Teléfono</strong>: 916 895 665</p><p class='mb-1'><strong>Email</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Correo electrónico do delegado de Protección de datos</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title=Delegado de Protección de datos de MailTeck>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- OBXECTO",
    termsConditionsObjetoParrafo1:
      "As presentes Condicións Xerais de Uso (en diante, as CXU) regulan o uso da aplicación de validación de certificados (en diante, a APP) que CCOMMS pon a disposición dos usuarios que accedan co gallo de poder satisfacer a solicitude realizada.",
    termsConditionsObjetoParrafo2:
      "Polo tanto, as presentes CXU aplicaranse tanto á actividade promocional e de subministración de información que se realiza a través da APP como ao fornecemento dos servizos ofrecidos, de tal xeito que rexerán en todo momento tanto para a simple navegación pola APP como para a contratación de servizos no marco da APP, se ben esta última actividade adicionalmente someterase ás condicións xerais de contratación aplicables e ás condicións particulares que poidan existir.",
    termsConditionsUsuariosTitulo: "II.- USUARIOS",
    termsConditionsUsuariosParrafo1:
      "O acceso á APP ou o uso dela outorgan a quen os realice a condición de usuario; o usuario acepta dende ese mesmo intre, plenamente e sen ningunha reserva, as presentes CXU, así como as condicións particulares que poidan complementar, modificar ou substituír as CXU en relación con determinados servizos e contidos da APP, obrigándose a respectar as restricións de uso da páxina establecidas por CCOMMS en cada momento.",
    termsConditionsAccesoAPPTitulo: "III.- ACCESO Á APP",
    termsConditionsAccesoAPPParrafo1:
      "O acceso á APP e o uso dela teñen carácter restrinxido e requirirán o previo rexistro do usuario.",
    termsConditionsUsoAPPTitulo: "IV.- USO DA APP",
    termsConditionsUsoAPPParrafo1:
      "O usuario comprométese a utilizar a APP e os seus servizos e contidos sen contravir a lexislación vixente, a boa fe, os usos xeralmente aceptados e a orde pública. Así mesmo, queda prohibido o uso da APP con fins ilícitos ou lesivos para CCOMMS ou calquera terceiro, ou que poidan de calquera xeito causar prexuízo ou impedir o correcto funcionamento da APP.",
    termsConditionsResposabilidadTitulo:
      "V-. RESPONSABILIDADE DO USUARIO POR DANOS E PREXUÍZOS",
    termsConditionsResposabilidadParrafo1:
      "O uso da APP farase baixo a única e exclusiva responsabilidade do usuario. Esta responsabilidade estenderase ao uso por parte do usuario ou dun terceiro de calquera contrasinal ou similar asignados para acceder á APP ou os seus servizos.",
    termsConditionsResposabilidadParrafo2:
      "Sen prexuízo do anterior, CCOMMS resérvase o dereito a denegar ou inhabilitar, en calquera momento e sen necesidade de aviso previo, o acceso á APP ou o acceso restrinxido para aqueles usuarios que incumpran estas CXU ou as normas establecidas por CCOMMS ou os seus colaboradores, ou que perturben o bo funcionamento, a imaxe, a credibilidade ou o prestixio de CCOMMS ou os seus colaboradores.",
    termsConditionsResposabilidadParrafo3:
      "Neste sentido, CCOMMS resérvase a facultade de cancelar ou restrinxir o acceso á APP para determinados usuarios en caso de observar calquera conduta que, ao seu xuízo, resulte contraria a estas condicións de uso, la lei ou as normas establecidas por CCOMMS ou os seus colaboradores, ou que perturbe o bo funcionamento, a imaxe, a credibilidade ou o prestixio de CCOMMS ou os seus colaboradores. Así mesmo, CCOMMS poderá esixir responsabilidade aos usuarios polos danos e prexuízos derivados dun mal uso da APP.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMS non garante a licitude, fiabilidade e utilidade, así como a veracidade ou exactitude, dos contidos que os usuarios transmitan durante a utilización da APP.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMS non controla o uso que os usuarios fagan da APP nin garante que este uso cumpra as presentes Condicións Xerais.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMS non controla nin garante que nos servizos fornecidos por terceiros a través da APP non existan virus informáticos que poidan producir alteracións no seu sistema informático (software e hardware) ou nos documentos e ficheiros electrónicos almacenados no seu sistema informático.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- PROPIEDADE INTELECTUAL",
    termsConditionsPropiedadIntelectualParrafo1:
      'Calquera uso ou modificación do material ou dos contidos para calquera fin distinto do autorizado nas Condicións Xerais consideraranse unha infracción das leis internacionais de "copyright", que protexen os dereitos de autor.',
    termsConditionsPropiedadIntelectualParrafo2:
      "Respecto aos contidos, prohíbese:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "A súa reprodución, copia, distribución, modificación ou manipulación, de calquera xeito e con calquera finalidade, a menos que se dispoña da autorización previa por escrito dos lexítimos titulares ou que estea permitido por lei.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Calquera vulneración dos dereitos de CCOMMS ou dos lexítimos titulares.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "A súa utilización para todo tipo de fins comerciais ou publicitarios distintos dos estritamente permitidos.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMS garante que os contidos, incluídos os de propiedade intelectual, non son de carácter ilícito nin infrinxen a normativa vixente. Polo tanto, os contidos da APP non terán carácter xenófobo, pornográfico, discriminatorio nin racista, nin fomentarán de calquera outro xeito a violencia.",
    termsConditionsPropiedadIntelectualParrafo4:
      "Ademais, CCOMMS adoptará as medidas legais que considere convenientes para impedir calquera tipo de conduta contraria á lei ou inmoral.",
    termsConditionsModificacionUnilateralTitulo:
      "VII.- MODIFICACIÓN UNILATERAL",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMS poderá modificar unilateralmente e sen previo aviso, sempre que o considere oportuno, a estrutura e o deseño da APP, así como cambiar ou eliminar os servizos, os contidos e as condicións de acceso ou uso da APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSIÓN DE GARANTÍAS E RESPONSABILIDADE",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMS non outorga ningunha garantía nin se fai responsable, en ningún caso, respecto aos danos e prexuízos de calquera tipo que puideran derivarse de:",
    termsConditionsExclusionGarantiasListaELemento1:
      "A non observación da lei, a moral e os bos costumes, así como a alteración da orde pública ou o uso dos produtos ou servizos ofertados de xeito pouco dilixente ou incorrecto, ou con fins ilícitos ou contrarios ao establecido nas presentes CXU ou nas condicións que sexan aplicables en cada caso.",
    termsConditionsExclusionGarantiasListaELemento2:
      "A adquisición ou contratación de servizos, bens ou produtos de terceiros a través dun acceso na APP de CCOMMS, especialmente naqueles casos en que o proceso de compra ou contratación se realice directamente na APP do terceiro, aínda que aparezan distintivos ou un «frame» cos elementos gráficos da APP (branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "A falta de dispoñibilidade, mantemento e funcionamento efectivo da APP ou os seus servizos ou contidos.",
    termsConditionsExclusionGarantiasListaELemento4:
      "A falta de utilidade, adecuación ou validez da APP ou dos seus servizos ou contidos para necesidades, actividades ou resultados concretos ou as expectativas dos usuarios.",
    termsConditionsExclusionGarantiasListaELemento5:
      "A existencia de virus ou programas maliciosos ou lesivos nos contidos.",
    termsConditionsExclusionGarantiasListaELemento6:
      "A recepción, obtención, almacenamento, difusión ou transmisión por parte dos usuarios dos contidos.",
    termsConditionsExclusionGarantiasListaELemento7:
      "A ilicitude ou falta de veracidade, exactitude, fiabilidade, pertinencia, actualidade e exhaustividade dos contidos e informacións transmitidos ou postos a disposición dos usuarios, incluíndo as informacións e servizos fornecidos por terceiros ou polos usuarios a través da APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "O incumprimento por parte de terceiros das súas obrigas ou compromisos en relación cos servizos fornecidos aos usuarios a través da APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "Os contidos, ficheiros, informacións, publicidade, opinións, conceptos e imaxes que non dependan da APP nin estean xestionados por CCOMMS. Así mesmo, o mal uso que se faga dos contidos da APP, cuxa exclusiva responsabilidade recae na persoa que acceda a eles ou os utilice.",
    termsConditionsExclusionGarantiasListaELemento10:
      "O uso indebido das claves de usuario para acceder aos contidos do servizo da APP que as requiran e as consecuencias de calquera tipo derivadas do mal uso ou da perda ou esquecemento por parte dos usuarios, e do uso indebido por parte de terceiros non autorizados.",
    termsConditionsExclusionGarantiasListaELemento11:
      "A incapacidade de calquera usuario ou a suplantación da personalidade dun terceiro por parte do usuario.",
    termsConditionsExclusionGarantiasListaELemento12:
      "Os contidos fornecidos polos usuarios.",
    termsConditionsProteccionDatosTitulo: "IX.-  PROTECCIÓN DE DATOS",
    termsConditionsProteccionDatosParrafo1:
      "Pode consultar a información detallada sobre protección de datos na nosa páxina de",
    termsConditionsLeyJurisdiccionTitulo: "X.- LEI APLICABLE E XURISDICIÓN",
    termsConditionsLeyJurisdiccionParrafo1:
      "Todas as condicións, xerais e particulares, aplicables á presente APP réxense pola normativa española. Todos os conflitos, controversias ou situacións derivadas das condicións xerais e particulares resolveranse nos xulgados e tribunais da Vila de Madrid, renunciando expresamente as partes ao seu foro."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  cookies: {
    cookiesPoliciesCompliance: "Política de rastros",
    cookiesPoliciesIntroText:
      "Este sitio web utiliza rastros para mellorar a experiencia de navegación por el. Pode cambiar estes axustes en calquera momento. Os rastros clasificados como necesarios almacénanse no navegador e son esenciais para as funcións básicas do sitio web. Os rastros de terceiros almacénanse no navegador só se dá o seu consentimento e axúdannos a analizar e comprender como se utiliza o sitio web. Pode optar por non recibir estes rastros; non obstante, a exclusión voluntaria dalgún destes rastros pode facer que algunhas funcións deixen de estar dispoñibles. Para obter información sobre a eliminación dos rastros, consulte a axuda do navegador.",
    cookiesPoliciesNecessaryCookies: "Rastros necesarios",
    cookiesPoliciesNecessaryCookiesText:
      "Este tipo de rastros son absolutamente esenciais para que o sitio web funcione correctamente. Esta categoría só inclúe rastros que garanten funcións básicas e características de seguridade do sitio web e non recompilan nin almacenan ningunha información persoal sobre os usuarios do sitio web.",
    cookiesPoliciesUnnecessaryCookies: "Rastros non necesarios",
    cookiesPoliciesUnnecessaryCookiesText:
      "Denomínanse rastros non necesarios aqueles que poden non ser especialmente necesarios para que o sitio web funcione e se utilizan especificamente para recompilar datos persoais do usuario a través de análises, anuncios e outros contidos integrados. É obrigatorio obter o consentimento do usuario antes de executar estes rastros no sitio web.",
    cookiesPoliciesGoogleAnalyticsCookies: "Rastros de Google Analytics",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Para obter máis información sobre este tipo de rastros, pode consultar o documento «Uso dos rastros de Google Analytics en sitios web»",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Manter rastros activos",
    cookiesNotificationBarText:
      "Utilizamos rastros, tanto propios como de terceiros, para computar as visitas, garantir as funcións do sitio web e analizar a navegación por este. A continuación, pode aceptar os rastros ou configurar/rexeitar o seu uso. Pode obter máis información sobre o titular da web, que é responsable do tratamento dos rastros e dos seus datos de contacto, na nosa",
    cookiesNotificationBarConfig: "Configurar",
    cookiesPoliciesModalIntro: "Introdución",
    cookiesPoliciesModalIntroP1:
      "Este sitio web utiliza rastros ou tecnoloxías similares que almacenan e recuperan información durante a navegación para mellorar e optimizar a experiencia do usuario.",
    cookiesPoliciesModalIntroP2:
      "En xeral, estas tecnoloxías poden servir para fins moi diversos, como, por exemplo, recoñecer os usuarios, obter información sobre hábitos de navegación ou personalizar o xeito en que se amosa o contido.",
    cookiesPoliciesModalIntroP3:
      "De conformidade co Real Decreto 13/2012 e a súa afectación no artigo 22.2 da Lei de Servizos da Sociedade da Información e Comercio Electrónico, mediante a presente política comunícaselle que rastros, propios ou de terceiros, exentos ou non do deber de notificación, recompila CUSTOMER COMMUNICATIONS TECKNALIA, S.L. a través dos distintos medios ou canles de comunicación, así como por que medios se poden rexeitar, permitir ou bloquear os devanditos rastros.",
    cookiesPoliciesModalIntroP4:
      "A continuación, fornécese información detallada sobre que son os rastros, que tipos se utilizan neste sitio web, como se poden desactivar nun navegador e como bloquear especificamente a instalación de rastros de terceiros.",
    cookiesPoliciesModalResponsibility:
      "Quen é o responsable do uso dos rastros no presente sitio web?",
    cookiesPoliciesModalResponsibilityP1:
      "O responsable do uso dos rastros é CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en diante, CCOMMS), con NIF número B-86414000 e con domicilio social en Leganés (Madrid), Avenida de la Recomba, 14, debidamente inscrita no Rexistro Mercantil de Madrid ao Tomo 29 801, Folio 98, Sección 8.ª, Folla número M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Correo electrónico do delegado de Protección de datos.",
    cookiesPoliciesModalWhatIsCookie: "Que é un rastro?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Un rastro é un dato, ficheiro ou dispositivo de almacenamento e recuperación de datos (DARD) que o navegador web almacena de xeito automático no dispositivo (smartphone, tableta ou televisor conectado) do usuario cando este accede a páxinas web e contas de redes sociais ou recibe un correo electrónico.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Os rastros permiten almacenar e recuperar de xeito automatizado datos sobre o uso que se fixo dos citados medios dixitais e sobre o equipo ou dispositivo dende o que se accede a eles.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Un rastro son só datos que se almacenan no ordenador do usuario, polo que non se trata de software nin de fragmentos de código, só de datos. Por tanto, en principio os rastros non poden transmitir nin executar virus, nin instalar malware como troianos ou programas de espionaxe.",
    cookiesPoliciesModalCookieTypes:
      "Que tipo de rastros hai na páxina web e a súa finalidade",
    cookiesPoliciesModalCookiesInstall:
      "Que rastros se poden instalar na web de verificación de certificados?",
    cookiesPoliciesModalCookiesInstallP1:
      "O acceso á web de verificación de certificados implica que se poidan instalar os rastros, propios ou de terceiros, que se detallan no seguinte cadro:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Como configurar os rastros no navegador",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Ten a opción de non recibir rastros ou de que lle comuniquen a fixación dos mesmos, o que deberá axustar na configuración do navegador; todos os rastros, excepto os de Flash, poden xestionarse dende o navegador, normalmente nos apartados «Opcións» ou «Preferencias».",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Pode desactivalos de todo, en cuxo caso o navegador non permitirá que se instale ningún (imposibilitando o uso de todas as funcións da páxina web), ou desactivar só os que procedan de determinadas webs, de xeito que poida gozar das funcións avanzadas das webs que respectan a privacidade dos datos, excluíndo só as que non o fagan.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "Mediante as seguintes ligazóns poderá acceder ás principais páxinas de soporte:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Así mesmo, o usuario pode opoñerse ao uso de determinados rastros mediante os seguintes servizos:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Bloqueo de rastros en Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Bloqueo de rastros en Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Bloqueo de rastros en Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Bloqueo de rastros en Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "Que ocorre se se inhabilitan os rastros?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Se rexeita os rastros, poderá seguir navegando polo sitio web, aínda que o uso dalgúns servizos podería quedar limitado e, por tanto, a súa experiencia podería resultar menos satisfactoria.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "O rexeitamento, bloqueo ou non aceptación dos rastros descritos na presente política poden afectar a correcta navegación polos contidos do sitio web.",
    cookiesPoliciesModalAcceptCookies: "Aceptación da política de rastros",
    cookiesPoliciesModalAcceptCookiesP1:
      "Premendo o botón «ACEPTAR RASTROS», indica que acepta o uso dos rastros.",
    cookiesPoliciesModalAcceptCookiesP2:
      "Non obstante, cada vez que inicie sesión, aparecerá a política de rastros na parte inferior das páxinas do portal co gallo de que sexa consciente dela.",
    cookiesPoliciesModalUpgradeCookies:
      "Actualizamos a nosa política de rastros?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "É posible que a información fornecida na presente política de rastros poida sufrir modificacións por mor de actualizacións ou cambios no tratamento dos rastros ou da publicación de novas instrucións ou recomendacións por parte da Axencia Española de Protección de Datos.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Por iso, recomendámoslle revisar esta política cada vez que acceda ao sitio web co gallo de estar ao día de como e para que usamos os rastros.",
    cookiesPoliciesModalRecipientCookies:
      "Quen son os destinatarios da información e como afecta o uso de rastros á protección dos datos persoais?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Para máis información sobre o tratamento dos seus datos, pode consultar a ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS:
      "Política de protección de datos",
    cookiesPoliciesModalContactInfoCookies: "Información de contacto",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Para calquera dúbida, aclaración ou suxestión sobre os rastros, debe contactar a través do correo electrónico ",
    cookiesPoliciesModalDatatableCookieType: "Tipo de rastro",
    cookiesPoliciesModalDatatableTreatmentTerm: "Prazo de tratamento",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analíticos",
    cookiesPoliciesModalDatatableTypeSelf: "Propios",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Cada vez que un usuario visita un servizo, unha ferramenta dun provedor externo xera un rastro analítico no seu ordenador. Este rastro, que só se xera na visita, utilizarao CCOMMS para, nas seguintes visitas, identificar de xeito anónimo o visitante.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Permitir a identificación anónima dos usuarios navegantes a través do rastro (identifica navegadores e dispositivos, pero non persoas) e, polo tanto, a contabilización aproximada do número de visitantes coas tendencias temporais. Identificar de xeito anónimo os contidos máis visitados e atractivos para os usuarios. Saber se o usuario que está accedendo é novo ou repite visita. Salvo que o usuario decida rexistrarse en CCOMMS, o rastro nunca irá asociado a ningún dato de carácter persoal que poida identificalo directamente. Os devanditos rastros só se utilizarán con fins estatísticos que axuden á optimización e mellora da experiencia dos usuarios no sitio.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistentes",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Este tipo de rastro permite ampliar a información que se amosa a cada usuario anónimo nos servizos de CCOMMS.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Almacénanse, entre outros datos, a duración ou frecuencia da visualización dos distintos apartados, a interacción cos mesmos ou os patróns de navegación ou os comportamentos do usuario.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistentes",
    description: "Descrición",
    privacyPoliciesConditionsFinalidadTitle: "Finalidade",
    accept: "Aceptar",
    reject: "Rexeitar",
    save: "Gardar",
    cookiesPoliciesCertySign: "Rastros na verificación de certificados",
    cookiesNecessaryCookieDescription:
      "Este rastro é necesario e utilízase para establecer a preferencia do usuario respecto á política de rastros.",
    cookiesGAMessage:
      "Medición interna con Google Analytics. Estes rastros almacenan un identificador único para recoñecer o usuario cando no futuro volva visitar o sitio web.",
    cookiesGAFGenericMessage: "Medición interna con Google Analytics."
  },
  conditionsOfService: 'Condicións do servizo',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "icona pechar",
    zoomTRID: "zoom trid",
    zoomCSV: "ampliar csv",
    sampleCertificate: "Certificado de mostra",
    downloadButton: "botón de descarga",
    checkboxActiveUser: "Caixa de verificación",
    selectLanguage: "Seleccionar idioma",
    openHelpDialog: "Abrir diálogo de soporte",
    trid: "Escribe TRID",
    csv: "Escribir CSV",
    part1Container: "1 - Indicador de posición do identificador único de transacción (trid) no modelo de certificado",
    part2Container: "2 - Indicador de posición do identificador único do certificado (csv) no modelo de certificado",
    sampleTrid: "Identificador de transacción único (trid) no certificado de mostra",
    sampleCSV: "Identificador único do certificado (csv) no certificado de mostra"
  }
};
