<template>
  <v-dialog v-model="areCookiesConfigureVisible" max-width="900px">
    <v-card v-if="areCookiesConfigureVisible" tile>
      <v-card-title
        class="d-flex justify-space-between title-terms--contaier align-center"
      >
        <p id="verification-portal-cookie_configure_dialog_title" class="primaryGreen subtitle-2 text-uppercase mb-0 pb-1">
          {{ $t("cookies.cookiesPoliciesCertySign") }}
        </p>
        <img
          id="verification-portal-cookie_configure_dialog_close"
          src="../../../../../assets/icons/close.svg"
          :alt="$t('altAriaAttributes.closeIcon')"
          class="close-icon pointer"
          @click="areCookiesConfigureVisible = false"
        />
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row dense class="ma-0">
          <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="pb-5">
            <h3>{{ $t("cookies.cookiesPoliciesCertySign") }}</h3>
            <br />
            <p>{{ $t("cookies.cookiesPoliciesIntroText") }}</p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <strong>{{
                          $t("cookies.cookiesPoliciesNecessaryCookies")
                        }}</strong>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>
                    {{ $t("cookies.cookiesPoliciesNecessaryCookiesText") }}
                  </p>
                  <v-data-table
                    dense
                    :headers="necessaryCookiesHeaders"
                    :items="necessaryCookiesData"
                    class="elevation-0 col-12 px-0 px-md-2"
                    sort-by="cookie"
                    calculate-widths
                    hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td
                          class="text-start pb-6 pl-0 pr-0 pt-1 title-configure"
                        >
                          <h4 class="cts-color-tertiary">
                            {{ item.cookie }}
                          </h4>
                        </td>
                        <td class="text-start">
                          <h4 class="cts-color-tertiary pb-6">
                            {{ item.description }}
                          </h4>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <strong>{{
                          $t("cookies.cookiesPoliciesUnnecessaryCookies")
                        }}</strong>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="pa-0">
                      <v-checkbox
                        class="cts-tabs-checkboxes cts-modal-checkbox pa-0 my-0 float-left"
                        v-model="unnecessaryCookies"
                        color="var(--primary)"
                        :aria-label="$t(altAriaAttributes.checkboxActiveUser)"
                      ></v-checkbox>
                      <h3
                        class="mt-0 cts-color-primary pa-0 cts-padding-small float-left"
                        style="line-height: 25px"
                      >
                        {{
                          $t(
                            "cookies.cookiesPoliciesGoogleAnalyticsCheckboxText"
                          )
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                  <p class="my-4">
                    {{ $t("cookies.cookiesPoliciesUnnecessaryCookiesText") }}
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <strong>{{
                          $t("cookies.cookiesPoliciesGoogleAnalyticsCookies")
                        }}</strong>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="pa-0">
                      <v-checkbox
                        class="cts-tabs-checkboxes cts-modal-checkbox pa-0 my-0 float-left"
                        v-model="googleAnalyticsCookies"
                        color="var(--primary)"
                        :aria-label="$t(altAriaAttributes.checkboxActiveUser)"
                      ></v-checkbox>
                      <h3
                        class="mt-0 cts-color-primary pa-0 cts-padding-small float-left"
                        style="line-height: 25px"
                      >
                        {{
                          $t(
                            "cookies.cookiesPoliciesGoogleAnalyticsCheckboxText"
                          )
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                  <p class="my-4">
                    {{
                      $t("cookies.cookiesPoliciesGoogleAnalyticsCookiesText")
                    }}
                  </p>
                  <v-data-table
                    dense
                    :headers="GACookiesHeaders"
                    :items="GACookiesData"
                    class="elevation-0 col-12 px-0 px-md-2"
                    sort-by="cookie"
                    calculate-widths
                    hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td
                          class="text-start pb-6 pl-0 pr-0 title-configure pt-1"
                        >
                          <h4 class="cts-color-tertiary pb-6">
                            {{ item.cookie }}
                          </h4>
                        </td>
                        <td class="text-start">
                          <h4 class="cts-color-tertiary pb-6">
                            {{ item.description }}
                          </h4>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row>
              <v-col cols="12">
                <v-btn
                  class="cts-button-standard my-2 float-right mt-6"
                  small
                  @click="
                    saveCookies(unnecessaryCookies, googleAnalyticsCookies)
                  "
                >
                  {{ $t("cookies.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/plugins/helpers/eventBus";
export default {
  name: "cookiesConfigure",
  data: function() {
    return {
      areCookiesConfigureVisible: false,
      unnecessaryCookies: true,
      googleAnalyticsCookies: true,
      necessaryCookiesData: [
        {
          cookie: "CScookies Policy Accepted",
          description: this.$t("cookies.cookiesNecessaryCookieDescription")
        }
      ],
      necessaryCookiesHeaders: [
        {
          text: "Cookie",
          align: "start",
          value: "cookie",
          width: "25%",
          sortable: false
        },
        {
          text: this.$t("cookies.description"),
          value: "description",
          align: "start",
          width: "75%",
          sortable: false
        }
      ],
      unnecessaryCookiesData: [],
      unnecessaryCookiesHeaders: [
        {
          text: "Cookie",
          align: "start",
          value: "cookie",
          width: "25%",
          sortable: false
        },
        {
          text: this.$t("cookies.description"),
          value: "description",
          align: "start",
          width: "75%",
          sortable: false
        }
      ],
      GACookiesData: [
        {
          cookie: "_ga",
          description: this.$t("cookies.cookiesGAMessage")
        },
        {
          cookie: "_ga_C8Z6WV9GMM",
          description: this.$t("cookies.cookiesGAFGenericMessage")
        },
        {
          cookie: "_ga_BT9F4YXY10",
          description: this.$t("cookies.cookiesGAFGenericMessage")
        },
        {
          cookie: "_ga_EJW1FTLN0K",
          description: this.$t("cookies.cookiesGAFGenericMessage")
        },
        {
          cookie: "_ga_LPMNR1YBN2",
          description: this.$t("cookies.cookiesGAFGenericMessage")
        },
        {
          cookie: "_ga_BQHZZLN57Y",
          description: this.$t("cookies.cookiesGAFGenericMessage")
        }
      ],
      GACookiesHeaders: [
        {
          text: "Cookie",
          align: "start",
          value: "cookie",
          width: "25%",
          sortable: false
        },
        {
          text: this.$t("cookies.description"),
          value: "description",
          align: "start",
          width: "75%",
          sortable: false
        }
      ]
    };
  },
  methods: {
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    delAnalyticsCookies() {
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (c.indexOf(name) == 0 || c.indexOf(name) == 1) {
          let cd = c.split("=");
          let cookieToDelete = cd[0].trim();
          if (cookieToDelete.includes("_ga")) {
            document.cookie =
              cookieToDelete +
              "=; Path=/; Domain=.certy-sign.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        }
      }
    },
    saveCookies(unnecessaryCookies, googleAnalyticsCookies) {
      this.unnecessaryCookies = { unnecessaryCookies, googleAnalyticsCookies };
      this.areCookiesConfigureVisible = false;
      EventBus.$emit("saveUnnecessary", this.unnecessaryCookies);
    }
  },
  mounted() {
    EventBus.$on("open:cookiesConfigure", () => {
      this.areCookiesConfigureVisible = true;
    });
  }
};
</script>
