<template>
  <v-dialog
    max-width="900px"
    light
    v-model="areTermsVisible"
    close-delay="5"
    open-delay="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- open modal button -->
      <button role="button" class="button-footer" v-bind="attrs" v-on="on">
        <p
          id="verification-portal-footer-terms_and_conditions"
          class="mb-0 text-xl-body-2 text-lg-body-2 text-md-body-2 text-sm-body-1 primaryGreen font-weight-medium"
        >
          {{ $t("termsAndConditions.title") }}
        </p>
      </button>
      <!--  -->
    </template>

    <!-- content modal -->
    <v-card v-if="areTermsVisible">
      <v-card-title
        class="d-flex justify-space-between title-terms--contaier align-center"
      >
        <p id="verification-portal-footer-terms_and_conditions_title" class="primaryGreen subtitle-2 text-uppercase mb-0 pb-1">
          {{ $t("termsAndConditions.title") }}
        </p>

        <img
          id="verification-portal-footer-terms_and_conditions_close"  
          src="../../../../../assets/icons/close.svg"
          :alt="$t('altAriaAttributes.closeIcon')"
          class="close-icon pointer"
          @click="areTermsVisible = false"
        />
      </v-card-title>
      <!-- text modal -->
      <v-card-text class="text-terms--container">
        <p class="body-2 mb-5 mt-4">
          {{ $t("termsAndConditions.termsConditions1") }}
        </p>
        <p class="body-2" v-html="$t('termsAndConditions.companyInfo')"></p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsObjetoTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsObjetoParrafo1") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsObjetoParrafo2") }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsUsuariosTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsUsuariosParrafo1") }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsAccesoAPPTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsAccesoAPPParrafo1") }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsUsoAPPTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsUsoAPPParrafo1") }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsResposabilidadTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsResposabilidadParrafo1") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsResposabilidadParrafo2") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsResposabilidadParrafo3") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsResposabilidadParrafo4") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsResposabilidadParrafo5") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsResposabilidadParrafo6") }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{
            $t("termsAndConditions.termsConditionsPropiedadIntelectualTitulo")
          }}
        </p>
        <p class="body-2 mb-5">
          {{
            $t("termsAndConditions.termsConditionsPropiedadIntelectualParrafo1")
          }}
        </p>
        <p class="body-2 mb-5">
          {{
            $t("termsAndConditions.termsConditionsPropiedadIntelectualParrafo2")
          }}
        </p>
        <ul class="mb-5 list-style-terms">
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsPropiedadIntelectualListaELemento1"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsPropiedadIntelectualListaELemento2"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsPropiedadIntelectualListaELemento3"
              )
            }}
          </li>
        </ul>
        <p class="body-2 mb-5">
          {{
            $t("termsAndConditions.termsConditionsPropiedadIntelectualParrafo3")
          }}
        </p>
        <p class="body-2 mb-5">
          {{
            $t("termsAndConditions.termsConditionsPropiedadIntelectualParrafo4")
          }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{
            $t("termsAndConditions.termsConditionsModificacionUnilateralTitulo")
          }}
        </p>
        <p class="body-2 mb-5">
          {{
            $t(
              "termsAndConditions.termsConditionsModificacionUnilateralParrafo1"
            )
          }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsExclusionGarantiasTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{
            $t(
              "termsAndConditions.termsConditionsExclusionGarantiasListaELemento1"
            )
          }}
        </p>
        <ul class="list-style-terms">
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento2"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento3"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento4"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento5"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento6"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento7"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento8"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento9"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento10"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento11"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento12"
              )
            }}
          </li>
          <li class="body-2">
            {{
              $t(
                "termsAndConditions.termsConditionsExclusionGarantiasListaELemento1"
              )
            }}
          </li>
        </ul>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsProteccionDatosTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{
            $t(
              "termsAndConditions.termsConditionsModificacionUnilateralParrafo1"
            )
          }}
        </p>
        <p class="body-2 mb-7 mt-7 font-weight-medium">
          {{ $t("termsAndConditions.termsConditionsLeyJurisdiccionTitulo") }}
        </p>
        <p class="body-2 mb-5">
          {{ $t("termsAndConditions.termsConditionsLeyJurisdiccionParrafo1") }}
        </p>
      </v-card-text>
    </v-card>
    <!--  -->
  </v-dialog>
</template>
<script>
export default {
  name: "termsAndConditions",
  data: function() {
    return {
      areTermsVisible: false
    };
  }
};
</script>
