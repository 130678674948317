import appHeader from "./sections/header/";
import appLogoContainer from "./sections/logoContainer";
import appFooter from "./sections/footer";
import EventBus from "../plugins/helpers/eventBus";
import { screenSizeMixin } from "@/store/screenSizeMixin";

export default {
  mixins: [screenSizeMixin],
  name: "App",
  data: function() {
    return {
      isLoaderVisible: false
    };
  },
  components: {
    appHeader,
    appLogoContainer,
    appFooter
  },
  methods: {
    setLoader(param) {
      this.isLoaderVisible = param;
    },
    initializeI18n() {
      const preferedLocale = process.env.VUE_APP_I18N_LOCALE;
      const routerLocale = this.$route.params.lang;
      if (!routerLocale) this.$i18n.locale = preferedLocale;
      else this.$i18n.locale = routerLocale;
    }
  },
  mounted() {
    EventBus.$on("set:loader", param => {
      this.isLoaderVisible = param;
    });
  },
  created() {
    this.initializeI18n();
    this.setLoader(true);
    setTimeout(() => {
      this.setLoader(false);
    }, 1200);

    document.title = this.$t("home.title")
    document.documentElement.lang = this.$route.params.lang
  }
};
