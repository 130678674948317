import Vue from "vue";
import App from "./App/";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

let google_id = process.env.VUE_APP_GOOGLE_KEY_ANALYTICS;
let gtag = Vue.use(VueGtag, {
  config: { id: google_id }
});

Vue.prototype.gtag = gtag;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
