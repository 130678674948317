import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#007681",
        secondary: "#7EDDD3",
        error: "#c43b3b"
      }
    }
  }
});
