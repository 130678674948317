<template>
  <v-dialog
    max-width="900px"
    max-height="70%"
    light
    v-model="isPrivacyOpen"
    close-delay="5"
    open-delay="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- open modal button -->
      <button
        role="button"
        class="button-footer pointer"
        v-bind="attrs"
        v-on="on"
      >
        <p
          class="mb-0 text-xl-body-2 text-lg-body-2 text-md-body-2 primaryGreen font-weight-medium"
          id="verification-portal-footer-privacy_policy"
        >
          <span
            class="body-2 primaryGreen d-xl-inline d-lg-inline d-md-inline d-sm-none d-none font-weight-medium ml-1"
          >|</span
          >
          {{ $t("privacyPolicy.privacyPolicieModalLargeTitle") }}
        </p>
      </button>
      <!--  -->
    </template>

    <!-- content modal -->
    <v-card v-if="isPrivacyOpen">
      <v-card-title
        class="d-flex justify-space-between title-terms--contaier align-center"
      >
        <p id="verification-portal-footer-privacy_policy_dialog_title" class="primaryGreen subtitle-2 text-uppercase mb-0 pb-1">
          {{ $t("privacyPolicy.privacyPolicieModalTitle") }}
        </p>

        <img
          id="verification-portal-footer-privacy_policy_dialog_close"
          src="../../../../../assets/icons/close.svg"
          :alt="$t('altAriaAttributes.closeIcon')"
          class="close-icon pointer"
          @click="isPrivacyOpen = false"
        />
      </v-card-title>
      <!-- text modal -->
      <v-card-text>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="pa-0 pt-5">
          <br />
          <p>
            {{ $t("privacyPolicy.privacyPoliciesConditionsParrafo1") }}
          </p>
          <v-expansion-panels class="pt-4">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong class="wordBreak">{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsResponsable1Title"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="9" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>Mailteck, S.A. </span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsIdentidadTitle")
                  }}:</strong
                >
                Mailteck, S.A.<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsNIFTitle")
                  }}:</strong
                >
                A-31501901<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsDireccionTitle")
                  }}:</strong
                >
                Avenida de la Recomba nº 12-14 - 28914 Leganés (Madrid)<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsTelefonoTitle")
                  }}:</strong
                >
                91 304 49 41<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsCorreoTitle")
                  }}:</strong
                >
                <a
                  class="text-decoration-none"
                  href="mailto:info@mailteck.com"
                  title="Delegado de Protección de Datos de Mailteck"
                >
                  info@mailteck.com</a
                ><br />
                <strong>
                  {{ $t("privacyPolicy.privacyPoliciesConditionsDPDTitle") }}:
                </strong>
                <a
                  class="text-decoration-none"
                  href="mailto:dpo@mailteck.com"
                  title="Delegado de Protección de Datos de Mailteck"
                >
                  dpo@mailteck.com</a
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong>{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsResponsable2Title"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>Customer Communications Tecknalia, S.L. </span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsIdentidadTitle")
                  }}:</strong
                >
                Customer Communications Tecknalia, S.L.<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsNIFTitle")
                  }}:</strong
                >
                B-86414000<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsDireccionTitle")
                  }}:</strong
                >
                Avenida de la Recomba nº 12-14 - 28914 Leganés (Madrid)<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsTelefonoTitle")
                  }}:</strong
                >
                91 689 56 65<br />
                <strong>
                  {{
                    $t("privacyPolicy.privacyPoliciesConditionsCorreoTitle")
                  }}:
                </strong>
                <a
                  href="mailto:info@customercomms.com"
                  class="text-decoration-none"
                  title="Email Customer Comms"
                  >info@customercomms.com</a
                ><br />
                <strong>
                  {{ $t("privacyPolicy.privacyPoliciesConditionsDPDTitle") }}:
                </strong>
                <a
                  class="text-decoration-none"
                  href="mailto:dpo@customercomms.com"
                  title="Delegado de Protección de Datos de Customer Comms"
                  >dpo@customercomms.com</a
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong>{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsFinalidadTitle"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>{{
                            $t(
                              "privacyPolicy.privacyPoliciesConditionsFinalidadIntro"
                            )
                          }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{
                  $t(
                    "privacyPolicy.privacyPoliciesConditionsFinalidadParrafo1"
                  )
                }}<br /><br />
                {{
                  $t("privacyPolicy.privacyPoliciesConditionsFinalidadParrafo2")
                }}<br /><br />
                {{
                  $t("privacyPolicy.privacyPoliciesConditionsFinalidadParrafo3")
                }}<br /><br />
                {{
                  $t("privacyPolicy.privacyPoliciesConditionsFinalidadParrafo4")
                }}
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong>{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsLegitimacionTitle"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>{{
                            $t(
                              "privacyPolicy.privacyPoliciesConditionsLegitimacionIntro"
                            )
                          }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{
                  $t(
                    "privacyPolicy.privacyPoliciesConditionsLegitimacionParrafo1"
                  )
                }}
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong>{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsDestinatariosTitle"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>{{
                            $t(
                              "privacyPolicy.privacyPoliciesConditionsDestinatariosIntro"
                            )
                          }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p
                  v-html="$t(
                        'privacyPolicy.privacyPoliciesConditionsDestinatariosParrafo1'
                      )"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3" class="mb-1">
                      <strong>{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsDerechosTitle"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>{{
                            $t(
                              "privacyPolicy.privacyPoliciesConditionsDerechosIntro"
                            )
                          }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem1ListaTitulo"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem1ListaDescripcion"
                      )
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem2ListaTitulo"
                      )
                    }}</strong>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem2ListaDescripcion"
                      )
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem3ListaTitulo"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem3ListaDescripcion"
                      )
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem4ListaTitulo"
                      )
                    }}</strong>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem4ListaDescripcion"
                      )
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem5ListaTitulo"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem5ListaDescripcion"
                      )
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem6ListaTitulo"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem6ListaDescripcion"
                      )
                    }}
                    (<a href="http://www.aepd.es" rel="noopener" title="AEPD"
                      >www.aepd.es</a
                    >).
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem7ListaTitulo"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsDerechosElem7ListaDescripcion"
                      )
                    }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong>{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsInformacionAdicionalTitle"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span>{{
                            $t(
                              "privacyPolicy.privacyPoliciesConditionsInformacionAdicionalIntro"
                            )
                          }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsInformacionAdicionalLista1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsInformacionAdicionalLista2"
                      )
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsInformacionAdicionalLista3"
                      )
                        }}</strong>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template>
                  <v-row no-gutters>
                    <v-col cols="6" sm="3">
                      <strong style="line-height: 1.2em;">{{
                        $t(
                          "privacyPolicy.privacyPoliciesConditionsPoliticaInformacion1Title"
                        )
                      }}</strong>
                    </v-col>
                    <v-col cols="6" sm="8" class="text--secondary pl-2">
                      <v-fade-transition leave-absolute>
                        <span key="0">
                          <span style="line-height: 1.2em;">{{
                            $t(
                              "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionIntro"
                            )
                          }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  <span>
                  {{
                          $t(
                            "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionDate"
                          )
                      }}</span>
                <h3 class="my-2">
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionTituloH"
                    )
                  }}
                </h3>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP1"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP2"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP3"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP4"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP5"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP6"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP7"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP8"
                    )
                  }}
                </p>
                <p>
                  {{
                    $t(
                      "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP9"
                    )
                  }}
                </p>
                <ul style="    list-style-type: disclosure-closed;">
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista2"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista3"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista4"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista5"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista6"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionLista7"
                      )
                    }}
                  </li>
                </ul>

                  <p>
                      {{
                          $t(
                            "privacyPolicy.privacyPoliciesConditionsPoliticaInformacionP10"
                          )
                      }}
                  </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-card-text>
    </v-card>
    <!--  -->
  </v-dialog>
</template>
<script>
import EventBus from "@/plugins/helpers/eventBus";

export default {
  name: "privacyPolicy",
  data: function() {
    return {
      isPrivacyOpen: false
    };
  },
  mounted() {
    EventBus.$on("open:privacyPolicy", () => {
      this.isPrivacyOpen = true;
    });
  }
};
</script>
