export default {
  app: {
    header: {
      language: 'Langue'
    }
  },
  home: {
    title: 'Vérification en ligne de certificats',
    trid: 'identifiant unique de transaction (trid)',
    csv: 'identifiant unique de certificat (csv)',
    description: 'Complétez les données suivantes pour vérifier l’authenticité de la déclaration de finalisation ou du certificat en votre possession.',
    subtitle: 'CODE DE VALIDATION',
    validate: 'Valider',
    resultError: 'Veuillez vérifier les données saisies',
    messagesInputs: {
      required: 'Requis',
      minimo: 'Minimum 4 caractères',
      maximo: 'Minimum 1 000 caractères'
    }
  },
  confirmation: {
    title: 'Vos données correspondent à la certification suivante',
    subtitle: 'Vous pouvez télécharger le certificat en cliquant sur le bouton ci-dessous et vérifier qu’il coïncide avec les informations en votre possession.',
    download: 'Télécharger',
    back: 'Retour',
    certificateDownloaded: 'Votre certificat a été correctement téléchargé'
  },
  helpDialog: {
    messageSent: {
      title: 'Contacter l’assistance',
      subtitle: 'Votre demande a été envoyée à notre département d’assistance, un agent vous contactera dans les meilleurs délais.',
      back: 'Retour'
    },
    text: 'Besoin d’aide ?',
    contactWithSupport: 'Contacter l’assistance',
    dialogPhrase: 'Remplissez les données et l’équipe d’assistance technique vous répondra dans les meilleurs délais. Horaires d’ouverture, les jours ouvrables :',
    monFriHours: '<p class="body-2 font-weight-medium mb-7"><strong>Du lundi au vendredi</strong> : 8 h - 17 h</p>',
    message: 'Message',
    sendRequest: 'Envoyer demande',
    requiredEmailFormat: 'Format d’e-mail incorrect',
    requiredEmail: 'E-mail requis',
    requiredMessage: 'Message requis',
    requiredName: 'Prénom requis',
    email: 'E-mail',
    name: 'Prénom'
  },
  termsAndConditions: {
    title: 'Termes et conditions',
    termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (ci-après CCOMMS), en sa condition de titulaire et responsable de la présente application, conformément à l’article 10 de la loi 34/2002 du 11 juillet 2002 sur les services de la société de l’information et le commerce électronique, met à disposition des utilisateurs les informations suivantes à propos de cette application :',
    companyInfo: '<p class="mb-1"><strong>Titulaire</strong> : Customer Communications Tecknalia, S.L.</p><p class="mb-1"><strong>N.I.F</strong> : CB-86414000 </p><p class="mb-1"><strong>Siège social</strong> :  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class="mb-1"><strong>Inscription au registre</strong> : Registre du commerce de Madrid au tome 29 801, folio 98, 8e section, feuille nº M-536210.</p><p class="mb-1"><strong>Contact : Téléphone</strong> : +34 916 895 665</p><p class="mb-1"><strong>E-mail</strong> : <a  class="text-decoration-none" href="mailto:info@customercomms.com" title="v">info@customercomms.com</a></p><p class="mb-1"><strong>Correo electrónico del Delegado de Protección de Datos</strong>: <a href="mailto:dpo@mailteck.com" class="text-decoration-none" title="Delegado de Protección de Datos de MailTecK">dpo@mailteck.com</a></p>',
    termsConditionsObjetoTitulo: 'I.- OBJET',
    termsConditionsObjetoParrafo1: 'Les présentes Conditions générales d’utilisation (ci-après les ‘CGU’) réglementent l’utilisation de l’application Validation de certificat (ci-après ‘l’application’) que CCOMMS met à disposition des utilisateurs qui y accèdent pour pouvoir effectuer la demande réalisée.',
    termsConditionsObjetoParrafo2: 'Les présentes CGU s’appliqueront donc à l’activité promotionnelle et d’informations effectuée par l’intermédiaire de l’application, comme la prestation des services proposés sur l’application, de telle sorte qu’elles devront être respectées à tout moment, que ce soit pour naviguer simplement sur l’application ou pour souscrire des services dans le cadre de l’application, même si ces dernières activités seront également soumises aux Conditions générales de souscription applicables, et aux Conditions particulières éventuelles.',
    termsConditionsUsuariosTitulo: 'II.- UTILISATEURS',
    termsConditionsUsuariosParrafo1: 'La personne accédant à et/ou utilisant l’application acquiert la condition d’utilisateur et dès lors, accepte pleinement et sans aucune réserve les présentes CGU, ainsi que les Conditions particulières qui, le cas échéant, complètent, modifient ou remplacent les CGU concernant certains services et contenus de l’application, en s’obligeant à respecter les restrictions d’utilisation de cette page fixées par CCOMMS à chaque instant.',
    termsConditionsAccesoAPPTitulo: 'III.- ACCÈS À L’APPLICATION',
    termsConditionsAccesoAPPParrafo1: 'L’accès et l’utilisation de l’application ont un caractère restreint ; pour pouvoir y accéder, l’utilisateur devra au préalable s’être inscrit.',
    termsConditionsUsoAPPTitulo: 'IV.- UTILISATION DE L’APPLICATION',
    termsConditionsUsoAPPParrafo1: 'L’utilisateur s’engage à utiliser l’application, ses services et contenus sans enfreindre la législation en vigueur, la bonne foi, les usages communément acceptés et l’ordre public. De même, il est interdit d’utiliser l’application à des fins illégales ou nuisibles contre CCOMMS ou tout autre tiers, ou, de n’importe quelle manière, de causer préjudice ou empêcher le fonctionnement normal de l’application.',
    termsConditionsResposabilidadTitulo: 'V-. RESPONSABILITÉ DE L’UTILISATEUR POUR DOMMAGES ET INTÉRÊTS',
    termsConditionsResposabilidadParrafo1: 'L’utilisation de l’application se fera sous la responsabilité unique et exclusive de l’utilisateur. Cette responsabilité s’étendra à l’utilisation, par l’utilisateur ou n’importe quel tiers, de tout mot de passe ou similaire attribué pour accéder à l’application ou à l’un de ses services.',
    termsConditionsResposabilidadParrafo2: 'Sans préjudice de ce qui précède, CCOMMS se réserve le droit de refuser ou d’inactiver à tout moment et sans avis préalable l’accès à l’application ou à l’accès restreint, aux utilisateurs qui ne respectent pas ces CGU, les normes fixées par CCOMMS ou leurs collaborateurs, ou qui pourraient perturber le bon fonctionnement, l’image, la crédibilité et/ou le prestige de CCOMMS ou de ses collaborateurs.',
    termsConditionsResposabilidadParrafo3: 'En ce sens, CCOMMS se réserve la faculté d’annuler ou de restreindre l’accès à l’application à certains utilisateurs, en cas d’observation d’une conduite jugée par CCOMMS comme étant contraire à ces Conditions d’utilisation, à la loi, aux normes établies par CCOMMS ou ses collaborateurs ou susceptible de perturber le bon fonctionnement, l’image, la crédibilité et/ou le prestige de CCOMMS ou ses collaborateurs. De même, CCOMMS pourra exiger leur responsabilité aux utilisateurs pour les dommages et intérêts dérivés d’une mauvaise utilisation de l’application.',
    termsConditionsResposabilidadParrafo4: 'CCOMMS ne garantit pas la légalité, la fiabilité et l’utilité, ainsi que la véracité ou l’exactitude, des contenus transmis par les utilisateurs pendant l’utilisation de l’application.',
    termsConditionsResposabilidadParrafo5: 'CCOMMS ne contrôle pas l’utilisation faite par les utilisateurs de l’application, ni ne garantit qu’ils le font conformément aux présentes Conditions générales.',
    termsConditionsResposabilidadParrafo6: 'CCOMMS ne contrôle ni ne garantit l’absence de virus informatique dans les services fournis par des tiers par l’intermédiaire de l’application susceptibles de produire des altérations dans leur système informatique (software et hardware) ou sur les documents et fichiers électroniques stockés dans leur système informatique.',
    termsConditionsPropiedadIntelectualTitulo: 'VI.- PROPRIÉTÉ INTELLECTUELLE',
    termsConditionsPropiedadIntelectualParrafo1: 'Toute utilisation ou modification du matériel ou des contenus pour toute fin autre que celle autorisée dans les conditions générales sera considérée comme une violation des lois internationales du copyright qui protègent les droits d’auteur.',
    termsConditionsPropiedadIntelectualParrafo2: 'Concernant les contenus, sont interdits :',
    termsConditionsPropiedadIntelectualListaELemento1: 'Leur reproduction, copie, distribution, modification ou manipulation, de n’importe quelle manière et dans n’importe quel but, sans autorisation préalable écrite de leurs titulaires légitimes ou à moins d’y être autorisé par la loi.',
    termsConditionsPropiedadIntelectualListaELemento2: 'Toute violation des droits de CCOMMS ou de leurs titulaires légitimes sur ces contenus.',
    termsConditionsPropiedadIntelectualListaELemento3: 'Leur utilisation pour toute sorte de fins commerciales ou publicitaires, autres que celles strictement autorisées.',
    termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garantit que les contenus, y compris ceux de propriété intellectuelle, n’ont pas un caractère illégal et n’enfreignent pas la réglementation en vigueur. Par conséquent, les contenus de l’application n’auront pas un caractère xénophobe, pornographique, discriminatoire, raciste ou qui encourage la violence de quelque manière que ce soit.',
    termsConditionsPropiedadIntelectualParrafo4: 'De plus, CCOMMS prendra les mesures légales jugées opportunes par la compagnie pour empêcher tout type de conduite contraire à la loi ou immorale.',
    termsConditionsModificacionUnilateralTitulo: 'VII.- MODIFICATION UNILATÉRALE',
    termsConditionsModificacionUnilateralParrafo1: 'CCOMMS pourra modifier de manière unilatérale et sans préavis, à condition de le juger opportun, la structure et l’aspect de l’application, ainsi que modifier ou supprimer les services, contenus et conditions d’accès et/ou d’utilisation de l’application.',
    termsConditionsExclusionGarantiasTitulo: 'VIII.- EXCLUSION DE GARANTIES ET RESPONSABILITÉ',
    termsConditionsExclusionGarantiasParrafo1: 'CCOMMS ne donne aucune garantie ni ne se porte responsable, en aucun cas, des dommages et intérêts de toute nature susceptibles d’entraîner :',
    termsConditionsExclusionGarantiasListaELemento1: 'Le manquement à la loi, à la morale et aux bonnes mœurs, ainsi qu’à l’ordre public, ou l’utilisation des produits et/ou services offerts de manière non consciencieuse ou incorrecte, ou à des fins ou effets illégaux ou contraires à ceux établis dans les présentes CGU ou dans les conditions éventuellement applicables.',
    termsConditionsExclusionGarantiasListaELemento2: 'En achetant ou en souscrivant des services, des biens ou des produits pouvant être acquis ou souscrits à des tiers par le biais d’un accès à l’application de CCOMMS, particulièrement dans les cas où le processus d’achat ou de souscription se fait directement sur l’application du tiers et même si des distinctifs ou un ‘frame’ avec les éléments graphiques de l’application (Branding) sont affichés.',
    termsConditionsExclusionGarantiasListaELemento3: 'Le manque de disponibilité, de maintenance et de fonctionnement efficace de l’application et/ou de ses services ou contenus.',
    termsConditionsExclusionGarantiasListaELemento4: 'Le manque d’utilité, adéquation ou validité de l’application et/ou de ses services ou contenus pour répondre à des besoins, activités ou résultats concrets ou attentes des utilisateurs.',
    termsConditionsExclusionGarantiasListaELemento5: 'L’existence de virus, programmes malicieux ou nuisibles dans les contenus.',
    termsConditionsExclusionGarantiasListaELemento6: 'La réception, l’obtention, le stockage, la diffusion ou la transmission des contenus par les utilisateurs.',
    termsConditionsExclusionGarantiasListaELemento7: 'L’illégalité ou le défaut de véracité, d’exactitude, de fiabilité, de pertinence, d’actualité et d’exhaustivité des contenus et informations transmis ou mis à disposition des utilisateurs, y compris les informations et services fournis par des tiers ou par les utilisateurs par l’intermédiaire de l’application.',
    termsConditionsExclusionGarantiasListaELemento8: 'Le non-respect par des tiers de leurs obligations ou engagements en lien avec les services fournis aux utilisateurs par le biais de l’application.',
    termsConditionsExclusionGarantiasListaELemento9: 'Des contenus, fichiers, informations, publicités, opinions, concepts et images qui ne dépendent pas de l’application et qui ne sont pas gérés par CCOMMS. L’entreprise n’est pas responsable non plus de la mauvaise utilisation qui serait faite des contenus de l’application, qui relèvera exclusivement de la responsabilité de la personne qui y accède ou qui les utilise.',
    termsConditionsExclusionGarantiasListaELemento10: 'De l’utilisation indue des mots de passe des utilisateurs pour accéder aux contenus du service de l’application qui les requièrent et des conséquences dérivées de toute nature de leur mauvaise utilisation par les utilisateurs, de leur perte ou de leur oubli, et de leur utilisation indue par des tiers non autorisés.',
    termsConditionsExclusionGarantiasListaELemento11: 'L’incapacité de tout utilisateur ou l’usurpation de la personnalité d’un tiers effectuée par l’utilisateur.',
    termsConditionsExclusionGarantiasListaELemento12: 'Des contenus fournis par les utilisateurs.',
    termsConditionsProteccionDatosTitulo: 'IX.-  PROTECTION DES DONNÉES',
    termsConditionsProteccionDatosParrafo1: 'Vous pouvez consulter les informations détaillées sur la Protection des données sur notre page de',
    termsConditionsLeyJurisdiccionTitulo: 'X.- LOI APPLICABLE ET JURIDICTION',
    termsConditionsLeyJurisdiccionParrafo1: 'Toutes les Conditions, générales et particulières, introduites dans la présente application, sont soumises à la réglementation espagnole. Tous les conflits, controverses ou situations dérivés des Conditions générales et particulières seront soumis aux cours et tribunaux de la ville de Madrid, les parties renonçant expressément à leur juridiction.'
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  cookies: {
    cookiesPoliciesCompliance: 'Politique de cookies',
    cookiesPoliciesIntroText: 'Ce site web utilise des cookies pour améliorer l’expérience de navigation sur le site web. Vous pouvez changer ces paramètres à tout moment. Les cookies classés comme nécessaires sont stockés sur votre navigateur et sont essentiels pour le fonctionnement des fonctionnalités basiques du site web. Les cookies tiers seront stockés dans votre navigateur uniquement avec votre consentement. Ils nous aident à analyser et comprendre comment est utilisé ce site web. Vous pouvez choisir de ne pas recevoir ces cookies, toutefois l’exclusion volontaire de l’un de ces cookies peut entraîner l’indisponibilité de certaines fonctions. Pour plus d’informations sur la manière de supprimer les cookies, veuillez consulter la fonction Aide de votre navigateur.',
    cookiesPoliciesNecessaryCookies: 'Cookies nécessaires',
    cookiesPoliciesNecessaryCookiesText: 'Ce type de cookies est absolument essentiel au bon fonctionnement du site web. Cette catégorie inclut uniquement des cookies qui garantissent des fonctionnalités de base et des caractéristiques de sécurité du site web, ils ne compilent ni ne stockent aucune information personnelle sur les utilisateurs du site web.',
    cookiesPoliciesUnnecessaryCookies: 'Cookies non nécessaires',
    cookiesPoliciesUnnecessaryCookiesText: 'Les cookies qui peuvent ne pas être particulièrement nécessaires au fonctionnement du site web et qui sont spécifiquement utilisés pour compiler des données personnelles de l’utilisateur par le biais d’analyse, de publicités et autres contenus intégrés sont appelés cookies non nécessaires. Il est obligatoire d’obtenir le consentement de l’utilisateur avant d’exécuter ces cookies sur le site web.',
    cookiesPoliciesGoogleAnalyticsCookies: 'Cookies de Google Analytics',
    cookiesPoliciesGoogleAnalyticsCookiesText: 'Pour plus d’informations concernant ce type de cookies, vous pouvez consulter le document ‘Utilisation des cookies de Google Analytics sur les sites web’.',
    cookiesPoliciesGoogleAnalyticsCheckboxText: 'Maintenir des cookies actifs',
    cookiesNotificationBarText: 'Nous utilisons des cookies propres et tiers pour mesurer les visites, garantir la fonctionnalité du site web et analyser la navigation sur le site. Vous pouvez accepter les cookies ou configurer/refuser leur utilisation. Plus d’informations sur le propriétaire du site web, le responsable du traitement des cookies et ses données de contact dans notre',
    cookiesNotificationBarConfig: 'Configurer',
    cookiesPoliciesModalIntro: 'Introduction',
    cookiesPoliciesModalIntroP1: 'Ce site web utilise des cookies et/ou des technologies similaires qui stockent et récupèrent des informations quand vous naviguez pour améliorer et optimiser l’expérience utilisateur.',
    cookiesPoliciesModalIntroP2: 'En général, ces technologies peuvent servir à des finalités très diverses, par exemple reconnaître l’utilisateur, obtenir des informations sur ses habitudes de navigation, ou personnaliser l’affichage du contenu.',
    cookiesPoliciesModalIntroP3: 'Conformément au décret-loi royal 13/2012 et à son affectation dans l’article 22.2 de la loi sur les services de la société de l’information et le commerce électronique, vous êtes informé par la présente Politique des différents cookies utilisés par CUSTOMER COMMUNICATIONS TECKNALIA, S.L. dans les différents moyens ou canaux de communication, propres ou tiers, exemptés ou non du devoir d’informer, et des différents moyens permettant de les refuser, les autoriser ou les bloquer.',
    cookiesPoliciesModalIntroP4: 'Vous trouverez ci-dessous des informations détaillées sur : les ‘cookies’, quelle typologie est utilisée sur ce site web, comment ils peuvent être désactivés sur un navigateur et comment bloquer spécifiquement l’installation de cookies tiers.',
    cookiesPoliciesModalResponsibility: 'Qui est le responsable de l’utilisation des cookies sur le présent site web ?',
    cookiesPoliciesModalResponsibilityP1: 'Le responsable de l’utilisation des cookies est CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (ci-après CCOMMS), titulaire du N.I.F. nº B-86414000 et dont le siège social est situé à l’adresse ‘Leganés (Madrid), Avenida de la Recomba, 14’’ dûment inscrite au Registre du commerce de Madrid au tome 29 801, folio 98, 8e section, feuille nº M-536210.',
    cookiesPoliciesModalResponsibilityP2: 'Adresse électronique du délégué à la protection des données.',
    cookiesPoliciesModalWhatIsCookie: 'Qu’est-ce qu’un cookie ?',
    cookiesPoliciesModalWhatIsCookieP1: 'Un cookie est une donnée, un fichier ou un dispositif de stockage et de récupération des données (DARD), qu’un navigateur web stocke automatiquement sur l’appareil (smartphone, tablette ou smart TV) d’un utilisateur lorsqu’il accède à des pages web, comptes de réseaux sociaux ou quand il reçoit un courrier électronique.',
    cookiesPoliciesModalWhatIsCookieP2: 'Les cookies permettent de stocker et de récupérer automatiquement des données sur l’utilisation qui est faite des médias numériques cités et de l’équipement ou appareil depuis lequel on y accède.',
    cookiesPoliciesModalWhatIsCookieP3: 'Un cookie, ce sont des données qui sont stockées sur l’ordinateur de l’utilisateur. Ce ne sont pas des logiciels, ni des fragments de code, ce sont de simples données. En principe, les cookies ne peuvent donc pas transmettre et exécuter un virus, ni installer un logiciel malveillant tel que des chevaux de Troie ou des logiciels espions.',
    cookiesPoliciesModalCookieTypes: 'Quels types de cookies sont utilisés sur le site web et dans quel but ?',
    cookiesPoliciesModalCookiesInstall: 'Quels cookies peuvent être installés sur le site de Vérification de certificats ?',
    cookiesPoliciesModalCookiesInstallP1: 'L’accès au site de Vérification de certificats implique l’installation des cookies, propres et/ou tiers, détaillés dans le tableau ci-dessous :',
    cookiesPoliciesModalBrowserConfigCookies: 'Comment configurer les cookies sur le navigateur ?',
    cookiesPoliciesModalBrowserConfigCookiesP1: 'Vous avez la possibilité de ne pas recevoir de cookies ou d’être informé de leur installation en paramétrant votre navigateur à cet effet. Tous les cookies, sauf ceux de Flash, peuvent être gérés sur votre navigateur, normalement dans la rubrique ‘Options’ ou ‘Préférences’.',
    cookiesPoliciesModalBrowserConfigCookiesP2: 'Vous pouvez les désactiver tous, auquel cas votre navigateur n’autorisera aucune installation de cookies (ce qui bloquera certaines fonctions du site web), ou uniquement ceux qui viennent de certains sites, ce qui vous permettra de profiter des fonctionnalités avancées des sites qui respectent la confidentialité de vos données, et d’exclure ceux qui ne le font pas.',
    cookiesPoliciesModalBrowserConfigCookiesP3: 'Les liens suivants vous permettront d’accéder aux principales pages d’assistance :',
    cookiesPoliciesModalBrowserConfigCookiesP4: 'De même, l’utilisateur peut s’opposer à l’utilisation de certains cookies par l’intermédiaire des services suivants :',
    cookiesPoliciesModalBrowserConfigCookiesLI1: 'Bloquer les cookies dans Chrome',
    cookiesPoliciesModalBrowserConfigCookiesLI2: 'Bloquer les cookies dans Safari',
    cookiesPoliciesModalBrowserConfigCookiesLI3: 'Bloquer les cookies dans Firefox',
    cookiesPoliciesModalBrowserConfigCookiesLI4: 'Bloquer les cookies dans Internet Explorer',
    cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
    cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
    cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
    cookiesPoliciesModalUnavailableCookies: 'Que se passe-t-il si les cookies sont désactivés ?',
    cookiesPoliciesModalUnavailableCookiesP1: 'Si vous refusez les cookies, vous pourrez continuer à naviguer sur notre site, toutefois l’utilisation de certains services pourra être limitée, ce qui pourrait rendre votre expérience moins satisfaisante.',
    cookiesPoliciesModalUnavailableCookiesP2: 'Le refus, le blocage ou la non-autorisation des cookies décrits dans la présente Politique peut affecter une navigation correcte dans les contenus du site web.',
    cookiesPoliciesModalAcceptCookies: 'Acceptation de la Politique de cookies',
    cookiesPoliciesModalAcceptCookiesP1: 'En cliquant sur le bouton ‘ACCEPTER COOKIES’, vous assumez que vous acceptez l’utilisation de cookies.',
    cookiesPoliciesModalAcceptCookiesP2: 'Cependant, à chaque ouverture de session, la Politique de cookies sera affichée en bas de la page pour que vous donniez votre consentement.',
    cookiesPoliciesModalUpgradeCookies: 'Notre Politique de cookies est-elle mise à jour ?',
    cookiesPoliciesModalUpgradeCookiesP1: 'Il est possible que les informations fournies dans la présente Politique de Cookies soient modifiées par des mises à jour ou des changements dans la politique de traitement des cookies ; de même, l’Agence espagnole de protection des données pourrait dicter des instructions et/ou recommandations de modification de cette Politique.',
    cookiesPoliciesModalUpgradeCookiesP2: 'C’est pourquoi nous vous recommandons de vérifier cette Politique chaque fois que vous accédez à notre site web dans le but d’être dûment informé sur la manière et la raison pour laquelle nous utilisons les cookies.',
    cookiesPoliciesModalRecipientCookies: 'Qui sont les destinataires des informations et comment l’utilisation des cookies affecte-t-elle la protection des données à caractère personnel ?',
    cookiesPoliciesModalRecipientCookiesP1: 'Pour plus d’informations sur le traitement de vos données, vous pouvez consulter la',
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Politique de protection des données',
    cookiesPoliciesModalContactInfoCookies: 'Informations de contact',
    cookiesPoliciesModalContactInfoCookiesP1: 'En cas de doute et pour toute explication et/ou suggestion concernant les cookies, nous vous prions de nous contacter par courrier électronique',
    cookiesPoliciesModalDatatableCookieType: 'Type de cookie',
    cookiesPoliciesModalDatatableTreatmentTerm: 'Délai de traitement',
    cookiesPoliciesModalDatatableTypeAnalytics: 'Analytiques',
    cookiesPoliciesModalDatatableTypeSelf: 'Propres',
    cookiesPoliciesModalDatatableTypeAnalyticsD: 'Chaque fois qu’un utilisateur visite un service, un outil d’un prestataire externe crée un cookie analytique sur l’ordinateur de l’utilisateur. Ce cookie, qui est généré uniquement lors de la visite, sera utile à CCOMMS lors de prochaines visites pour identifier le visiteur de manière anonyme.',
    cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permettre l’identification anonyme des utilisateurs navigateurs par l’intermédiaire du ‘cookie’ (il identifie des navigateurs et appareils, non des personnes) et donc la comptabilisation approximative du nombre de visiteurs et sa tendance dans le temps. Identifier de manière anonyme les contenus les plus visités et les plus attrayants pour les utilisateurs. Savoir si l’utilisateur qui accède est nouveau ou s’il renouvelle sa visite. Sauf si l’utilisateur décide de s’inscrire chez CCOMMS, le ‘cookie’ ne sera jamais associé à aucune donnée à caractère personnel permettant de l’identifier directement. Ces cookies seront uniquement utilisés à des fins statistiques qui aident à optimiser et améliorer l’expérience des utilisateurs sur le site.',
    cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistants',
    cookiesPoliciesModalDatatableTypeSelfD: 'Ce type de cookies permet d’élargir les informations affichées à chaque utilisateur anonyme dans les services de CCOMMS.',
    cookiesPoliciesModalDatatableTypeSelfF: 'Entre autres, il stocke la durée ou la fréquence d’affichage des différentes sections, l’interaction avec les sections, ou les patrons de navigation et/ou le comportement de l’utilisateur.',
    cookiesPoliciesModalDatatableTypeSelfT: 'Persistants',
    description: 'Description',
    privacyPoliciesConditionsFinalidadTitle: 'Finalité',
    accept: 'Accepter',
    reject: 'Refuser',
    save: 'Enregistrer',
    cookiesPoliciesCertySign: 'Cookies dans Vérification de certificats',
    cookiesNecessaryCookieDescription: 'Ce cookie est nécessaire, il sert à établir la préférence de l’utilisateur concernant la politique de cookies.',
    cookiesGAMessage: 'Mesure interne avec Google Analytics. Ces cookies stockent un identifiant unique permettant de reconnaître l’utilisateur lors de futures visites sur le site web.',
    cookiesGAFGenericMessage: 'Mesure interne avec Google Analytics.'
  },
  conditionsOfService: 'Conditions de service',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "Icône de fermeture",
    zoomTRID: "Zoom TRID",
    zoomCSV: "Zoom CSV",
    sampleCertificate: "Certificat exemple",
    downloadButton: "Bouton de téléchargement",
    checkboxActiveUser: "Case à cocher utilisateur actif",
    selectLanguage: "Sélectionner la langue",
    openHelpDialog: "Ouvrir la boîte de dialogue d'assistance",
    trid: "Entrer le TRID",
    csv: "Entrer le CSV",
    part1Container: "1 - Indicateur de position de l'identifiant unique de transaction (TRID) dans le profil du certificat",
    part2Container: "2 - Indicateur de position de l'identifiant unique du certificat (CSV) dans le certificat exemple",
    sampleTrid: "Identifiant unique de transaction (TRID) dans le certificat exemple",
    sampleCSV: "Identifiant unique de certificat (CSV) dans le certificat exemple"
  }
};