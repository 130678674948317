export default {
  app: {
    header: {
      language: "Idioma"
    }
  },
  home: {
    title: "Verificació en Línia de Certificats",
    trid: "identificador únic de transacció (trid)",
    csv: "identificador únic de certificat (csv)",
    description:
      "Introduïu les dades següents per verificar l'autenticitat de la declaració de finalització o certificat de què disposeu.",
    subtitle: "CODI DE VALIDACIÓ",
    validate: "Valida",
    resultError: "Si us plau, reviseu les dades introduïdes",
    messagesInputs: {
      required: "Requerit",
      minimo: "Mínim 4 caràcters",
      maximo: "Mínim 1000 caràcters"
    }
  },
  confirmation: {
    title: "Les dades corresponen a la següent certificació",
    subtitle:
      "Podeu descarregar el certificat amb el botó inferior i comprovar que coincideix amb la informació de què disposeu.",
    download: "Descarrega",
    back: "Enrere",
    certificateDownloaded: "El vostre certificat s'ha descarregat correctament"
  },
  helpDialog: {
    messageSent: {
      title: "Contacta amb suport",
      subtitle:
        "La vostra sol·licitud s'ha enviat al nostre departament de suport. Un agent es posarà en contacte al més aviat possible.",
      back: "Enrere"
    },
    text: "Necessiteu ajuda?",
    contactWithSupport: "Contacta amb suport",
    dialogPhrase:
      "Introduïu les dades i l'equip de suport tècnic us respondrà al més aviat possible. Horaris d'atenció, laborables:",
    monFriHours:
      "<p class='body-2 font-weight-medium mb-7'><strong>Dilluns a Divendres</strong>: 8:00 h - 17:00 h</p>",
    message: "Missatge",
    sendRequest: "Envia sol·licitud",
    requiredEmailFormat: "Format de correu electrònic incorrecte",
    requiredEmail: "Correu electrònic requerit",
    requiredMessage: "Missatge requerit",
    requiredName: "Nom requerit",
    email: "Correu electrònic",
    name: "Nom"
  },
  termsAndConditions: {
    title: "Termes i Condicions",
    termsConditions1:
      " CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (en endavant, 'CCOMMS') en la seva condició de titular i responsable de la present APP, en compliment de les disposicions de l'article 10 de la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i de Comerç Electrònic, posa a disposició dels usuaris la següent informació general d'aquesta APP:",
    companyInfo:
      "<p class='mb-1'><strong>Titular</strong>: Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>NIF</strong>: CB-86414000 </p><p class='mb-1'><strong>Domicili Social</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Dades Registrals</strong>: Registre Mercantil de Madrid, Tom 29.801, Foli 98, Secció 8a, Full número M-536210.</p><p class='mb-1'><strong>Contacte: Telèfon</strong>: 916 895 665</p><p class='mb-1'><strong>Correu electrònic</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>Correu electrònic del delegat de Protecció de Dades</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Delegat de Protecció de Dades de MailTecK'>dpo@mailteck.com</a></p>",
    termsConditionsObjetoTitulo: "I.- OBJECTE",
    termsConditionsObjetoParrafo1:
      "Les presents Condicions Generals d'Ús (en endavant, les 'CGU') regulen l'ús de l'Aplicació Validació de certificat (en endavant, l'APP) que CCOMMS posa a disposició del usuaris que hi accedeixin a fi de procedir a poder dur a terme la sol·licitud realitzada.",
    termsConditionsObjetoParrafo2:
      "Per tant, les presents CGU s'aplicaran tant a l'activitat promocional i de subministrament d'informació que s'efectua a través de l'APP com a la prestació dels serveis oferts a l'APP, de manera que les CGU regiran en tot moment tant la simple navegació per l'APP com la contractació de serveis en el marc de l'APP, tot i que aquestes últimes activitats se sotmetran tant a les CGU descrites a continuació com a les Condicions Generals de Contractació aplicables, i les Condicions Particulars que, si escau, poguessin existir.",
    termsConditionsUsuariosTitulo: "II.- USUARIS",
    termsConditionsUsuariosParrafo1:
      "L'accés a l'APP i/o l'ús de l'APP atribueix a la persona que el realitza la condició d'usuari que accepta, des d'aquest mateix moment, plenament i sense cap reserva, les presents CGU i les Condicions Particulars que, si s'escauen, complementen, modifiquen o substitueixen les CGU en relació amb determinats serveis i continguts de l'APP, i que obliguen a respectar les restriccions d'ús de la pàgina esmentada establertes per CCOMMS en cada moment.",
    termsConditionsAccesoAPPTitulo: "III.- ACCÉS A L'APP",
    termsConditionsAccesoAPPParrafo1:
      "L'accés i ús de la APP té caràcter restringit i, per poder accedir-hi, caldrà que l'usuari es registri prèviament.",
    termsConditionsUsoAPPTitulo: "IV.- ÚS DE L'APP",
    termsConditionsUsoAPPParrafo1:
      "L'usuari es compromet a utilitzar l'APP, i els seus serveis i continguts sense contravenir la legislació vigent, la bona fe, els usos acceptats generalment i l'ordre públic. Així mateix, queda prohibit l'ús de l'APP amb objectius il·lícits o lesius contra CCOMMS o qualsevol tercer o que, de qualsevol forma, puguin causar un perjudici o impedir el funcionament normal de l'APP.",
    termsConditionsResposabilidadTitulo:
      "V-. RESPONSABILITAT DE L'USUARI PER DANYS I PERJUDICIS",
    termsConditionsResposabilidadParrafo1:
      "L'ús de l'APP es durà a terme sota la responsabilitat única i exclusiva de l'usuari. Aquesta responsabilitat s'estendrà a l'ús per part de l'usuari o de qualsevol tercer de qualsevol contrasenya o clau similar assignada per a l'accés a l'APP o a qualsevol dels seus serveis.",
    termsConditionsResposabilidadParrafo2:
      "Sense perjudici de la disposició anterior, CCOMMS es reserva el dret a denegar o inhabilitar l'accés a l'APP en qualsevol moment i sense necessitat d'avís previ, o a restringir-hi l'accés a aquells usuaris que incompleixin aquestes CGU, o les normes establertes per CCOMMS o els seus col·laboradors, o que puguin pertorbar el bon funcionament, imatge, credibilitat i/o prestigi de CCOMMS o els seus col·laboradors.",
    termsConditionsResposabilidadParrafo3:
      "En aquest sentit, CCOMMS es reserva la facultat de cancel·lar o restringir l'accés a l'APP a determinats Usuaris en cas d'observar qualssevol conductes que, segons CCOMMS, resultin contràries a aquestes Condicions d'Ús, la llei, o les normes establertes per CCOMMS o els seus col·laboradors, o que puguin pertorbar el bon funcionament, imatge, credibilitat i/o prestigi de CCOMMS o els seus col·laboradors. Així mateix, CCOMMS podrà exigir responsabilitat als Usuaris pels danys i perjudicis derivats del mal ús de l'APP.",
    termsConditionsResposabilidadParrafo4:
      "CCOMMS no garanteix la licitud, fiabilitat i utilitat dels continguts que els usuaris transmetin durant la utilització de l'APP, ni tampoc la seva veracitat o exactitud.",
    termsConditionsResposabilidadParrafo5:
      "CCOMMS no controla la utilització que els usuaris fan de l'APP, ni garanteix que la utilitzin d'una forma que s'ajusti a les presents Condicions Generals.",
    termsConditionsResposabilidadParrafo6:
      "CCOMMS no controla ni garanteix l'absència de virus informàtics en els serveis prestats per tercers a través de l'APP que puguin produir alteracions en el seu sistema informàtic (programari i maquinari) o en els documents i fitxers electrònics emmagatzemats al seu sistema informàtic.",
    termsConditionsPropiedadIntelectualTitulo: "VI.- PROPIETAT INTEL·LECTUAL",
    termsConditionsPropiedadIntelectualParrafo1:
      "Qualsevol ús o modificació del Material o dels Continguts per a qualsevol altre objectiu diferent de l'autoritzat a les Condicions Generals es considerarà una violació de les lleis internacionals del 'copyright', que protegeixen els drets d'autor.",
    termsConditionsPropiedadIntelectualParrafo2:
      "Respecte dels continguts, es prohibeix:",
    termsConditionsPropiedadIntelectualListaELemento1:
      "La seva reproducció, còpia, distribució, modificació o manipulació de qualsevol forma i amb qualsevol finalitat, excepte si es disposa de l'autorització prèvia per escrit dels seus titulars legítims o es troba permesa per la llei.",
    termsConditionsPropiedadIntelectualListaELemento2:
      "Qualsevol vulneració dels drets de CCOMMS o dels seus titulars legítims sobre els esmentats drets.",
    termsConditionsPropiedadIntelectualListaELemento3:
      "La seva utilització per a tot tipus de finalitats comercials o publicitàries diferents de les estrictament permeses.",
    termsConditionsPropiedadIntelectualParrafo3:
      "CCOMMS garanteix que els continguts, inclosos els de propietat intel·lectual, no són de caràcter il·lícit ni infringeixen la normativa vigent. Per tant, els continguts de l'APP no tindran caràcter xenòfob, pornogràfic, discriminatori o racista, ni fomentaran qualsevol altra forma la violència.",
    termsConditionsPropiedadIntelectualParrafo4:
      "A més, CCOMMS adoptarà les mesures legals que consideri convenients per impedir qualsevol tipus de conducta contrària a la llei o immoral.",
    termsConditionsModificacionUnilateralTitulo: "VII.- MODIFICACIÓ UNILATERAL",
    termsConditionsModificacionUnilateralParrafo1:
      "CCOMMS podrà modificar unilateralment i sense avís previ, sempre que ho consideri oportú, l'estructura i disseny de l'APP, així com modificar o eliminar els serveis, els continguts i les condicions d'accés i/o ús de l'APP.",
    termsConditionsExclusionGarantiasTitulo:
      "VIII.- EXCLUSIÓ DE GARANTIES I RESPONSABILITAT",
    termsConditionsExclusionGarantiasParrafo1:
      "CCOMMS no atorga cap garantia ni es fa responsable en cap cas pel que fa als danys i perjudicis de qualsevol tipus que poguessin produir:",
    termsConditionsExclusionGarantiasListaELemento1:
      "L'incompliment de la llei, la moral, els bons costums i l'ordre públic, o l'ús dels productes i/o serveis oferts de forma no diligent o incorrecta, o amb objectius o efectes il·lícits o contraris a les disposicions de les presents CGU o de les condicions aplicables en cada cas.",
    termsConditionsExclusionGarantiasListaELemento2:
      "Per l'adquisició o contractació dels serveis, béns o productes que es puguin adquirit o contractar a tercers a través d'un accés a l'APP de CCOMMS, especialment en aquells casos en què el procés de compra o contractació es realitza directament a l'aplicació del tercer i tot i que apareguin distintius o un 'frame' amb els elements gràfics de l'app (Branding).",
    termsConditionsExclusionGarantiasListaELemento3:
      "La manca de disponibilitat, manteniment i funcionament efectiu de l'APP i/o dels seus serveis o continguts.",
    termsConditionsExclusionGarantiasListaELemento4:
      "La manca d'utilitat, adequació o validesa de l'APP i/o dels seus serveis o continguts per satisfer necessitats, activitats o resultats concrets, o expectatives dels usuaris.",
    termsConditionsExclusionGarantiasListaELemento5:
      "L'existència de virus, programes maliciosos o lesius en els continguts.",
    termsConditionsExclusionGarantiasListaELemento6:
      "La recepció, obtenció, emmagatzemament, difusió o transmissió dels continguts per part dels usuaris.",
    termsConditionsExclusionGarantiasListaELemento7:
      "La il·licitud o manca de veracitat, exactitud, fiabilitat, pertinença, actualitat i exhaustivitat dels continguts i la informació transmesos o posats a disposició del usuaris, incloent-hi la informació i els serveis prestats per tercers o pels usuaris a través de l'APP.",
    termsConditionsExclusionGarantiasListaELemento8:
      "L'incompliment per part de tercers de les seves obligacions o compromisos en relació amb els serveis prestats als usuaris a través de l'APP.",
    termsConditionsExclusionGarantiasListaELemento9:
      "Dels continguts, arxius, informació, publicitat, opinions, conceptes i imatges que no depenguin de l'APP ni siguin gestionades per CCOMMS. Tampoc es responsabilitza del mal ús que es realitzi dels continguts de l'APP. La responsabilitat exclusiva recaurà sobre la persona que accedeixi a aquests continguts i els utilitzi",
    termsConditionsExclusionGarantiasListaELemento10:
      "De l'ús indegut de les claus d'accés dels usuaris per a l'accés als continguts de l'APP que les requereixin i de les conseqüències de qualsevol tipus derivades del mal ús per part dels usuaris, la seva pèrdua i el seu ús indegut per part de tercers no autoritzats.",
    termsConditionsExclusionGarantiasListaELemento11:
      "La incapacitat de qualsevol usuari o la suplantació de la personalitat d'un tercer efectuada per l'usuari.",
    termsConditionsExclusionGarantiasListaELemento12:
      "Dels continguts facilitats pels usuaris.",
    termsConditionsProteccionDatosTitulo: "IX.- PROTECCIÓ DE DADES",
    termsConditionsProteccionDatosParrafo1:
      "Podeu consultar la informació detallada sobre Protecció de Dades a la nostra pàgina de",
    termsConditionsLeyJurisdiccionTitulo: "X.- LLEI APLICABLE I JURISDICCIÓ",
    termsConditionsLeyJurisdiccionParrafo1:
      "Totes les Condicions, Generals i Particulars, inserides a la present APP es regeixen per la normativa espanyola. Tots els conflictes, controvèrsies o situacions derivades de les Condicions Generals i particulars se sotmetran als Jutjats i Tribunals de la Vila de Madrid, i les parts renunciaran al seu fur."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  cookies: {
    cookiesPoliciesCompliance: "Política de Galetes",
    cookiesPoliciesIntroText:
      "Aquest lloc web utilitza galetes per millorar la vostra experiència mentre hi navegueu. Podeu canviar aquesta configuració en qualsevol moment. Les galetes classificades com a necessàries s'emmagatzemen en el vostre navegador i són essencials per al funcionament de les funcions bàsiques del lloc web. Les galetes de tercers s'emmagatzemaran en el vostre navegador només amb el vostre consentiment i ens ajuden a analitzar i comprendre com s'utilitza aquest lloc web. Podeu optar per no rebre aquestes galetes, però l'exclusió voluntària d'alguna d'aquestes galetes pot fer que algunes funcions deixin d'estar disponibles. Per obtenir informació sobre eliminar les galetes, si us plau, consulteu la funció d'ajuda del vostre navegador.",
    cookiesPoliciesNecessaryCookies: "Galetes necessàries",
    cookiesPoliciesNecessaryCookiesText:
      "Aquest tipus de galetes són absolutament essencials perquè el lloc web funcioni correctament. Aquesta categoria només inclou galetes que garanteixen funciones bàsiques i característiques de seguretat del lloc web, i no recopilen ni emmagatzemen cap informació personal sobre els usuaris del lloc web.",
    cookiesPoliciesUnnecessaryCookies: "Galetes no necessàries",
    cookiesPoliciesUnnecessaryCookiesText:
      "Les galetes que poden no ser particularment necessàries perquè el lloc web funcioni i s'utilitzen específicament per recopilar dades personals de l'usuari a través d'anàlisis, anuncis i altres continguts integrats es denominen galetes no necessàries. És obligatori obtenir el consentiment de l'usuari abans d'executar aquestes galetes en el vostre lloc web.",
    cookiesPoliciesGoogleAnalyticsCookies: "Galetes de Google Analytics",
    cookiesPoliciesGoogleAnalyticsCookiesText:
      "Per obtenir més informació respecte a aquest tipus de galetes, podeu consultar el document d'Ús de les galetes de Google Analytics en llocs web",
    cookiesPoliciesGoogleAnalyticsCheckboxText: "Mantenir galetes actives",
    cookiesNotificationBarText:
      "Utilitzem galetes, tant pròpies com de tercers, per mesurar les visites, garantir la funcionalitat del lloc web i analitzar la navegació pel lloc web. A continuació podeu acceptar les galetes o configurar/rebutjar el seu ús. Podeu obtenir més informació sobre la titularitat del web, el responsable del tractament de les galetes i les seves dades de contacte a la nostra",
    cookiesNotificationBarConfig: "Configura",
    cookiesPoliciesModalIntro: "Introducció",
    cookiesPoliciesModalIntroP1:
      "Aquest lloc web utilitza galetes i/o tecnologies similars que emmagatzemen i recuperen informació quan navegueu per millorar i optimitzar l'experiència d'usuari.",
    cookiesPoliciesModalIntroP2:
      "En general, aquestes tecnologies poden servir per a finalitats molt diverses com, per exemple, reconèixer algú com a usuari, obtenir informació sobre hàbits de navegació, o personalitzar la forma en què se mostra el contingut.",
    cookiesPoliciesModalIntroP3:
      "De conformitat amb el Reial Decret Llei 13/2012 i la seva afectació a l'article 22.2 de la Llei de Serveis de la Societat de la Informació i Comerç Electrònic, mitjançant la present Política s'us informa sobre les diferents galetes que CUSTOMER COMMUNICATIONS TECKNALIA, S.L. recopila a través dels diferents mitjans o canals de comunicació, pròpies o de tercers, exemptes o no del deure d'informar, i els diferents mitjans a través dels quals les podeu rebutjar, permetre o bloquejar.",
    cookiesPoliciesModalIntroP4:
      "A continuació es detalla informació sobre: què són les 'Galetes', quina tipologia utilitza aquest lloc web, com es poden desactivar en un navegador i com es pot bloquejar específicament la instal·lació de Galetes de tercers.",
    cookiesPoliciesModalResponsibility:
      "Qui és el responsable de l'ús de les galetes en aquest lloc web?",
    cookiesPoliciesModalResponsibilityP1:
      "El responsable de l'ús de les galetes és CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en endavant, 'CCOMMS'), amb NIF número B-86414000 i domicili social a Leganés (Madrid), Avenida de la Recomba, 14, degudament inscrita en el Registre Mercantil de Madrid, al Tom 29.801, Foli 98, Secció 8a, Full número M-536210.",
    cookiesPoliciesModalResponsibilityP2:
      "Correu electrònic del delegat de Protecció de Dades.",
    cookiesPoliciesModalWhatIsCookie: "Què és una galeta?",
    cookiesPoliciesModalWhatIsCookieP1:
      "Una galeta és una dada, fitxer o dispositiu d'emmagatzemament i recuperació de dades (DARD) que un navegador web emmagatzema de forma automàtica en el dispositiu (smartphone, tauleta o televisió connectada) d'un usuari quan aquest últim accedeix a pàgines web o comptes de xarxes socials, o rep un correu electrònic.",
    cookiesPoliciesModalWhatIsCookieP2:
      "Les galetes permeten emmagatzemar i recuperar de forma automatitzada dades sobre l'ús que s'ha fet dels mitjans digitals esmentats i de l'equip o dispositiu des del qual s'accedeix a aquests mitjans.",
    cookiesPoliciesModalWhatIsCookieP3:
      "Una galeta no és més que dades que s'emmagatzemen a l'ordinador de l'usuari i, per tant, no és programari ni fragments de codi; només és un conjunt de dades. Així doncs, en principi les galetes no poden transmetre i executar virus, ni instal·lar programari maliciós, com ara troians o programes d'espionatge.",
    cookiesPoliciesModalCookieTypes:
      "Quins tipus de galetes hi ha al lloc web i la seva finalitat",
    cookiesPoliciesModalCookiesInstall:
      "Quines galetes es poden instal·lar al web de Verificació de Certificats?",
    cookiesPoliciesModalCookiesInstallP1:
      "L'accés al web de Verificació de Certificats implica que es poden instal·lar les galetes pròpies i/o de tercers que se detallen en el següent quadre:",
    cookiesPoliciesModalBrowserConfigCookies:
      "Com configurar les galetes en el navegador",
    cookiesPoliciesModalBrowserConfigCookiesP1:
      "Teniu l'opció de no rebre galetes o de rebre informació sobre la seva fixació mitjançant la configuració del vostre navegador a aquest efecte. Podeu gestionar totes les galetes, excepte les de Flash, des del vostre navegador, normalment a la secció 'Opcions' o 'Preferències'.",
    cookiesPoliciesModalBrowserConfigCookiesP2:
      "Podeu desactivar-les del tot i que el vostre navegador no permeti que se n'instal·li cap (impossibilitant així que podeu fer ús de totes les funcions del lloc web), o només les que provinguin de determinats webs, fet que us permetrà poder gaudir de les funcions avançades dels webs que respectin la privacitat de les vostres dades i excloure aquelles que no ho facin.",
    cookiesPoliciesModalBrowserConfigCookiesP3:
      "En los següents enllaços us facilitem els accessos a les principals pàgines de suport:",
    cookiesPoliciesModalBrowserConfigCookiesP4:
      "Així mateix, l'Usuari pot oposar-se a l'ús de determinades galetes mitjançant els següents serveis:",
    cookiesPoliciesModalBrowserConfigCookiesLI1: "Bloqueig de galetes a Chrome",
    cookiesPoliciesModalBrowserConfigCookiesLI2: "Bloqueig de galetes a Safari",
    cookiesPoliciesModalBrowserConfigCookiesLI3:
      "Bloqueig de galetes a Firefox",
    cookiesPoliciesModalBrowserConfigCookiesLI4:
      "Bloqueig de galetes a Internet Explorer",
    cookiesPoliciesModalBrowserConfigCookiesLI5:
      "http://www.criteo.com/deactivate-criteo-banners/",
    cookiesPoliciesModalBrowserConfigCookiesLI6: "http://youronlinechoices.eu/",
    cookiesPoliciesModalBrowserConfigCookiesLI7:
      "http://www.networkadvertising.org/choices/",
    cookiesPoliciesModalUnavailableCookies:
      "Què passa si es deshabiliten les galetes?",
    cookiesPoliciesModalUnavailableCookiesP1:
      "Si rebutja les galetes podrà seguir navegant pel nostre Lloc Web, tot i que l'ús d'alguns dels seus serveis podrà ser limitat i, per tant, la vostra experiència podrà resultar menys satisfactòria.",
    cookiesPoliciesModalUnavailableCookiesP2:
      "El rebuig, bloqueig o no permissibilitat de les galetes descrites a la present Política pot afectar a la correcta navegació pels continguts reproduïts en el lloc web.",
    cookiesPoliciesModalAcceptCookies: "Acceptació de la política de galetes",
    cookiesPoliciesModalAcceptCookiesP1:
      "Si premeu el botó 'ACCEPTA GALETES' entendrem que accepteu l'ús de galetes.",
    cookiesPoliciesModalAcceptCookiesP2:
      "No obstant això, amb cada inici de sessió es mostrarà la Política de galetes a la part inferior de qualsevol pàgina del portal amb l'objectiu que en sigueu conscient.",
    cookiesPoliciesModalUpgradeCookies:
      "Actualitzem la nostra política de galetes?",
    cookiesPoliciesModalUpgradeCookiesP1:
      "És possible que la informació proporcionada en aquesta Política de Galetes es pugui modificar a causa d'actualitzacions o canvis en la política de tractament de galetes. Així mateix, l'Agència Espanyola de Protecció de Dades podria dictar instruccions i/o recomanacions que la fessin variar.",
    cookiesPoliciesModalUpgradeCookiesP2:
      "Per això us recomanem que reviseu aquesta política cada cop que accediu al nostre Lloc Web amb l'objectiu d'estar ben informat sobre com i perquè utilitzem les galetes.",
    cookiesPoliciesModalRecipientCookies:
      "Qui són els destinataris de la informació i com afecta l'ús de galetes a la protecció de dades personals?",
    cookiesPoliciesModalRecipientCookiesP1:
      "Per obtenir més informació sobre el tractament de les vostres dades, podeu consultar la ",
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS:
      "Política de Protecció de dades",
    cookiesPoliciesModalContactInfoCookies: "Informació de contacte",
    cookiesPoliciesModalContactInfoCookiesP1:
      "Per a qualsevol dubte, aclariment i/o suggeriment sobre les galetes, si us plau, poseu-vos en contacte a través del correu electrònic ",
    cookiesPoliciesModalDatatableCookieType: "Tipus de Galeta",
    cookiesPoliciesModalDatatableTreatmentTerm: "Termini del Tractament",
    cookiesPoliciesModalDatatableTypeAnalytics: "Analítiques",
    cookiesPoliciesModalDatatableTypeSelf: "Pròpies",
    cookiesPoliciesModalDatatableTypeAnalyticsD:
      "Cada cop que un Usuari visita un Servei, una eina d'un proveïdor extern genera una Galeta analítica a l'ordinador de l'Usuari. Aquesta Galeta, que només es genera en la visita, servirà en properes visites a CCOMMS per identificar el visitant de forma anònima.",
    cookiesPoliciesModalDatatableTypeAnalyticsF:
      "Permetre la identificació anònima dels Usuaris navegants a través de la 'Galeta' (identifica navegadors i dispositius, no persones) i, per tant, el recompte aproximat del número de visitants i la seva tendència en el temps. Identificar de forma anònima els continguts més visitats i més atractius per als Usuaris. Saber si l'Usuari que hi està accedint és nou o repeteix visita. Excepte si l'Usuari decideix enregistrar-se a CCOMMS, la 'Galeta' mai anirà associada a cap dada de caràcter personal que el pugui identificar directament. Aquestes Galetes només s'utilitzaran amb propòsits estadístics que ajudin a l'optimització i millora de l'experiència dels Usuaris en el lloc web.",
    cookiesPoliciesModalDatatableTypeAnalyticsT: "Persistents",
    cookiesPoliciesModalDatatableTypeSelfD:
      "Aquest tipus de Galetes permeten ampliar la informació mostrada a cada Usuari anònim en els Serveis de CCOMMS.",
    cookiesPoliciesModalDatatableTypeSelfF:
      "Per exemple, s'emmagatzema la durada o freqüència de visualització de les diferents seccions, la interacció amb aquestes seccions, o els patrons de navegació i/o comportaments de l'Usuari.",
    cookiesPoliciesModalDatatableTypeSelfT: "Persistents",
    description: "Descripció",
    privacyPoliciesConditionsFinalidadTitle: "Finalitat",
    accept: "Accepta",
    reject: "Rebutja",
    save: "Desa",
    cookiesPoliciesCertySign: "Galetes a Verificació de Certificats",
    cookiesNecessaryCookieDescription:
      "Aquesta galeta és necessària i s'utilitza per establir la preferència de l'usuari respecte de la política de galetes.",
    cookiesGAMessage:
      "Mesura interna amb Google Analytics. Aquestes galetes emmagatzemen un identificador únic per reconèixer l'usuari quan visiti el lloc web en futures ocasions.",
    cookiesGAFGenericMessage: "Mesura interna amb Google Analytics."
  },
  conditionsOfService: 'Condicions de servei',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "icona tancar",
    zoomTRID: "zoom trid",
    zoomCSV: "zoom csv",
    sampleCertificate: "Certificat model",
    downloadButton: "botó descarregar",
    checkboxActiveUser: "Marqueu casella",
    selectLanguage: "Seleccionar idioma",
    openHelpDialog: "Obrir diàleg de suport",
    trid: "Escriure TRID",
    csv: "Escriure CSV",
    part1Container: "1 - Indicador de posició de l'identificador únic de transacció (trid) al certificat model",
    part2Container: "2 - Indicador de posició de l'identificador únic de certificat (csv) al certificat model",
    sampleTrid: "Identificador únic de transacció (trid) al certificat model",
    sampleCSV: "Identificador únic de certificat (csv) al certificat model"
  }
};
