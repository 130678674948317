export default {
  app: {
    header: {
      language: 'Sprache'
    }
  },
  home: {
    title: 'Online-Verifizierung der Zertifikate',
    trid: 'Eindeutige Transaktionskennung (TRID)',
    csv: 'Eindeutige Zertifikatskennung (CSV)',
    description: 'Bitte füllen Sie die folgenden Angaben aus, um die Echtheit der in Ihrem Besitz befindlichen Abschlusserklärung oder Bescheinigung zu überprüfen.',
    subtitle: 'VALIDIERUNGSCODE',
    validate: 'Validieren',
    resultError: 'Bitte überprüfen Sie die eingegebenen Daten.',
    messagesInputs: {
      required: 'Notwendig',
      minimo: 'Mindestens 4 Zeichen',
      maximo: 'Mindestens 1000 Zeichen'
    }
  },
  confirmation: {
    title: 'Ihre Daten stimmen mit der folgenden Zertifizierung überein',
    subtitle: 'Sie können Ihr Zertifikat mit einem Klick auf das untere Feld herunterladen und prüfen, ob die Informationen mit Ihren Daten übereinstimmen.',
    download: 'Herunterladen',
    back: 'Zurück',
    certificateDownloaded: 'Ihr Zertifikat wurde korrekt heruntergeladen.'
  },
  'helpDialog': {
    'messageSent': {
      title: 'Kontakt mit Support',
      subtitle: 'Wenn Sie Ihre Anfrage an unsere Support-Abteilung gesendet haben, wird sich ein Mitarbeiter schnellstmöglich mit Ihnen in Verbindung setzen.',
      back: 'Zurück'
    },
    text: 'Benötigen Sie Hilfe?',
    contactWithSupport: 'Kontakt mit Support',
    dialogPhrase: 'Geben Sie Ihre Daten ein und das technische Support-Team wird so schnell wie möglich reagieren. Geschäftszeiten, an Werktagen:',
    monFriHours: '<p class="body-2 font-weight-medium mb-7"><strong>Montags bis Freitags</strong>: 08:00 - 17:00 Uhr</p>',
    message: 'Nachricht',
    sendRequest: 'Anfrage senden',
    requiredEmailFormat: 'E-Mail-Format nicht korrekt',
    requiredEmail: 'E-Mail erforderlich',
    requiredMessage: 'Nachricht erforderlich',
    requiredName: 'Nachname erforderlich',
    email: 'E-Mail',
    name: 'Vorname'
  },
  termsAndConditions: {
    title: "Bedingungen und Voraussetzungen",
    termsConditions1: "CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (im Folgenden CCOMMS) in seiner Eigenschaft als Eigentümer und Verantwortlicher dieser App, in Übereinstimmung mit den Bestimmungen des Artikels 10 des Gesetzes 34/2002 vom 11. Juli über die Dienste der Informationsgesellschaft und den Elektro-Handel, stellt den Benutzern die folgenden Informationen dieser App zur Verfügung:",
    companyInfo: '<p class="mb-1"><strong>Schlagzeile</strong>: Customer Communications Tecknalia, S.L.</p><p class="mb-1"><strong>Steuernummer</strong>: CB-86414000 </p><p class="mb-1"><strong>Geschäftssitz</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class="mb-1"><strong>Angaben zur Registrierung</strong>: Handelsregister von Madrid in Band 29.801, Folio 98, Sektion 8ª, Blattnummer M-536210.</p><p class="mb-1"><strong>Kontakt: Telefonnummer</strong>: +34 916 895 665</p><p class="mb-1"><strong>E-Mail</strong>: <a  class="text-decoration-none" href="mailto:info@customercomms.com" title="v">info@customercomms.com</a></p><p class="mb-1"><strong>Correo electrónico del Delegado de Protección de Datos</strong>: <a href="mailto:dpo@mailteck.com" class="text-decoration-none" title="Delegado de Protección de Datos de MailTecK">dpo@mailteck.com</a></p>',
    termsConditionsObjetoTitulo: 'I.- ZIEL',
    termsConditionsObjetoParrafo1: 'Diese Allgemeinen Nutzungsbedingungen (im Folgenden AGB) regeln die Nutzung der Anwendung zur Zertifikatsvalidierung (im Folgenden APP), die CCOMMS den Nutzern zur Verfügung stellt, die auf diese zugreifen, um den gestellten Antrag ausführen zu können.',
    termsConditionsObjetoParrafo2: 'Die vorliegenden AGB gelten daher sowohl für die Werbetätigkeit und die Bereitstellung von Informationen, die über die APP erfolgen, als auch für die Erbringung der dort angebotenen Dienstleistungen, so dass sie jederzeit sowohl für das einfache Besuchen der APP als auch für die Beauftragung von Dienstleistungen im Rahmen der APP gelten, wobei die letztgenannten Tätigkeiten zusätzlich sowohl den nachstehend beschriebenen AGB als auch den geltenden Allgemeinen Vertragsbedingungen und den gegebenenfalls vorhandenen Besonderen Bedingungen unterliegen.',
    termsConditionsUsuariosTitulo: 'II.- BENUTZER',
    termsConditionsUsuariosParrafo1: 'Der Zugriff auf und/oder die Nutzung der APP setzt voraus, dass der Nutzer von diesem Zeitpunkt an die vorliegenden AGB sowie die Besonderen Bedingungen, die gegebenenfalls die AGB in Bezug auf bestimmte Dienste und Inhalte der APP ergänzen, ändern oder ersetzen, in vollem Umfang und ohne Vorbehalte akzeptiert und verpflichtet ist, die von CCOMMS aufgestellten Nutzungsbeschränkungen dieser Seite jederzeit einzuhalten.',
    termsConditionsAccesoAPPTitulo: 'III.- ZUGANG ZUR APP',
    termsConditionsAccesoAPPParrafo1: 'Der Zugang und die Nutzung der APP sind beschränkt, und der Nutzer muss sich zunächst registrieren, um darauf zugreifen zu können.',
    termsConditionsUsoAPPTitulo: 'IV.- NUTZUNG DER APP',
    termsConditionsUsoAPPParrafo1: 'Der Nutzer verpflichtet sich, die APP, ihre Dienste und Inhalte ohne Verstoß gegen geltendes Recht, Vertrauen, allgemein anerkannten Gebrauch und die öffentliche Ordnung zu nutzen. Ebenso ist es verboten, die APP für unerlaubte oder schädliche Zwecke gegen CCOMMS oder Dritte zu verwenden oder in irgendeiner Weise Schäden zu verursachen oder den normalen Betrieb der APP zu beeinträchtigen.',
    termsConditionsResposabilidadTitulo: 'V-. HAFTUNG DES NUTZERS FÜR SCHÄDEN',
    termsConditionsResposabilidadParrafo1: 'Die Nutzung der APP liegt in der alleinigen und ausschließlichen Verantwortung des Nutzers. Diese Haftung bezieht sich auch auf Passwörter oder ähnliche Daten, die an den Nutzer oder an Dritte für den Zugang zur APP oder zu ihren Diensten vergeben wurden.',
    termsConditionsResposabilidadParrafo2: 'Unter Vorbehalt dessen behält sich CCOMMS das Recht vor, jederzeit und ohne Vorankündigung den Zugang zur APP oder den eingeschränkten Zugang für diejenigen Nutzer zu verweigern oder zu sperren, die gegen die vorliegenden AGB, die von CCOMMS oder seinen Partnern aufgestellten Regeln verstoßen oder das ordnungsgemäße Funktionieren, das Image, die Glaubwürdigkeit und/oder das Ansehen von CCOMMS oder seinen Partnern schädigen könnten.',
    termsConditionsResposabilidadParrafo3: 'In diesem Sinne behält sich CCOMMS das Recht vor, bestimmten Nutzern den Zugang zur APP zu verweigern oder einzuschränken, wenn sie ein Verhalten an den Tag legen, das nach Ansicht von CCOMMS gegen die vorliegenden Nutzungsbedingungen, das Gesetz, die von CCOMMS oder seinen Partnern aufgestellten Regeln verstößt oder das ordnungsgemäße Funktionieren, das Image, die Glaubwürdigkeit und/oder das Ansehen von CCOMMS oder seinen Mitarbeitern schädigen könnte. Ebenso kann CCOMMS die Benutzer für Schäden haftbar machen, die durch die missbräuchliche Nutzung der APP entstehen.',
    termsConditionsResposabilidadParrafo4: 'CCOMMS übernimmt keine Garantie für die Zulässigkeit, die Zuverlässigkeit, den Nutzen, den Wahrheitsgehalt oder die Genauigkeit der Inhalte, die der Nutzer bei der Nutzung der APP übermittelt.',
    termsConditionsResposabilidadParrafo5: 'CCOMMS hat keine Kontrolle über die Nutzung der APP durch die Nutzer und garantiert nicht, dass diese im Einklang mit den vorliegenden Allgemeinen Nutzungsbedingungen erfolgt.',
    termsConditionsResposabilidadParrafo6: 'CCOMMS hat keine Kontrolle oder Garantie bezüglich der Abwesenheit von Computerviren in den von Dritten über die APP bereitgestellten Diensten, die Veränderungen in Ihrem Computersystem (Software und Hardware) oder in den elektronischen Dokumenten und Dateien, die in Ihrem Computersystem gespeichert sind, verursachen können.',
    termsConditionsPropiedadIntelectualTitulo: 'VI.- INTELLEKTUELLES EIGENTUM',
    termsConditionsPropiedadIntelectualParrafo1: 'Jegliche Nutzung oder Änderung des Materials oder des Inhalts zu einem anderen als dem in den Allgemeinen Geschäftsbedingungen genehmigten Zweck wird als Verletzung der internationalen Urheberrechtsgesetze zum Schutz der Urheberrechte betrachtet.',
    termsConditionsPropiedadIntelectualParrafo2: 'Verbote hinsichtlich des Inhalts:',
    termsConditionsPropiedadIntelectualListaELemento1: 'Seine Vervielfältigung, Kopie, Verbreitung, Änderung oder Manipulation in jeglicher Form und zu jeglichem Zweck, es sei denn, es liegt eine vorherige schriftliche Genehmigung der rechtmäßigen Eigentümer vor oder es ist gesetzlich zulässig.',
    termsConditionsPropiedadIntelectualListaELemento2: 'Jegliche Verletzung der Rechte von CCOMMS oder der rechtmäßigen Eigentümer.',
    termsConditionsPropiedadIntelectualListaELemento3: 'Seine Verwendung zu jeglichen Werbe- oder kommerziellen Zwecken, sollten diese von den ausdrücklich erlaubten Zwecken abweichen.',
    termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garantiert, dass die Inhalte, einschließlich des intellektuellen Eigentums, nicht rechtswidrig sind und nicht gegen geltendes Recht verstoßen. Folglich darf der Inhalt der APP nicht fremdenfeindlich, pornografisch, diskriminierend, rassistisch oder in irgendeiner Weise gewaltfördernd sein.',
    termsConditionsPropiedadIntelectualParrafo4: 'Darüber hinaus wird CCOMMS alle rechtlichen Maßnahmen ergreifen, die es für angemessen hält, um ungesetzliches oder unmoralisches Verhalten zu verhindern.',
    termsConditionsModificacionUnilateralTitulo: 'VII.- EINSEITIGE ÄNDERUNG',
    termsConditionsModificacionUnilateralParrafo1: 'CCOMMS kann die Struktur und das Design der APP einseitig und ohne vorherige Ankündigung ändern, wann immer dies für angemessen gehalten wird, sowie Dienste, Inhalte, Zugangs- und/oder Nutzungsbedingungen der APP ändern oder abschaffen.',
    termsConditionsExclusionGarantiasTitulo: 'VIII.- AUSSCHLUSS VON GARANTIE UND HAFTUNG',
    termsConditionsExclusionGarantiasParrafo1: 'CCOMMS übernimmt keine Garantie und ist in keinem Fall verantwortlich für Schäden jeglicher Art, die durch Folgendes verursacht wurden:',
    termsConditionsExclusionGarantiasListaELemento1: 'Die Nichteinhaltung der Gesetze, der Moral und der guten Sitten sowie der öffentlichen Ordnung oder die Nutzung der angebotenen Produkte und/oder Dienstleistungen auf nicht sorgsame oder falsche Weise oder für Zwecke oder Wirkungen, die rechtswidrig sind oder im Widerspruch zu den Bestimmungen der vorliegenden AGB oder den gegebenenfalls geltenden Bedingungen stehen.',
    termsConditionsExclusionGarantiasListaELemento2: 'Für den Erwerb oder Abschluss von Dienstleistungen, Waren oder Produkten, die von Dritten über einen Zugang in der APP von CCOMMS erworben oder abgeschlossen werden können, insbesondere in den Fällen, in denen der Kauf- oder Vertragsabschlussprozess direkt in der App des Dritten erfolgt und auch wenn sie unverwechselbar oder ein „Frame“ mit den grafischen Elementen der App erscheinen (Branding).',
    termsConditionsExclusionGarantiasListaELemento3: 'Fehlende Verfügbarkeit, Wartung und effektives Funktionieren der APP und/oder ihrer Dienste oder Inhalte.',
    termsConditionsExclusionGarantiasListaELemento4: 'Mangelnder Nutzen, Angemessenheit oder Gültigkeit der APP und/oder ihrer Dienste oder Inhalte für die Erfüllung der Bedürfnisse, Aktivitäten oder spezifischen Ergebnisse oder Erwartungen der Nutzer.',
    termsConditionsExclusionGarantiasListaELemento5: 'Existenz von Viren, bösartigen oder schädlichen Programmen in Inhalten.',
    termsConditionsExclusionGarantiasListaELemento6: 'Entgegennahme, Einholung, Speicherung, Verbreitung oder Übermittlung von Inhalten durch Nutzer.',
    termsConditionsExclusionGarantiasListaELemento7: 'Die Rechtswidrigkeit oder mangelnde Richtigkeit, Genauigkeit, Zugehörigkeit, Aktualität und Vollständigkeit der Inhalte und Informationen, die den Nutzern übermittelt oder zur Verfügung gestellt werden, einschließlich der von Dritten oder von Nutzern über die APP bereitgestellten Informationen und Dienste.',
    termsConditionsExclusionGarantiasListaELemento8: 'Nichteinhaltung der Verpflichtungen oder Zusagen Dritter im Zusammenhang mit den Diensten, die den Nutzern über die APP geboten werden.',
    termsConditionsExclusionGarantiasListaELemento9: 'Von Inhalten, Dateien, Informationen, Werbung, Meinungen, Konzepten und Bildern, die weder von der APP abhängen, noch von CCOMMS verwaltet werden. Gehaftet wird auch nicht für den Missbrauch der Inhalte der APP, für den allein derjenige verantwortlich ist, der auf sie zugreift oder sie nutzt.',
    termsConditionsExclusionGarantiasListaELemento10: 'Die missbräuchliche Verwendung der Zugangscodes der verantwortlichen Benutzer für den Zugang zu den Inhalten des APP-Dienstes und die Folgen jeglicher Art, die sich aus der missbräuchlichen Verwendung durch die Benutzer, ihrem Verlust oder Vergessen und ihrer missbräuchlichen Verwendung durch unbefugte Dritte ergeben.',
    termsConditionsExclusionGarantiasListaELemento11: 'Die Unfähigkeit eines Benutzers oder Identitätsbetrug von Dritten durch den Benutzer.',
    termsConditionsExclusionGarantiasListaELemento12: 'Von Inhalten, die von den Benutzern bereitgestellt werden.',
    termsConditionsProteccionDatosTitulo: 'IX.-  DATENSCHUTZ',
    termsConditionsProteccionDatosParrafo1: 'Ausführliche Informationen zum Datenschutz finden Sie auf unserer Website unter',
    termsConditionsLeyJurisdiccionTitulo: 'X.- ANWENDBARES RECHT UND GERICHTSBARKEIT',
    termsConditionsLeyJurisdiccionParrafo1: 'Alle in dieser APP enthaltenen Allgemeinen und Besonderen Bedingungen unterliegen dem spanischen Recht. Alle Streitigkeiten, Kontroversen oder Situationen, die sich aus den Allgemeinen und Besonderen Bedingungen ergeben, werden den Gerichten der Stadt Madrid vorgelegt, und die Parteien verzichten ausdrücklich auf ihre eigene Zuständigkeit.'
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  cookies: {
    cookiesPoliciesCompliance: 'Cookie-Richtlinien',
    cookiesPoliciesIntroText: 'Diese Website verwendet Cookies, um Ihre Erfahrung beim Surfen auf der Website zu verbessern. Sie können diese Einstellungen jederzeit ändern. Als notwendig eingestufte Cookies werden in Ihrem Browser gespeichert und sind wesentlich für den Betrieb der Grundfunktionen der Website. Cookies von Dritten werden nur mit Ihrer Zustimmung in Ihrem Browser gespeichert und helfen uns zu analysieren und zu verstehen, wie diese Website genutzt wird. Sie haben die Möglichkeit, Cookies abzulehnen, dies kann jedoch dazu führen, dass einige Funktionen nicht mehr verfügbar sind. Informationen zum Löschen von Cookies entnehmen Sie bitte der Hilfefunktion Ihres Browsers.',
    cookiesPoliciesNecessaryCookies: 'Notwendige Cookies',
    cookiesPoliciesNecessaryCookiesText: 'Dieser Cookie-Typ ist für das korrekte Funktionieren der Website absolut notwendig. Zu dieser Kategorie gehören nur Cookies, die grundlegende Funktionen und Sicherheitsmerkmale der Website garantieren und keine persönlichen Informationen über die Nutzer der Website erfassen oder speichern.',
    cookiesPoliciesUnnecessaryCookies: 'Nicht notwendige Cookies',
    cookiesPoliciesUnnecessaryCookiesText: 'Cookies, die für das Funktionieren der Website nicht unbedingt erforderlich sind und speziell für die Erfassung persönlicher Daten des Benutzers durch Analysen, Werbung und andere eingebettete Inhalte verwendet werden, werden als nicht erforderliche Cookies bezeichnet. Es ist notwendig, die Zustimmung des Benutzers vor der Ausübung der Cookies auf Ihrer Website einzuholen.',
    cookiesPoliciesGoogleAnalyticsCookies: 'Google Analytics-Cookies',
    cookiesPoliciesGoogleAnalyticsCookiesText: 'Weitere Informationen über diese Art von Cookies finden Sie in dem Dokument „Verwendung von Google Analytics-Cookies auf Websites“',
    cookiesPoliciesGoogleAnalyticsCheckboxText: 'Cookies aktiviert lassen',
    cookiesNotificationBarText: 'Wir verwenden Cookies, sowohl eigene als auch Cookies von Dritten, um Besuche zu messen, die Funktionalität der Website zu gewährleisten und das Surfen auf der Website zu analysieren. Im Folgenden können Sie Cookies akzeptieren oder die Verwendung von Cookies einstellen/ablehnen. Weitere Informationen über die Inhaberschaft der Website, verantwortlich für die Verarbeitung von Cookies, und deren Kontaktdaten finden Sie in unserer',
    cookiesNotificationBarConfig: 'Einstellungen',
    cookiesPoliciesModalIntro: 'Einleitung',
    cookiesPoliciesModalIntroP1: 'Diese Website verwendet Cookies und/oder ähnliche Technologien, die beim Surfen Informationen speichern und einholen, um das Benutzererlebnis zu verbessern und zu optimieren.',
    cookiesPoliciesModalIntroP2: 'Im Allgemeinen können diese Technologien einer Vielzahl von Zwecken dienen, wie z. B. der Erkennung des Benutzers, der Erfassung von Informationen über Ihre Surfgewohnheiten oder der Personalisierung der Darstellung von Inhalten.',
    cookiesPoliciesModalIntroP3: 'In Übereinstimmung mit dem Königlichen Gesetzesdekret 13/2012 und seiner Auswirkung auf Artikel 22.2 des Gesetzes über die Dienste der Informationsgesellschaft und den elektronischen Handel informiert Sie diese Richtlinie über die verschiedenen Cookies, die CUSTOMER COMMUNICATIONS TECKNALIA, S.L. über die verschiedenen Kommunikationsmittel oder -kanäle, eigene oder fremde, von der Informationspflicht befreite oder nicht befreite, sammelt, sowie über die verschiedenen Mittel, mit denen Sie diese ablehnen, zulassen oder blockieren können.',
    cookiesPoliciesModalIntroP4: 'Im Folgenden finden Sie ausführliche Informationen darüber, was „Cookies“ sind, welche Art diese Website verwendet, wie sie in einem Browser deaktiviert werden können und wie Sie die Installation von Cookies Dritter gezielt blockieren können.',
    cookiesPoliciesModalResponsibility: 'Wer ist für die Verwendung von Cookies auf dieser Website verantwortlich?',
    cookiesPoliciesModalResponsibilityP1: 'Verantwortlich für die Verwendung von Cookies ist CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (im Folgenden CCOMMS), mit der Steuernummer B-86414000 und Sitz in Leganés (Madrid), Avenida de la Recomba, 14, eingetragen im Handelsregister von Madrid in Band 29.801, Folio 98, Sektion 8ª, Blattnummer M-536210.',
    cookiesPoliciesModalResponsibilityP2: 'E-Mai-Adresse des Datenschutzbeauftragten.',
    cookiesPoliciesModalWhatIsCookie: 'Was ist ein Cookie?',
    cookiesPoliciesModalWhatIsCookieP1: 'Ein Cookie ist ein Wert, eine Datei oder eine Vorrichtung zur Speicherung und Abrufung von Daten, das ein Webbrowser automatisch auf dem Gerät eines Nutzers (Smartphone, Tablet oder angeschlossenes Fernsehgerät) speichert, wenn dieser auf Webseiten oder Konten in sozialen Netzwerken zugreift oder eine E-Mail erhält.',
    cookiesPoliciesModalWhatIsCookieP2: 'Cookies ermöglichen die Speicherung und den automatischen Abruf von Daten über die Nutzung der genannten digitalen Medien und der Geräte, von denen aus Sie auf sie zugreifen.',
    cookiesPoliciesModalWhatIsCookieP3: 'Bei einem Cookie handelt es sich lediglich um Daten, die auf dem Computer des Nutzers gespeichert werden, also nicht um Software, nicht um Code-Fragmente, sondern um reine Daten. Daher können Cookies im Prinzip keine Viren übertragen und ausführen oder Malware wie Trojaner oder Spyware installieren.',
    cookiesPoliciesModalCookieTypes: 'Welche Cookie-Typen gibt es auf der Website und mit welchem Zweck?',
    cookiesPoliciesModalCookiesInstall: 'Welche Cookies kann man auf der Zertifikat-Website installieren?',
    cookiesPoliciesModalCookiesInstallP1: 'Der Zugriff auf die Website zur Zertifikatsverifizierung setzt voraus, dass die in der folgenden Tabelle aufgeführten Cookies, eigene und/oder von Dritten, installiert werden können:',
    cookiesPoliciesModalBrowserConfigCookies: 'Wie Sie Cookies im Browser konfigurieren',
    cookiesPoliciesModalBrowserConfigCookiesP1: 'Sie haben in Ihren Browsereinstellungen die Möglichkeit zu bestimmen, keine Cookies zu erhalten oder über deren Festlegung informiert zu werden. Alle Cookies, mit Ausnahme von Flash-Cookies, können von Ihrem Browser aus verwaltet werden, normalerweise im Abschnitt „Optionen“ oder „Einstellungen“.',
    cookiesPoliciesModalBrowserConfigCookiesP2: 'Sie können diese ganz deaktivieren, in diesem Fall ließe Ihr Browser die Installation nicht zu (wodurch Sie nicht alle Funktionen der Website nutzen können), oder nur jene von bestimmten Websites, sodass Sie die erweiterten Funktionen von Websites nutzen können, die den Schutz Ihrer Daten respektieren, und diejenigen ausschließen, die dies nicht tun.',
    cookiesPoliciesModalBrowserConfigCookiesP3: 'Die folgenden Links bieten Zugang zu den wichtigsten Support-Seiten:',
    cookiesPoliciesModalBrowserConfigCookiesP4: 'Ebenso kann der Benutzer die Verwendung bestimmter Cookies mit Hilfe der folgenden Dienste ablehnen:',
    cookiesPoliciesModalBrowserConfigCookiesLI1: 'Blockierung von Cookies in Chrome',
    cookiesPoliciesModalBrowserConfigCookiesLI2: 'Blockierung von Cookies in Safari',
    cookiesPoliciesModalBrowserConfigCookiesLI3: 'Blockierung von Cookies in Firefox',
    cookiesPoliciesModalBrowserConfigCookiesLI4: 'Blockierung von Cookies in Internet Explorer',
    cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
    cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
    cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
    cookiesPoliciesModalUnavailableCookies: 'Was geschieht bei der Deaktivierung von Cookies?',
    cookiesPoliciesModalUnavailableCookiesP1: 'Wenn Sie Cookies ablehnen, können Sie weiterhin auf unserer Website surfen, auch wenn dies die Nutzung einiger Dienste einschränken und Ihre Erfahrung dadurch weniger zufriedenstellend sein kann.',
    cookiesPoliciesModalUnavailableCookiesP2: 'Die Ablehnung, Blockierung oder Nichtzulassung der in dieser Richtlinie beschriebenen Cookies kann das korrekte Browsen der auf der Website wiedergegebenen Inhalte beeinträchtigen.',
    cookiesPoliciesModalAcceptCookies: 'Bestätigung der Cookie-Richtlinien',
    cookiesPoliciesModalAcceptCookiesP1: 'Durch das Klicken des Feldes „Cookies akzeptieren“ wird angenommen, dass Sie mit der Nutzung von Cookies einverstanden sind.',
    cookiesPoliciesModalAcceptCookiesP2: 'Mit jedem Einloggen wird die Cookie-Richtlinie jedoch am unteren Rand jeder Seite der Website angezeigt, damit diese zur Kenntnis genommen werden kann.',
    cookiesPoliciesModalUpgradeCookies: 'Aktualisieren wir unsere Cookie-Richtlinien?',
    cookiesPoliciesModalUpgradeCookiesP1: 'Es ist möglich, dass die in diesen Cookie-Richtlinien enthaltenen Informationen aufgrund von Aktualisierungen oder Änderungen der Cookie-Behandlungspolitik geändert werden, ebenso wie die spanische Datenschutzbehörde Anweisungen und/oder Empfehlungen herausgeben kann, die sich von diesen unterscheiden können.',
    cookiesPoliciesModalUpgradeCookiesP2: 'Wir empfehlen Ihnen daher, diese Richtlinie jedes Mal durchzulesen, wenn Sie auf unsere Website zugreifen, um sich genau darüber zu informieren, wie und zu welchem Zweck die Cookies verwendet werden.',
    cookiesPoliciesModalRecipientCookies: 'Wer sind die Empfänger der Informationen und wie wirkt sich die Verwendung von Cookies auf den Schutz persönlicher Daten aus?',
    cookiesPoliciesModalRecipientCookiesP1: 'Weitere Informationen über die Verarbeitung Ihrer Daten finden Sie in den',
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Datenschutzrichtlinien',
    cookiesPoliciesModalContactInfoCookies: 'Kontaktinformationen',
    cookiesPoliciesModalContactInfoCookiesP1: 'Bei Fragen, Klärungen und/oder Vorschlägen zu Cookies, wenden Sie sich bitte per E-Mail an uns.',
    cookiesPoliciesModalDatatableCookieType: 'Cookie-Typ',
    cookiesPoliciesModalDatatableTreatmentTerm: 'Bearbeitungszeitraum',
    cookiesPoliciesModalDatatableTypeAnalytics: 'Analyse-Cookies',
    cookiesPoliciesModalDatatableTypeSelf: 'Eigene Cookies',
    cookiesPoliciesModalDatatableTypeAnalyticsD: 'Immer, wenn ein Benutzer einen Service in Anspruch nimmt, generiert ein Werkzeug eines externen Anbieters einen analytischen Cookie auf dem Benutzercomputer. Dieser Cookie, der nur beim Besuch generiert wird, dient CCOMMS bei zukünftigen Besuchen, um den Besucher anonym identifizieren zu können.',
    cookiesPoliciesModalDatatableTypeAnalyticsF: 'Es wird erlaubt, durch den „Cookie“ den Benutzer auf anonyme Weise zu identifizieren (identifiziert Browser und Geräte, nicht Personen) und damit eine ungefähre Zählung der Besucheranzahl und ihre Entwicklung im Laufe der Zeit. Anonyme Identifizierung der meistbesuchten und attraktivsten Inhalte für die Nutzer. Um zu wissen, ob der Benutzer, der auf die Website zugreift, ein neuer Besucher ist, oder bereits wiederholt besucht. Sofern der Nutzer nicht beschließt, sich bei CCOMMS zu registrieren, wird der „Cookie“ niemals mit persönlichen Daten verknüpft, die eine direkte Identifizierung des Nutzers ermöglichen. Besagte „Cookies“ werden nur zu statistischen Zwecken genutzt und zur Optimierung und Verbesserung der Benutzererfahrung auf der Website.',
    cookiesPoliciesModalDatatableTypeAnalyticsT: 'Permanente Cookies',
    cookiesPoliciesModalDatatableTypeSelfD: 'Dieser Cookie-Typ erlaubt es, die Informationen zu erweitern, die jedem anonymen Benutzer in den CCOMMS-Diensten angezeigt werden.',
    cookiesPoliciesModalDatatableTypeSelfF: 'Gespeichert werden unter anderem die Dauer oder Häufigkeit des Besuchs der verschiedenen Sektionen, die Interaktion mit ihnen oder die Browsing-Muster und/oder Verhaltensweisen des Benutzers.',
    cookiesPoliciesModalDatatableTypeSelfT: 'Permanente Cookies',
    description: 'Beschreibung',
    privacyPoliciesConditionsFinalidadTitle: 'Zweck',
    accept: 'Annehmen',
    reject: 'Ablehnen',
    save: 'Speichern',
    cookiesPoliciesCertySign: 'Cookies bei Zertifikatsverifizierung',
    cookiesNecessaryCookieDescription: 'Dieser Cookie ist notwendig und wird zur Einstellung der Cookie-Richtlinien des Benutzers verwendet.',
    cookiesGAMessage: 'Interne Messung mit Google Analytics. Diese Cookies speichern eine einzigartige Kennung zur Wiedererkennung des Benutzers bei künftigen Besuchen auf der Website.',
    cookiesGAFGenericMessage: 'Interne Messung mit Google Analytics.'
  },
  conditionsOfService: 'Geschäftsbedingungen',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "Schließen-Symbol",
    zoomTRID: "TRID vergrößern",
    zoomCSV: "CSV vergrößern",
    sampleCertificate: "Beispielzertifikat",
    downloadButton: "Download-Button",
    checkboxActiveUser: "Aktivierungskästchen",
    selectLanguage: "Sprache auswählen",
    openHelpDialog: "Support-Dialog öffnen",
    trid: "TRID eingeben",
    csv: "CSV eingeben",
    part1Container: "1 - Positionsanzeiger der Transaktions-Eindeutigen-Kennung (TRID) im Zertifikatsprofil",
    part2Container: "2 - Positionsanzeiger der Zertifikat-Eindeutigen-Kennung (CSV) im Beispielzertifikat",
    sampleTrid: "Eindeutige Transaktionskennung (TRID) im Beispielzertifikat",
    sampleCSV: "Eindeutige Zertifikatkennung (CSV) im Beispielzertifikat"
  }
};