import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./messages";

Vue.use(VueI18n);

let availableLocales = ["es", "en", "pt", "eu", "ca", "gl", "de", "fr"];
let browserLang =
    navigator.language.slice(0, 2) || navigator.language.slice("0,2");

if (availableLocales.indexOf(browserLang) !== -1) {
  browserLang = process.env.VUE_APP_I18N_LOCALE;
}

const i18n = new VueI18n({
  availableLocales,
  locale: browserLang || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  silentFallbackWarn: true,
  messages
});

export default i18n;
