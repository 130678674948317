export default {
  "app": {
    "header": {
      "language": "Lingua"
    }
  },
  "home": {
    "title": "Verifica online dei certificati",
    "trid": "Identificativo univoco delle transazioni (trid)",
    "csv": "Identificativo univoco del certificato (csv)",
    "description": "Compili i seguenti dati per verificare l'autenticità della dichiarazione di completamento o del certificato di cui dispone.",
    "subtitle": "CODICE DI VERIFICA",
    "validate": "Verifica",
    "resultError": "Controlli i dati inseriti",
    "messagesInputs": {
      "required": "Campo obbligatorio",
      "minimo": "Minimo 4 caratteri.",
      "maximo": "Minimo 1000 caratteri."
    }
  },
  "confirmation": {
    "title": "I suoi dati corrispondono alla seguente certificazione",
    "subtitle": "Può scaricare il certificato premendo sul pulsante inferiore e verificare che coincida con le informazioni di cui dispone.",
    "download": "Scarica",
    "back": "Indietro",
    "certificateDownloaded": "Il suo certificato è stato scaricato correttamente"
  },
  "helpDialog": {
    "messageSent": {
      "title": "Contatta l'assistenza",
      "subtitle": "È stata inviata una richiesta al nostro dipartimento di assistenza; un agente la contatterà il prima possibile.",
      "back": "Indietro"
    },
    "text": "Ha bisogno d'aiuto?",
    "contactWithSupport": "Contatta l'assistenza",
    "dialogPhrase": "Compili i dati e i nostri agenti del dipartimento di assistenza tecnica le risponderanno il prima possibile. Orari di assistenza nei giorni lavorativi:",
    "monFriHours": "<p class='body-2 font-weight-medium mb-7'><strong>Dal lunedì al venerdì</strong>: dalle ore 8:00 alle ore 17:00</p>",
    "message": "Messaggio",
    "sendRequest": "Invia richiesta",
    "requiredEmailFormat": "Il formato dell'e-mail è errato",
    "requiredEmail": "È necessario inserire l'e-mail",
    "requiredMessage": "È necessario inserire un messaggio",
    "requiredName": "È necessario inserire il nome",
    "email": "E-mail",
    "name": "Nome"
  },
  "termsAndConditions": {
    "title": "Termini e condizioni",
    "termsConditions1": "CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (di seguito CCOMMS) in qualità di titolare e responsabile della presente APP, in conformità a quanto previsto dall'articolo 10 della Legge 34/2002 dell'11 luglio sui Servizi della società dell'informazione e del commercio elettronico, mette a disposizione degli utenti le seguenti informazioni generali di questa APP:",
    "companyInfo": "<p class='mb-1'><strong>Titolare</strong> Customer Communications Tecknalia, S.L.</p><p class='mb-1'><strong>P.IVA</strong>: CB-86414000 </p><p class='mb-1'><strong>Indirizzo fiscale</strong>:  Avenida de la Recomba, 14.28914 Leganés (Madrid)</p><p class='mb-1'><strong>Dati registro</strong>: Iscritta al registro delle imprese di Madrid, Volume 29.801, Foglio 98, Sezione 8ª, Pagina n. M-536210.</p><p class='mb-1'><strong>Contatto: Telefono</strong>: 916 895 665</p><p class='mb-1'><strong>E-mail</strong>: <a  class='text-decoration-none' href='mailto:info@customercomms.com' title='v'>info@customercomms.com</a></p><p class='mb-1'><strong>E-mail del Delegato della Protezione dei dati</strong>: <a href='mailto:dpo@mailteck.com'        class='text-decoration-none' title='Delegato della Protezione dei dati di MailTeck'>dpo@mailteck.com</a></p>",
    "termsConditionsObjetoTitulo": "I.-OGGETTO",
    "termsConditionsObjetoParrafo1": "Le presenti Condizioni Generali d'uso (di seguito “CGU”) regolano l'uso dell'Applicazione Verifica del certificato (di seguito, l'APP) messa a disposizione degli utenti da CCOMMS al fine di poter eseguire la richiesta inoltrata.",
    "termsConditionsObjetoParrafo2": "Pertanto, le presenti CGU si applicheranno all'attività promozionale, alle informazioni e ai servizi prestati attraverso l'App; regolano, quindi, sia la semplice navigazione all'interno dell'APP che l'eventuale contrattazione di servizi in essa proposti, fermo restando che quest'ultima dovrà inoltre essere conforme sia alle CGU descritte di seguito che alle Condizioni Generali di Contrattazione applicabili e alle Condizioni Specifiche, se del caso.",
    "termsConditionsUsuariosTitulo": "II.-UTENTI",
    "termsConditionsUsuariosParrafo1": "L'accesso e/o l'uso dell'APP attribuisce a chi lo effettua la condizione di utente che, da quel momento, accetta pienamente e senza riserve le presenti CGU, le Condizioni Specifiche che, se del caso, completano, modificano o sostituiscono le CGU in relazione a determinati servizi e contenuti dell'APP, e si impegna a rispettare le restrizioni d'uso dell'APP stabilite da CCOMMS.",
    "termsConditionsAccesoAPPTitulo": "III.-ACCESSO ALL'APP",
    "termsConditionsAccesoAPPParrafo1": "L'accesso e l'uso dell'APP sono riservati. Per accedervi sarà necessaria la registrazione dell'utente.",
    "termsConditionsUsoAPPTitulo": "IV.-USO DELL'APP",
    "termsConditionsUsoAPPParrafo1": "L'utente si impegna ad utilizzare l'APP, i suoi servizi e contenuti in buona fede, nel rispetto della legislazione vigente, dell'ordine pubblico e degli usi generalmente accettati.  Analogamente, è severamente proibito l'uso dell'APP con finalità illecite o lesive nei confronti di CCOMMS o di terzi ovvero che possano in qualsiasi modo danneggiare o impedire il normale funzionamento dell'APP.",
    "termsConditionsResposabilidadTitulo": "V-. RESPONSABILITÀ DELL'UTENTE PER EVENTUALI DANNI O PERDITE",
    "termsConditionsResposabilidadParrafo1": "L'utente è l'unico responsabile dell'uso dell'APP. Tale responsabilità si estenderà all'uso di qualsiasi password o codice assegnato per accedere all'APP o a uno qualsiasi dei servizi in essa inclusi da parte dell'utente o di terze parti.",
    "termsConditionsResposabilidadParrafo2": "Fermo restando quanto sopra, CCOMMS si riserva il diritto di negare, inabilitare o limitare in qualsiasi momento e senza previo avviso l'accesso all'APP agli utenti che non rispettino le presenti CGU, le norme stabilite da CCOMMS o dai suoi collaboratori o che possano alterare il corretto funzionamento, l'immagine, la credibilità e/o il prestigio di CCOMMS o dei suoi collaboratori.",
    "termsConditionsResposabilidadParrafo3": "In tal senso, CCOMMS si riserva la facoltà di eliminare o limitare l'accesso all'APP ad alcuni Utenti, qualora si osservassero comportamenti che CCOMMS consideri contrari alle presenti Condizioni d'uso, alla Legge, alle norme stabilite da CCOMMS o dai suoi collaboratori ovvero che possano alterare il corretto funzionamento, l'immagine, la credibilità e/o il prestigio di CCOMMS o dei suoi collaboratori. Inoltre, CCOMMS potrà esigere che gli Utenti siano ritenuti responsabili per eventuali danni causati da un cattivo uso dell'APP.",
    "termsConditionsResposabilidadParrafo4": "CCOMMS non garantisce la legittimità, affidabilità e utilità, nonché veracità o esattezza, dei contenuti trasmessi dagli utenti quando utilizzano l'APP.",
    "termsConditionsResposabilidadParrafo5": "CCOMMS non controlla l'utilizzo dell'APP da parte degli utenti, né garantisce che ne facciano un uso conforme alle presenti Condizioni Generali.",
    "termsConditionsResposabilidadParrafo6": "CCOMMS non controlla né garantisce l'assenza di virus informatici nei servizi prestati da terzi attraverso l'APP che possano produrre alterazioni nel sistema informatico (software e hardware) nonché nei documenti e nei file elettronici salvati sul suo sistema informatico.",
    "termsConditionsPropiedadIntelectualTitulo": "VI.- PROPRIETÀ INTELLETTUALE",
    "termsConditionsPropiedadIntelectualParrafo1": "Qualsiasi uso o modifica del Materiale o dei Contenuti per finalità diverse da quelle autorizzate nelle Condizioni Generali sarà considerato una violazione delle leggi internazionali di “copyright” che proteggono i diritti d'autore.",
    "termsConditionsPropiedadIntelectualParrafo2": "Relativamente ai contenuti, ne è proibita:",
    "termsConditionsPropiedadIntelectualListaELemento1": "la riproduzione, copia, distribuzione, modifica o manipolazione, in qualsiasi modo e per qualsiasi finalità, salvo che sia legalmente concessa o che si disponga di autorizzazione preventiva e per iscritto da parte dei legittimi proprietari.",
    "termsConditionsPropiedadIntelectualListaELemento2": "Qualsiasi violazione dei diritti di CCOMMS o dei legittimi titolari.",
    "termsConditionsPropiedadIntelectualListaELemento3": "L'utilizzo dei contenuti per finalità commerciali o pubblicitarie diverse da quelle strettamente permesse.",
    "termsConditionsPropiedadIntelectualParrafo3": "CCOMMS garantisce che i contenuti, inclusi quelli di proprietà intellettuale, non sono di carattere illecito né violano la normativa vigente. Pertanto, i contenuti dell'APP non saranno di carattere xenofobo, pornografico, discriminatorio, razzista né fomenteranno in alcun modo la violenza.",
    "termsConditionsPropiedadIntelectualParrafo4": "Inoltre, CCOMMS adotterà le misure legali che ritiene opportune per prevenire qualsiasi tipo di comportamento illegale o contrario alla Legge.",
    "termsConditionsModificacionUnilateralTitulo": "VII.- MODIFICA UNILATERALE",
    "termsConditionsModificacionUnilateralParrafo1": "Se lo ritiene opportuno, CCOMMS potrà modificare unilateralmente e senza preavviso la struttura e il progetto dell'APP, nonché modificare o eliminare i servizi, i contenuti e le condizioni di accesso e/o uso dell'APP.",
    "termsConditionsExclusionGarantiasTitulo": "VIII.-ESCLUSIONE DELLE GARANZIE E RESPONSABILITÀ",
    "termsConditionsExclusionGarantiasParrafo1": "CCOMMS non concede garanzie né si assume la responsabilità in nessun caso per danni di qualsivoglia natura che possono derivare dalle seguenti circostanze:",
    "termsConditionsExclusionGarantiasListaELemento1": "violazione della Legge, degli usi, dell'ordine pubblico e del buon costume; uso negligente o incorretto dei prodotti e/o servizi offerti o con finalità illecite o contrarie a quanto previsto dalle presenti CGU o dalle condizioni applicabili, se del caso.",
    "termsConditionsExclusionGarantiasListaELemento2": "Acquisto o richiesta di servizi, beni e prodotti da terzi tramite l'accesso all'APP di CCOMMS, soprattutto quando il processo di acquisto o richiesta del servizio viene effettuato direttamente nell'app dei suddetti terzi, anche se vengono utilizzati marchi o “frame” con elementi grafici specifici dell'app (Branding).",
    "termsConditionsExclusionGarantiasListaELemento3": "Mancata disponibilità, manutenzione ed effettivo funzionamento dell'APP e/o dei suoi servizi e contenuti.",
    "termsConditionsExclusionGarantiasListaELemento4": "Mancata utilità, adeguamento o validità dell'APP e/o dei suoi servizi o contenuti per soddisfare le esigenze, le attività o i risultati specifici e le aspettative degli utenti.",
    "termsConditionsExclusionGarantiasListaELemento5": "Esistenza di virus, malware o programmi lesivi.",
    "termsConditionsExclusionGarantiasListaELemento6": "Ricezione, ottenimento, conservazione, diffusione o trasmissione dei contenuti da parte degli utenti.",
    "termsConditionsExclusionGarantiasListaELemento7": "Illegalità o la mancanza di veracità, accuratezza, affidabilità, pertinenza, tempestività e completezza del contenuto e delle informazioni trasmesse o rese disponibili agli utenti, comprese le informazioni e i servizi forniti da terzi o da utenti attraverso l'APP.",
    "termsConditionsExclusionGarantiasListaELemento8": "Mancato rispetto da parte di terzi dei propri obblighi e impegni in relazione ai servizi forniti agli utenti attraverso l'APP.",
    "termsConditionsExclusionGarantiasListaELemento9": "Contenuti, file, informazioni, pubblicità, opinioni, concetti e immagini che non dipendono dall'APP, né sono gestiti da CCOMMS. Altresì si solleva da ogni responsabilità per il cattivo uso dei contenuti all'interno dell'APP, essendo quest'ultimo una responsabilità esclusiva delle persone che accedono o utilizzano tali contenuti.",
    "termsConditionsExclusionGarantiasListaELemento10": "Né è responsabile dell'uso scorretto delle password degli utenti per accedere ai contenuti del servizio dell'APP richiesto né delle conseguenze di qualsiasi natura derivanti da un cattivo uso da parte degli utenti, dalla perdita o smarrimento di suddette password nonché dell'uso indebito da parte di terzi non autorizzati.",
    "termsConditionsExclusionGarantiasListaELemento11": "Incapacità di qualsiasi utente o il soppiantamento della personalità di una terza parte creato dall'utente.",
    "termsConditionsExclusionGarantiasListaELemento12": "Contenuti forniti dagli utenti.",
    "termsConditionsProteccionDatosTitulo": "IX.- PROTEZIONE DATI",
    "termsConditionsProteccionDatosParrafo1": "È possibile consultare le informazioni dettagliate sulla Protezione dei Dati sul nostro sito web ",
    "termsConditionsLeyJurisdiccionTitulo": "X.- NORMATIVA APPLICABILE E GIURISDIZIONE",
    "termsConditionsLeyJurisdiccionParrafo1": "Tutte le Condizioni, Generali e Specifiche, contenute nella presente APP sono disciplinate dalla normativa spagnola. Eventuali conflitti, controversie o situazioni derivanti dalle Condizioni Generali e specifiche saranno di competenza dei Tribunali della città di Madrid, e le parti rinunciano a qualsiasi giurisdizione che possa competere loro."
  },
  privacyPolicy: {
    privacyPoliciesConditionsTitle: "Privacy policy",
    privacyPolicieModalTitle: "PRIVACY POLICY",
    privacyPolicieModalLargeTitle: "Privacy policy",
    privacyPoliciesConditionsParrafo1:
        "In accordance with the provisions of both Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (RGPD), and the Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights (LOPD-GDD) and other data protection regulations in force, the users of the website, who have voluntarily expressed their consent in a free, clear and unequivocal manner for the processing of personal data by accepting the “Data Protection Policy” box, are informed that their data will be processed, depending on the company with which they have contacted, either the company MAILTECK, S.A. or the company CUSTOMER COMMUNICATIONS TECKNALIA, S.L., which will be jointly referred to as MAILCOMMS GROUP within this Privacy Policy.",
    privacyPoliciesConditionsResponsable1Title: "Responsible 1",
    privacyPoliciesConditionsResponsable2Title: "Responsible 2",
    privacyPoliciesConditionsFinalidadTitle: "Purpose",
    privacyPoliciesConditionsLegitimacionTitle: "Legitimation",
    privacyPoliciesConditionsDestinatariosTitle: "Target audience",
    privacyPoliciesConditionsDerechosTitle: "Rights",
    privacyPoliciesConditionsInformacionAdicionalTitle: "Additional information",
    privacyPoliciesConditionsPoliticaInformacion1Title:
        "Information Policy",
    privacyPoliciesConditionsIdentidadTitle: "Identity",
    privacyPoliciesConditionsNIFTitle: "NIF",
    privacyPoliciesConditionsDireccionTitle: "Address",
    privacyPoliciesConditionsTelefonoTitle: "Phone",
    privacyPoliciesConditionsCorreoTitle: "Email",
    privacyPoliciesConditionsDPDTitle: "Contact with DPO",
    privacyPoliciesConditionsFinalidadIntro:
        "To provide requested information and to manage contact with potential customers.",
    privacyPoliciesConditionsFinalidadParrafo1:
        "In MAILCOMMS GROUP, the information you have provided has been collected by one of the companies of MAILCOMMS GROUP, in order to manage and process the request made.",
    privacyPoliciesConditionsFinalidadParrafo2:
        "GRUPO MAILCOMMS, will only process the data provided for the purposes described above and will not be further processed in a manner incompatible with those purposes.",
    privacyPoliciesConditionsFinalidadParrafo3:
        "In order to be able to offer you products and services according to your interests and improve your user experience, we will create a “commercial profile” based on the information provided. However, no automated decisions will be made on the basis of such a profile.",
    privacyPoliciesConditionsFinalidadParrafo4:
        "The personal data provided will be kept for as long as the business relationship is maintained or their deletion is not requested by the interested party.",
    privacyPoliciesConditionsLegitimacionIntro: "Consent of the interested party.",
    privacyPoliciesConditionsLegitimacionParrafo1:
        "The interested party providing their own data through the channels that MAILCOMMS GROUP companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsDestinatariosIntro: "Collaborating companies",
    privacyPoliciesConditionsDestinatariosParrafo1:
        "<ul><li>- Collaborating and participated companies for commercial purposes.</li>" +
        "<li>- By legal requirement when required by law.</li></ul>",
    privacyPoliciesConditionsDerechosIntro:
        "You may exercise your rights in the manner and within the time limits provided by law.",
    privacyPoliciesConditionsDerechosElem1ListaTitulo: "General confirmation:",
    privacyPoliciesConditionsDerechosElem1ListaDescripcion:
        "Any person has the right to obtain confirmation as to whether GRUPO MAILCOMMS is processing personal data concerning them.",
    privacyPoliciesConditionsDerechosElem2ListaTitulo: "ARCO Rights:",
    privacyPoliciesConditionsDerechosElem2ListaDescripcion:
        "Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
    privacyPoliciesConditionsDerechosElem3ListaTitulo:
        "Limitation of processing:",
    privacyPoliciesConditionsDerechosElem3ListaDescripcion:
        "In certain circumstances, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims.",
    privacyPoliciesConditionsDerechosElem4ListaTitulo:
        "Opposition to processing:",
    privacyPoliciesConditionsDerechosElem4ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may object to the processing of their data.\n" +
        "Therefore, GRUPO MAILCOMMS will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    privacyPoliciesConditionsDerechosElem5ListaTitulo:
        "Data portability:",
    privacyPoliciesConditionsDerechosElem5ListaDescripcion:
        "In certain circumstances and for reasons related to their particular situation, data subjects may request to receive the personal data provided in the contact form in a structured, commonly used and machine-readable format, as well as to transmit such data to another data controller without hindrance.",
    privacyPoliciesConditionsDerechosElem6ListaTitulo:
        "Claim before a public body:",
    privacyPoliciesConditionsDerechosElem6ListaDescripcion:
        "If you consider that your right to personal data protection has been violated, you may file a claim before the Spanish Data Protection Agency (www.aepd.es).",
    privacyPoliciesConditionsDerechosElem7ListaTitulo:
        "Channel to exercise the rights:",
    privacyPoliciesConditionsDerechosElem7ListaDescripcion:
        'Interested parties may exercise the rights recognized in the regulations against, and against, each of the responsible parties, before the addresses enabled as DPO in the “Responsible” section.',
    privacyPoliciesConditionsInformacionAdicionalIntro:
        "More information about Data Protection.",
    privacyPoliciesConditionsInformacionAdicionalLista1:
        "Personal data have been obtained from the channels that CCMS companies provide for the collection of contact data (contact form, commercial visit, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista2:
        "The categories of data to be processed are identification data (name, surname, e-mail, etc.).",
    privacyPoliciesConditionsInformacionAdicionalLista3:
        "CCMS does not process specially protected data.",
    privacyPoliciesConditionsPoliticaInformacionIntro:
        "MailComms Group, you can consult the Information Security and Privacy Policy based on the ISO 27001 standard.",
    privacyPoliciesConditionsPoliticaInformacionDate:"Version: 7 | Date: October 26, 2022",
    privacyPoliciesConditionsPoliticaInformacionTituloH:
        "ANNEX I: INFORMATION SECURITY AND PRIVACY MANAGEMENT SYSTEM POLICY",
    privacyPoliciesConditionsPoliticaInformacionP1:
        "This Information Security, Continuity and Privacy Policy shows the commitment of the Management by providing the organization with resources, responsibilities and procedures to ensure compliance with current regulations, as well as the integrity, confidentiality and availability of information and systems, which is crucial for the security, privacy and continuity of our organization, as well as that of our customers.",
    privacyPoliciesConditionsPoliticaInformacionP2:
        "MailComms Group, aware that the security, continuity and privacy of information relating to our customers is a resource with great value, has established an Information Security, Continuity and Privacy Management System in accordance with the requirements of ISO/IEC 27001:2013, ISO/IEC 27701:2019 and ISO 22301 standards to guarantee the continuity of information systems, minimize the risks of damage and ensure compliance with the objectives set.",
    privacyPoliciesConditionsPoliticaInformacionP3:
        "Additionally, for the Certified Electronic Delivery service, based on the nature of the service provided, the provisions established in the European eIDAS Regulation governing Trusted Services have been followed, and specifically the ETSI 319 401 and ETSI 319 521 standards for Certified Electronic Delivery Service Providers, which contain specific precepts for the provision of this type of service. In this sense, the Policy that will govern this service will be the Certification Practices Statement (hereinafter CPD).",
    privacyPoliciesConditionsPoliticaInformacionP4:
        "The objective of the Security, Continuity and Privacy Policy is to establish the necessary action framework to protect information resources against threats, internal or external, deliberate or accidental, in order to ensure compliance with the confidentiality, integrity and availability of information.",
    privacyPoliciesConditionsPoliticaInformacionP5:
        "The effectiveness and implementation of the Information Security, Continuity and Privacy Management System is the direct responsibility of the Information Security and Privacy Committee, which is responsible for the approval, dissemination and compliance with this Security, Continuity and Privacy Policy. In its name and on its behalf, an Information Security and Privacy Management System Manager has been appointed, who has sufficient authority to play an active role in the Information Security and Privacy Management System, supervising its implementation, development and maintenance.",
    privacyPoliciesConditionsPoliticaInformacionP6:
        "In addition, and in order to comply with current data protection regulations, a Data Protection Officer (DPO) has been appointed to guarantee the security and privacy of personal data, especially taking into account the company’s activity.",
    privacyPoliciesConditionsPoliticaInformacionP7:
        "The Information Security and Privacy Committee shall develop and approve the risk analysis methodology used in the Information Security, Continuity and Privacy Management System.",
    privacyPoliciesConditionsPoliticaInformacionP8:
        "Any person whose activity may, directly or indirectly, be affected by the requirements of the Information Security, Continuity and Privacy Management System, is obliged to strictly comply with the Security, Continuity and Privacy Policy and additionally, for the Certified Electronic Delivery Service, must comply with the CPD.",
    privacyPoliciesConditionsPoliticaInformacionP9:
        "The MailComms Group will implement all necessary measures to comply with applicable regulations on security, continuity and privacy in general and computer security, relating to IT policy, the security of buildings and facilities and the behavior of employees and third parties associated with MailComms Group in the use of computer systems and the processing of personal data. The measures necessary to guarantee the security and privacy of information through the application of standards, procedures and controls shall ensure the confidentiality, integrity and availability of the information, essential to:",
    privacyPoliciesConditionsPoliticaInformacionLista1:
        "Comply with current legislation, regulations and standards on continuity, privacy and information systems and with all contractual requirements regarding data protection and trusted electronic services, as well as those it deems appropriate to carry out with the aim of achieving continuous improvement of the organization and the security, continuity and privacy management system.",
    privacyPoliciesConditionsPoliticaInformacionLista2:
        "Ensure the confidentiality of the data managed by MailComms Group.",
    privacyPoliciesConditionsPoliticaInformacionLista3:
        "Ensure the availability of information systems, both in the services offered to customers and in internal management.",
    privacyPoliciesConditionsPoliticaInformacionLista4:
        "Ensure the capacity to respond to emergency situations, restoring the operation of critical services in the shortest possible time.",
    privacyPoliciesConditionsPoliticaInformacionLista5:
        "Avoid undue alterations in the information.",
    privacyPoliciesConditionsPoliticaInformacionLista6:
        "Promote awareness and training in information security, continuity, privacy and trustworthy e-services.",
    privacyPoliciesConditionsPoliticaInformacionLista7:
        "Promote and participate in achieving continuous improvement of the security management system, continuity and privacy of information and trustworthy electronic services.",
    privacyPoliciesConditionsPoliticaInformacionP10: "The Information Security and Privacy Policy and CPD should be reviewed at planned intervals, at least annually and whenever significant changes occur in the organization, to ensure that their suitability, adequacy and effectiveness are maintained."
  },
  "cookies": {
    "cookiesPoliciesCompliance": "Politica dei cookie",
    "cookiesPoliciesIntroText": "Questo sito web usa cookie per migliorare la sua esperienza durante la navigazione. È possibile cambiare queste impostazioni in qualsiasi momento. I cookie classificati come “necessari” verranno salvati sul suo browser e sono essenziali per il funzionamento basilare del sito web. I cookie di terzi verranno salvati sul suo browser solo dietro il suo consenso e ci aiutano ad analizzare e comprendere come viene usato il sito web. Può scegliere di non ricevere questi cookie. Ad ogni modo, l'esclusione volontaria di uno di questi cookie può comportare la mancata disponibilità di alcune funzionalità. Per ottenere maggiori informazioni su come eliminare i cookie, consulti la funzione di Aiuto del suo browser.",
    "cookiesPoliciesNecessaryCookies": "Cookie necessari",
    "cookiesPoliciesNecessaryCookiesText": "Si tratta di una tipologia di cookie essenziale per far funzionare correttamente il sito web. Questa categoria include solo i cookie che garantiscono le funzionalità basilari e le caratteristiche di sicurezza del sito web e non raccolgono né salvano alcuna informazione personale sugli utenti del sito web.",
    "cookiesPoliciesUnnecessaryCookies": "Cookie non necessari",
    "cookiesPoliciesUnnecessaryCookiesText": "Si definiscono cookie non necessari quelli che non sono particolarmente essenziali per il corretto funzionamento del sito web. Si usano in modo specifico per raccogliere dati personali dell'utente attraverso l'analisi, gli annunci e altri contenuti integrati. Prima di eseguire questi cookie su un sito web è necessario ottenere il consenso dell'utente.",
    "cookiesPoliciesGoogleAnalyticsCookies": "Cookie di Google Analytics",
    "cookiesPoliciesGoogleAnalyticsCookiesText": "Per maggiori informazioni su questa tipologia di cookie, consulti il documento “Uso dei cookie di Google Analytics sui siti web”",
    "cookiesPoliciesGoogleAnalyticsCheckboxText": "Mantenere cookie attivi",
    "cookiesNotificationBarText": "Utilizziamo cookie, sia nostri che di terze parti, per misurare le visite al nostro sito web, garantirne la funzionalità e analizzare la navigazione al suo interno. Di seguito potrà accettare o rifiutare i cookie o configurarne l'uso. Può accedere alle informazioni sulla titolarità del sito web, sul responsabile del trattamento dei cookie e sui dati di contatto su",
    "cookiesNotificationBarConfig": "Imposta",
    "cookiesPoliciesModalIntro": "Introduzione",
    "cookiesPoliciesModalIntroP1": "Il nostro sito web usa cookie e/o tecnologie simili a quelle che conservano e recuperano informazioni durante la navigazione per migliorare e ottimizzare l'esperienza dell'utente.",
    "cookiesPoliciesModalIntroP2": "In generale, queste tecnologie compiono finalità molto diverse, tra cui il riconoscimento dell'utente, l'ottenimento di informazioni sulle abitudini di navigazione, oppure personalizzano la visualizzazione del contenuto.",
    "cookiesPoliciesModalIntroP3": "In conformità al Decreto Regio Legge 13/2012 e alle modifiche nell'articolo 22.2 della Legge sui Servizi della Società delle informazioni e del commercio elettronico, con la presente Politica le indichiamo quali sono i diversi cookie che CUSTOMER COMMUNICATIONS TECKNALIA, S.L. raccoglie tramite i diversi canali di comunicazione, propri e di terze parti, esenti o meno dell'obbligo di informare, e i diversi mezzi attraverso i quali può rifiutarli, permetterli o bloccarli.",
    "cookiesPoliciesModalIntroP4": "Di seguito verranno fornite informazioni dettagliate su: cosa sono i “cookie”, quali tipologie di cookie sono usate sul nostro sito web, come possono essere disattivati in un browser e come bloccare in modo specifico l'installazione di cookie di terze parti.",
    "cookiesPoliciesModalResponsibility": "Chi è il responsabile dell'uso dei cookie in questo sito web?",
    "cookiesPoliciesModalResponsibilityP1": "Il responsabile dell'uso dei cookie è CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (di seguito CCOMMS), P.IVA B-86414000, sede fiscale a Leganés (Madrid) in Avenida de la Recomba, 14, azienda debitamente iscritta al Registro delle Imprese di Madrid, Volume 29.801, Foglio 98, Sezione 8ª, Pagina n. M-536210.",
    "cookiesPoliciesModalResponsibilityP2": "E-mail del Delegato della Protezione dei dati.",
    "cookiesPoliciesModalWhatIsCookie": "Cos'è un cookie?",
    "cookiesPoliciesModalWhatIsCookieP1": "Un cookie è un dato, un file o dispositivo di archiviazione e recupero dati (DARD) che un browser salva in modo automatico nel dispositivo (Smartphone, tablet o smart-tv) di un utente quando questi accede ai siti web, agli account dei canali social o quando riceve un'e-mail.",
    "cookiesPoliciesModalWhatIsCookieP2": "I cookie permettono di archiviare e recuperare in modo automatico i dati sull'uso effettuato nei suddetti canali digitali e sul computer o dispositivo da cui è stato effettuato l'accesso.",
    "cookiesPoliciesModalWhatIsCookieP3": "Un cookie si compone unicamente di dati archiviati nel computer di un utente, pertanto non si tratta di software né di frammenti di codice, ma semplicemente di dati. Pertanto, in linea di principio i cookie non possono trasmettere né eseguire virus, né installare malware come trojan o programmi di spionaggio.",
    "cookiesPoliciesModalCookieTypes": "Tipologie di cookie del sito web e finalità",
    "cookiesPoliciesModalCookiesInstall": "Quali sono i cookie che si possono installare sul sito web Verifica dei Certificati?",
    "cookiesPoliciesModalCookiesInstallP1": "L'accesso al sito web Verifica dei Certificati implica che si possono installare Cookie, propri e di terzi, dettagliati nel riquadro di seguito:",
    "cookiesPoliciesModalBrowserConfigCookies": "Come configurare i cookie nel browser",
    "cookiesPoliciesModalBrowserConfigCookiesP1": "Lei ha l'opzione di non ricevere cookie o di essere informato dei cookie configurati dalle impostazioni del suo browser, da cui è possibile gestirli nelle sezioni “Opzioni” o “Preferenze”, fatta eccezione per i cookie Flash.",
    "cookiesPoliciesModalBrowserConfigCookiesP2": "Può disattivarli tutti e in tal caso il browser non permetterà l'installazione di nessun cookie (bloccando alcune funzionalità del sito web) oppure solo quelli provenienti da specifici siti web. In tal caso potrà utilizzare tutte le funzionalità avanzate dei siti web che rispettano la privacy dei suoi dati ed escludere quelli che non lo fanno.",
    "cookiesPoliciesModalBrowserConfigCookiesP3": "Nei link di seguito le indichiamo gli accessi ai principali siti web di assistenza:",
    "cookiesPoliciesModalBrowserConfigCookiesP4": "Inoltre, l'Utente può opporsi all'uso di determinati cookie tramite i servizi indicati di seguito:",
    "cookiesPoliciesModalBrowserConfigCookiesLI1": "Blocco dei cookie su Chrome",
    "cookiesPoliciesModalBrowserConfigCookiesLI2": "Blocco dei cookie su Safari",
    "cookiesPoliciesModalBrowserConfigCookiesLI3": "Blocco dei cookie su Firefox",
    "cookiesPoliciesModalBrowserConfigCookiesLI4": "Blocco dei cookie su Internet Explorer",
    "cookiesPoliciesModalBrowserConfigCookiesLI5": "http://www.criteo.com/deactivate-criteo-banners/",
    "cookiesPoliciesModalBrowserConfigCookiesLI6": "http://youronlinechoices.eu/",
    "cookiesPoliciesModalBrowserConfigCookiesLI7": "http://www.networkadvertising.org/choices/",
    "cookiesPoliciesModalUnavailableCookies": "Cosa succede se i cookie vengono disabilitati?",
    "cookiesPoliciesModalUnavailableCookiesP1": "Rifiutando i cookie potrà continuare a navigare sul nostro sito web, anche se potrebbe essere limitato l'uso di alcuni servizi e pertanto la sua esperienza potrebbe essere meno soddisfacente.",
    "cookiesPoliciesModalUnavailableCookiesP2": "Il rifiuto, il blocco o la negazione dei permessi dei cookie descritti nella presente politica può influire sulla corretta navigazione dei contenuti riprodotti nel sito web.",
    "cookiesPoliciesModalAcceptCookies": "Accettazione della politica dei cookie",
    "cookiesPoliciesModalAcceptCookiesP1": "Facendo clic sul pulsante “ACCETTA COOKIE” accetta l'uso dei cookie.",
    "cookiesPoliciesModalAcceptCookiesP2": "Tuttavia, a ogni accesso le verrà mostrata e ricordata la nostra Politica dei cookie, nella parte inferiore di qualsiasi pagina del portale.",
    "cookiesPoliciesModalUpgradeCookies": "Aggiorniamo la nostra politica dei cookie?",
    "cookiesPoliciesModalUpgradeCookiesP1": "È possibile che le informazioni contenute nella presente Politica dei cookie possano subire modifiche a causa di aggiornamenti o cambiamenti nella politica di trattamento dei cookie; inoltre, l'Agenzia Spagnola per la Protezione dei dati potrebbe dettare istruzioni e/ raccomandazioni che potrebbero modificare la suddetta politica.",
    "cookiesPoliciesModalUpgradeCookiesP2": "Pertanto, le consigliamo di controllare la presente politica ogni volta che accede al nostro Sito Web al fine di essere adeguatamente informato sulle modalità e sulle finalità dell'uso dei cookie.",
    "cookiesPoliciesModalRecipientCookies": "Chi sono i destinatari delle informazioni e in che modo l'uso dei cookie influisce sulla protezione dei dati personali?",
    "cookiesPoliciesModalRecipientCookiesP1": "Per ulteriori informazioni sul trattamento dei suoi dati personali può consultare la ",
    "cookiesPoliciesModalRecipientCookiesLinkCCOMMS": "Politica sulla protezione dei dati",
    "cookiesPoliciesModalContactInfoCookies": "Informazioni di contatto",
    "cookiesPoliciesModalContactInfoCookiesP1": "Per qualsiasi dubbio, chiarimento e/o suggerimento relativo ai cookie, la preghiamo di contattarci tramite e-mail",
    "cookiesPoliciesModalDatatableCookieType": "Tipo di cookie",
    "cookiesPoliciesModalDatatableTreatmentTerm": "Durata del trattamento",
    "cookiesPoliciesModalDatatableTypeAnalytics": "Analitici",
    "cookiesPoliciesModalDatatableTypeSelf": "Propri",
    "cookiesPoliciesModalDatatableTypeAnalyticsD": "Ogni volta che un Utente visita un Servizio, uno strumento di un fornitore esterno genera un cookie analitico nel computer dell'Utente. Tale cookie, che si genera solamente nel momento della visita, servirà a CCOMMS nelle visite successive per identificare anonimamente il visitatore.",
    "cookiesPoliciesModalDatatableTypeAnalyticsF": "Questi cookie permettono, inoltre, di identificare in modo anonimo gli Utenti naviganti (identificano browser e dispositivi, non le persone) e pertanto di contabilizzare approssimativamente il numero di visitatori e la tendenza nel tempo. Identificano in modo anonimo i contenuti più visitati e interessanti per gli Utenti. Consentono di sapere se l'Utente che sta effettuando l'accesso è nuovo o meno. Salvo che l'Utente decida di registrarsi su CCOMMS, il “cookie” non verrà associato a nessun dato di carattere personale che possa identificarlo direttamente. I suddetti cookie verranno utilizzati solo a fini statistici che contribuiscano a ottimizzare e migliorare l'esperienza degli Utenti nel sito web.",
    "cookiesPoliciesModalDatatableTypeAnalyticsT": "Persistenti",
    "cookiesPoliciesModalDatatableTypeSelfD": "Questo tipo di cookie permette di ampliare le informazioni mostrate a ogni Utente anonimo nei Servizi di CCOMMS.",
    "cookiesPoliciesModalDatatableTypeSelfF": "Tra le altre funzioni, questi cookie archiviano la durata e la frequenza della visualizzazione delle diverse sezioni, l'interazione con le stesse, gli schemi di navigazione e/o i comportamenti degli utenti.",
    "cookiesPoliciesModalDatatableTypeSelfT": "Persistenti",
    "description": "Descrizione",
    "privacyPoliciesConditionsFinalidadTitle": "Finalità",
    "accept": "Accetta",
    "reject": "Rifiuta",
    "save": "Salva",
    "cookiesPoliciesCertySign": "Cookie in Verifica dei Certificati",
    "cookiesNecessaryCookieDescription": "Questo cookie è necessario per stabilire la preferenza dell'utente rispetto alla politica dei cookie.",
    "cookiesGAMessage": "Misurazione interna con Google Analytics. Questi cookie archiviano un identificativo univoco per riconoscere l'utente nelle sue future visite al sito web.",
    "cookiesGAFGenericMessage": "Misurazione interna con Google Analytics."
  },
  conditionsOfService: 'Condizioni di servizio',
  certificationPractiseStatement: 'Practice Statement of Electronic Registered Delivery Service ',
  altAriaAttributes: {
    closeIcon: "icona chiudi",
    zoomTRID: "trid zoom",
    zoomCSV: "zoom csv",
    sampleCertificate: "Certificato di esempio",
    downloadButton: "pulsante download",
    checkboxActiveUser: "Casella di controllo",
    selectLingua: "Seleziona lingua",
    openHelpDialog: "Apri finestra di dialogo di supporto",
    trid: "Scrivi TRID",
    csv: "Scrivi CSV",
    part1Container: "1 - Indicatore di posizione dell'identificativo univoco della transazione (trid) nel modello di certificato",
    part2Container: "2 - Indicatore di posizione dell'identificativo univoco del certificato (csv) nel modello di certificato",
    sampleTrid: "Identificativo univoco della transazione (trid) nel certificato di esempio",
    sampleCSV: "Identificatore univoco del certificato (csv) nel certificato di esempio"
  }
};
