import Vue from "vue";
import VueRouter from "vue-router";
import { HOME, CONFIRMATION } from "@/constants/router/";
import Home from "@/views/home/";
import Confirmation from "@/views/confirmation/";
import i18n from "../i18n";

Vue.use(VueRouter);
const base = "/:lang";
const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`
  },
  {
    path: base + "/",
    name: HOME.name,
    component: Home
  },
  {
    path: base + CONFIRMATION.path,
    name: CONFIRMATION.name,
    component: Confirmation
  },
  {
    path: "/*",
    redirect: `/${i18n.locale}`
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
